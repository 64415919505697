<template>
    <div class="py-8 container mx-auto">
        
        
        <div class="mb-8">
            <div class="flex">
                <header class="flex-grow text-black mb-8">
                    Support Tickets
                </header>
                <div class="flex-none flex">
                    <div 
                        class="fas fa-redo-alt block flex items-center justify-center bg-white text-gray-400 w-10 h-10 rounded-lg cursor-pointer refresh"
                        :class="{'loading': loading == 'refreshing'}"
                        @click.prevent="refresh()"
                    />
                    
                    <button 
                        @click.prevent="showTicketNew = true"
                        class="bg-blue-500 text-white w-10 h-10 rounded-lg ml-4 ">
                        
                        <i class="fas fa-plus text-lg" />
                    </button>
                </div>
            </div>
            
            
            <div class="rounded-lg overflow-hidden shadow-sm">
                <table>
                    <thead>
                        <tr>
                            <th class="border-none w-48">Ticket ID</th>
                            <th>Ticket subject</th>
                            <th>Topic</th>
                            <th>Created At</th>
                            <th>Status</th>
                            <th class="w-12"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr 
                            v-for="ticket in tickets"
                            :key="'ticket_'+ticket.ticketId">
                            
                            <!-- <td>{{ticket.ticketId}}</td> -->
                            <td>
                                <div class="py-0 px-3 bg-yellow-600 text-white rounded inline-block w-32 text-center">
                                    {{ticket.ticketId}}
                                </div>
                            </td>
                            <td>
                                <router-link 
                                    :to="{ name: 'support-show', params: {ticketId: ticket.ticketId} }"
                                    class="link">
                                    
                                    {{ticket.subject}}
                                </router-link>
                            </td>
                            <td>
                                <div v-if="ticket.topic">
                                    {{ticket.topic.topicCategory}} - {{ticket.topic.topic}}
                                </div>
                                <div 
                                    v-else
                                    class="opacity-50">
                                    
                                    Not defined
                                </div>
                            </td>
                            <td>{{ticket.createdAt || ticket.createdAt | moment('lll') }}</td>
                            <td>
                                <div v-if="ticket.resolvedAt">
                                    {{ticket.resolvedAt || ticket.resolvedAt | moment('lll') }}
                                </div>
                                <div v-else>
                                    Open
                                </div>
                            </td>
                            <td>
                                <router-link 
                                    :to="{ name: 'support-show', params: {ticketId: ticket.ticketId} }"
                                    class="link">
                                    
                                    Show
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
                
            </div>
            
            <Modal 
                v-if="showTicketNew"
                @close="showTicketNew = false"
                insideClasses="bg-white"
                width="50rem">
                
                <header slot="header">
                    <div class="py-4 px-6">
                        Create Support Ticket
                    </div>
                </header>
                
                <form 
                    @submit.prevent="createTicket()"
                    class="pb-2 px-6">
                    
                    
                    <label>Topic</label>
                    
                    <select v-model="ticketNew.topic">
                        <optgroup 
                            class="font-normal"
                            v-for="(topicCategory, index) in Object.keys(supportTopics)"
                            :key="'topicCategory_'+index"
                            :label="topicCategory">
                            
                            <option 
                                v-for="topic in supportTopics[topicCategory]"
                                :key="'topic_'+topic"
                                :value="{topicCategory,topic}">
                                
                                {{topic}}
                            </option>
                        </optgroup>
                    </select>
                    
                    <InputSingleline 
                        v-model="ticketNew.subject"
                        label="Ticket Subject"
                        name="subject"
                    />
                    
                    <InputMultiline
                        v-model="ticketNew.text"
                        label="Ticket Text"
                        name="text"
                    />
                </form>
                
                <footer slot="footer">
                    <div class="buttons-wrapper">
                        <a 
                            class="button transparent link" 
                            @click="showTicketNew = false">
                            
                            Cancel
                        </a>
                        
                        <button 
                            :disabled="loading"
                            type="submit"
                            class="button submit"
                            :class="{'loading' : loading == 'creating' }"
                            @click.prevent="createTicket()">
                            
                            Create
                        </button>
                    </div>
                </footer>
            </Modal>
        </div>
        
    </div>
</template>

<script>
    export default {
        computed: {
            user(){
                return this.$store.getters.getUser;
            },
            
            tickets(){
                const tickets = this.$store.getters.getTickets;
                return tickets.filter( t => !t.ticketIdParent);
            },
        },

        data() {
            return {
                showTicketNew: false,
                ticketNew: {
                    subject: null,
                    text: null,
                    author: null,
                    topic: null,
                },
                loading: null,
            }
        },

        methods: {
            async createTicket(){
                try {
                    if (!this.ticketNew.subject) {
                        throw Error( 'The ticket need a subject' )
                    }
                    if (!this.ticketNew.text) {
                        throw Error( 'The ticket need some text content' )
                    }
                    
                    this.loading = 'creating';
                    this.ticketNew.author = this.user.username;
                    
                    const ticket = await this.$store.dispatch('createTicket', this.ticketNew);
                    this.ticketNew.subject = null;
                    this.ticketNew.text = null;
                    this.showTicketNew = false;
                    this.$router.push({ name: 'support-show', params: {ticketId: ticket.ticketId} });
                } 
                catch (e) {
                    this.$notify({ 
                        type: 'error', 
                        // title: 'Error', 
                        text: e.message || 'Problem Creating Ticket'
                    });
                    
                    console.error(e);
                } 
                finally {
                    this.loading = null;
                }
            },
            
            async refresh(){
                try {
                    this.loading = 'refreshing';
                    await this.$store.dispatch('listTickets');
                } 
                catch (e) {
                    this.$notify({ 
                        type: 'error', 
                        // title: 'Error', 
                        text: e.message || 'Problem refreshing tickets list'
                    });
                    
                    console.error(e);
                } 
                finally {
                    this.loading = null;
                }
            },
        },
		
		async mounted(){
			this.refresh();
		}
    }
</script>