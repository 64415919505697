<template>
	<div class="">
		<div class="border flex-none">
			<div class="py-8 container mx-auto">
				<h1>Welcome</h1>
			</div>
		</div>
		<div class="bg-white overflow-auto flex-grow">
		   
			<div 
				v-if="!account.activatedAt"
				class="p-8 container mx-auto  bg-yellow-600 text-white rounded-lg mt-8">
				
				<h1 class="text-white">Waiting for approvement</h1>
				<p class="text-lg">
					Have a look around in the Partner portal in the meantime. We are in the process of approving your application to become a Cloudcast Partner. 
				</p>
			</div>
			
			<div class="py-8 container mx-auto grid grid-cols-3 gap-8">
				<div class="bg-gray-200 rounded-lg p-8">
					<h2 class="text-black mb-2">Customers</h2>
					
					<p>
						Have a lead for a new customer? You can create a new customer easily right now, and attach contact persons and licences to it. 
						You can also create a new CloudCast account directly within this Partner Portal, and connect it to the newly created customer. 
					</p>
					<router-link 
						class="inline-block px-8 py-2 rounded-full bg-blue-500 text-white mt-4"
						:to="{ name: 'customers' }">
						
						Create Customer
					</router-link>
				</div>
				
				<div class="bg-gray-200 rounded-lg p-8">
					<h2 class="text-black mb-2">Support</h2>
					
					<p>
						Questions about product features, training, licenses or bug reports we are here for you. File a new ticket with some details, and we 
						will get right back to you. We also love to get feedback and feature requests, so feel free to get in touch!
					</p>
					<router-link 
						class="inline-block px-8 py-2 rounded-full bg-blue-500 text-white mt-4"
						:to="{ name: 'support' }">
						
						Create Support Ticket
					</router-link>
				</div>
				
				<div class="bg-gray-200 rounded-lg p-8">
					<h2 class="text-black mb-2">Invite other users</h2>
					
					<p>
						Do you have people in your organization that need to access the CloudCast Partner Portal? Go to the settings section and send your invitations
						by an automated email. If the person you are inviting already have an CloudCast Account, the person can sign in with the existing user.
					</p>
					<router-link 
						class="inline-block px-8 py-2 rounded-full bg-blue-500 text-white mt-4"
						:to="{ name: 'settings' }">
						
						Invite Users
					</router-link>
				</div>
			</div>
			
			
			<div class="py-8 container mx-auto">
				<h1 class="text-black mb-2">Latest News</h1>
				
				<div class="mb-8">
					<header class="mb-2">
						<h2 class="text-black">Summer update of 2024</h2>
						<em>{{ '2024.08.26' | moment('ll') }}</em>
					</header>
					<div class="ml-0">
						<p>
							New release of both CloudCast App (v2) and CloudCast Player (formerly know as "Viewer"), in addition to how the CloudCast architecture now is built around these components. 
							In short, we've transitioned from a "pull information of what to play on interval" by the player, to a more "subscribe to changes" methodology. 
							
							Here, in the CloudCast partner portal, there are some new changes to how old vs. new CloudCast accounts are connected, and from August 26th 2024, the partner portal now only creates v2 CloudCast accounts.
						</p>
					</div>
				</div>
				<div class="mb-8">
					<header class="mb-2">
						<h2 class="text-black">Summer update of 2023</h2>
						<em>{{ '2023.06.09' | moment('ll') }}</em>
					</header>
					<div class="ml-0">
						<p>
							There are a lot of updates in both the partner portal and the CloudCast App. We're excited to bring more partner related functionality into the 
							app itself, and some of these features the way your customers now can communicate with you as a partner. 
							
							You can now set up your own Products and Services to promote these inside CloudCast on various context based locations. We hope this will bring more business to 
							you as a partner, and at the same time get the customers more welcome to evolve their signage strategy.
						</p>
					</div>
				</div>
				<div class="mb-8">
					<header class="mb-2">
						<h2 class="text-black">Resources with presentations and the new "Signage Composer"</h2>
						<em>{{ '2022.05.31' | moment('ll') }}</em>
					</header>
					<div class="ml-0">
						<p>
							We've added some presentations in PDF (Norwegian language) in our Resources section, along with a new tool for visualizing signage screens onto image urls. 
							It's still in early development, but we think it might be usefull for our partners to test. Give it a go, and let us know what you think!
						</p>
					</div>
				</div>
				<div class="mb-8">
					<header class="mb-2">
						<h2 class="text-black">Improved Licenses overview</h2>
						<em>{{ '2022.03.30' | moment('ll') }}</em>
					</header>
					<div class="ml-0">
						<p>
							The list of customers and licenses more rich and accessible. We're now showing all monthly license cost and a breakdown of what CloudCast will charge monthly.
							It's a dynamic calculation of the different products included a customers setup, along with mechanisms on what's included in the different "CloudCast Admin packages".
							There's also a nice Excel-sheet download option. 
						</p>
					</div>
				</div>
				
				<div class="mb-8">
					<header class="mb-2">
						<h2 class="text-black">Customer offers</h2>
						<em>{{ '2022.02.18' | moment('ll') }}</em>
					</header>
					<div class="ml-0">
						<p>
							We've just launched a feature for composing unqiue offers for your customers. An offer consist of various building blocks, such as Header, Media Carousel, Information blocks, Seller Contacts, Products and Order Form. 
							The offer is hosted online and gets an unique URL to send out to your customer. This way you're able to update at any point without resending. 
						</p>
					</div>
				</div>
				
				<div class="mb-8">
					<header class="mb-2">
						<h2 class="text-black">Customer timeline</h2>
						<em>{{ '2022.02.02' | moment('ll') }}</em>
					</header>
					<div class="ml-0">
						<p>
							When there are many different people involved in maintaining and supporting a customer account in CloudCast, it would be nice to have a historic view on interactions and updates. 
							The "Customer Timeline" feature is great for handling this, giving your partner users the option to writing a few words in the event, along with the date, author and media assets. 
						</p>
					</div>
				</div>
			</div>
			
		</div>
		
		
		
		<!-- <div class="py-8  mx-auto">
			
			<h1>Debug:</h1>
			<h2>cloudCastAccounts</h2>
			
			<pre class="w-full">{{cloudCastAccounts}}</pre>
			<hr>
			
			
			
			<h1 v-if="account && !account.activatedAt">
				This portal account is still in process. 
			</h1>
			
			
			user:
			<pre class="w-full h-100 overflow-auto">{{user}}</pre>
			
			<br>
			<br>
			
			
			account:
			<pre class="w-full h-100 overflow-auto">{{account}}</pre>
		</div> -->
		
	</div>
</template>

<script>
	import AuthMixins from '@/mixins/AuthMixins';

	export default {
		mixins: [
			AuthMixins,
		],
		
		computed: {
			user(){
				return this.$store.getters.getUser;
			},
			cloudCastAccounts(){
				return this.$store.getters.getCloudCastAccounts;
			},
		},
		
		data() {
			return {
				
			}
		},
	}
</script>