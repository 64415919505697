<template>
    <div class="flex flex-col h-full">
		<div class="flex-none flex space-x-2 px-6">
			<a 
				v-for="tab in tabs"
				:key="'tab_'+tab"
				@click="activeTab = tab"
				class="border-t-2 py-2 px-6"
				:class="activeTab == tab ? 'border-blue-500 bg-gray-200' : 'border-transparent'">
				
				{{ tab }}
			</a>
		</div>
		
		<div class="bg-gray-200 flex-grow p-6 overflow-auto">
			<div v-if="activeTab == 'Details'">
				<a 
					:href="'https://app.getcloudcast.com/app/'+accountSelected.accountId+'/settings'"
					target="_blank"
					class="button submit">
					
					Enter account as Super Admin
				</a>
				<br>
				<br>
				
				<pre>{{ details }}</pre>
			</div>
			<div v-else-if="activeTab == 'Portals'">
				<pre>{{ accountSelected.usage.portals }}</pre>
			</div>
			<div v-else-if="activeTab == 'Users'">
				<pre>{{ accountSelected.usage.users }}</pre>
			</div>
			<div v-else-if="activeTab == 'Screens'">
				<h3>Screens</h3>
				
				activeScreens:
				<pre>{{activeScreens}}</pre>
				<br>
				
				inactiveScreens:
				<pre>{{inactiveScreens}}</pre>
				<br>
				
				<pre>{{ accountSelected.usage.screens }}</pre>
			</div>
			<div v-else-if="activeTab == 'Raw'">
				<pre>{{ accountSelected }}</pre>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
		name: 'AccountDetails',
		
        props: [
            'accountSelected',
			'activeScreens',
			'inactiveScreens',
        ],
		
		computed: {
			details(){
				const { name, createdAt, owner, design, accountId, contact } = this.accountSelected;
				
				return {
					accountId, 
					name, 
					createdAt, 
					owner, 
					design,
					contact,
				};
			}
		},
        
        data(){
            return {
                activeTab: 'Details',
				tabs: [
					'Details',
					'Portals',
					'Users',
					'Screens',
					'Raw',
				]
            }
        },
    }
</script>

<style scoped lang="scss">
	pre {
		font-family: 'Courier New', Courier, monospace;
		@apply bg-gray-800;
		@apply text-yellow-500;
		@apply p-8;
	}
</style>
