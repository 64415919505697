<template>
    <div class="form-component">
        <label 
            v-if="label"
            :for="randomId">
            
            {{label}}
            
            <span 
                v-if="required"
                class="required">
                <!-- title="Feltet er påkrevd" -->
                * 
                
                <!-- <span 
                    v-if="showRequiredWarning && (value == '' || value == [])" 
                    class="large">
                    Required field
                </span>
                <span 
                    v-else-if="!showRequiredWarning && (value == '' || value == [])" 
                    class="small">
                    *
                </span> -->
            </span>
        </label>
        
        <select 
            :name="name" 
            :id="randomId"
            :value="value"
            @input="$emit('input', $event.target.value)"
            class="capitalize"
            :required="required"
            :disabled="disabled"
            :readonly="readonly">
            
            <option 
                v-for="(o,index) in options"
                :key="randomId+'_'+index"
                :value="o"
                :selected="value == o">
                
                {{ o }}
            </option>
        </select>
    </div>
</template>

<script>
    export default {
        props: [
            'name',
            'label',
            'value',
            'options',
            'required',
            'disabled',
            'readonly',
            'showRequiredWarning',
        ],
        
        data(){
            return {
                randomId: null
            }
        },
        
        mounted(){
            const numString = ''+Math.random()*10000;
            this.randomId = numString.replace('.','');
        }
    }
</script>

<style scoped lang="scss">
    
</style>
