<template>
<div class="w-full h-full overflow-auto">
    <div class="max-w-lg mx-auto overflow-hidden rounded-lg my-12 ">

        <div class="bg-blue-900 text-white p-8">
            <div class="">
                <img src="https://www.getcloudcast.com/logo.svg" class="w-32" />
                <h1 class="text-white opacity-50 my-0 leading-none ml-3 text-4xl tracking-tight my-1">Partner Portal</h1>
            </div>

            <p class="m-3 mt-6 opacity-75">
                Upon signing up for a CloudCast Partner, we need to process your submission. You will be notified as soon as the application has been approved.
            </p>
        </div>

        <div class="bg-white p-8">
            
            <div v-if="response">
                <div v-if="response.user.UserStatus">
                    <div v-if="response.user.UserStatus == 'FORCE_CHANGE_PASSWORD'">
                        <p>We have prepared a user profile for you and your new Partner account. You will now have to set your own personal password once signed in. </p>
                        <p>Check your email inbox where we have generated a temporary password for your first visit, and follow the instructions to complete a secure access.</p>
                    </div>
                    <div v-else-if="response.user.UserStatus == 'CONFIRMED'">
                        <p>Since you have used an already existing email for your user, we have attached the new Partner account to this user. </p>
                    </div>
                    <div v-else>
                        {{ getUserStatusText(response.user.UserStatus) }}
                    </div>
                    
                    <router-link 
                        class="button submit my-4"
                        :to="{ name: 'signin', query: { email: response.user.Username } }">
                        
                        Portal Sign In
                    </router-link>
                </div>
                <div v-else>
                    <p>There was an error when registering the Partner account. Please get in touch with us so we can help you out with the process.</p>
                </div>
            </div>
            <form v-else @submit.prevent="accountsCreate()">
                <div 
                    v-if="error" 
                    class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                    
                    {{error}}
                </div>
                
                <InputSingleline 
                    v-model="accountNew.name" 
                    label="Company Name" 
                    name="name" 
                    placeholder="My Company" 
                    type="text" 
                    :required="true" 
                    class="mt-8" 
                />
                
                <InputSingleline 
                    v-model="accountNew.address" 
                    label="Address" 
                    name="address" 
                    type="text" 
                    class="mt-8" 
                />
                
                <div class="flex space-x-8">
                    <InputSingleline 
                        v-model="accountNew.postalCode" 
                        label="Postal Code" 
                        name="postalCode" 
                        type="text" 
                    />
                    <InputSingleline 
                        v-model="accountNew.city" 
                        label="City" 
                        name="city" 
                        type="text"
                    />
                </div>
                
                <div class="flex space-x-8">
                    <InputSingleline 
                        v-model="accountNew.state" 
                        label="State" 
                        name="state" 
                        type="text" 
                    />
                    <InputSingleline 
                        v-model="accountNew.country" 
                        label="Country" 
                        name="country" 
                        type="text" 
                    />
                </div>
                
                <InputSingleline 
                    v-model="accountNew.websiteUrl" 
                    label="Company Website Url" 
                    name="websiteUrl" 
                    type="text" 
                />
                
                <p class="mt-8 pt-8 border-t">
                    If you already have a CloudCast account registered, you can use the same email address. This will attach this partner account to your existing user profile.
                </p>
                
                <InputSingleline 
                    v-model="accountNew.email" 
                    label="Your Email Address" 
                    name="email" 
                    placeholder="john@company.com"
                    type="email" 
                    :required="true" 
                    class="mt-8" 
                />
                
                <button 
                    type="submit" 
                    class="button submit mb-4 w-full" 
                    :class="{'loading' : loading}" 
                    :disabled="loading">
                    
                    Sign Up
                </button>
                
                <div class="text-center">
                        
                    <router-link 
                        :to="{ name: 'signin'}"
                        class="inline-block mx-auto">
                        
                        Go to Partner Sign in page
                    </router-link>
                </div>
                    
            </form>
        </div>
        <!-- <pre>{{response}}</pre> -->
    </div>
    <!-- 
        
        <div 
            v-if="!user"
            class="flex flex-col md:flex-row m-8 md:rounded-lg md:overflow-hidden">
            
            <div class="w-full md:w-64 bg-blue-900 text-white p-8 pl-12 flex flex-col rounded-t-lg md:rounded-none">
                <img 
                    src="https://www.getcloudcast.com/logo.svg"
                    class="w-32 mt-3 mb-20 -ml-3"
                />
                
                <h2 class="text-2xl font-normal mb-2 leading-tight">signUp</h2>
                <p>welcomeNewUser</p>

            </div>
            
            <div class="w-full md:w-auto flex-grow bg-white p-8 md:px-12 h-full flex flex-col rounded-b-lg md:rounded-none">
                <form @submit.prevent="signUp()">
                    <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                        {{error}}
                    </div>
                    
                    <InputSingleline 
                        v-model="email"
                        label="email"
                        name="email"
                        placeholder="email"
                        class="w-full -mt-4"
                        type="email"
                        :required="true"
                    />
                    
                    <InputSingleline 
                        v-model="password"
                        label="password"
                        name="password"
                        placeholder="password"
                        class="w-full "
                        type="password"
                        :required="true"
                    />
                    
                    <button 
                        type="submit" 
                        class="button submit mb-4 w-full" 
                        :class="{'loading' : loading}"
                        :disabled="loading">
                        
                        signUp
                    </button>
                </form>
                
                <div class="text-center flex flex-col">
                    <router-link :to="{ name: 'signin'}">
                        regsiteredAlready
                    </router-link>
                </div>
            </div>
        </div>
        <div 
            v-else-if="user"
            class="flex flex-col md:flex-row m-8 md:rounded-lg md:overflow-hidden">
            
            <div class="w-full md:w-64 bg-blue-900 text-white p-8 pl-12 flex flex-col rounded-t-lg md:rounded-none">
                <img 
                    src="https://www.getcloudcast.com/logo.svg"
                    class="w-32 mt-3 mb-20 -ml-3"
                />
                
                <div class="flex-grow">
                    <h2 class="text-2xl font-normal mb-2">{{$t('auth.confirmEmail')}}</h2>
                    <p>{{$t('auth.confirmEmailText')}}</p>
                </div>
            </div>
            <div class="w-full md:w-auto flex-grow bg-white p-8 md:px-12 h-full flex flex-col rounded-b-lg md:rounded-none">
                <form @submit.prevent="confirmSignUp()">
                    <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                        {{error}}
                    </div>
                    
                    <InputSingleline 
                        v-model="email"
                        label="email"
                        name="email"
                        placeholder="email"
                        class="w-full -mt-4"
                        :required="true"
                    />
                    
                    <InputSingleline 
                        v-model="confirmCode"
                        label="verificationCode"
                        name="confirmCode"
                        placeholder="verificationCode"
                        class="w-full"
                        :required="true"
                    />
                    
                    <button 
                        type="submit" 
                        class="button submit mb-4 w-full" 
                        :class="{'loading' : loading}"
                        :disabled="loading">
                        
                        confirm
                    </button>
                </form>
                
                <div class="text-center flex flex-col">
                    <a 
                        class="cursor-pointer" 
                        @click="resendSignUp()">
                        
                        resendConfirmCode
                    </a>
                </div>
            </div>
        </div>
        -->
</div>
</template>

<script>

import AuthMixins from '@/mixins/AuthMixins';

export default {
    mixins: [
        AuthMixins,
    ],
    
    data() {
        return {
            accountNew: {
                name: null,
                email: null,
                country: null,
                state: null,
                city: null,
                websiteUrl: null,
                address: null,
                postalCode: null,
            },

            error: null,
            loading: false,
            response: null,
            // response: {
            //     data: {
            
            //         name: "Ecode AS",
            //         email: "kristoffer4@ecode.no",
            //         country: "Norway",
            //         state: "Norge",
            //         city: "Oslo",
            //         websiteUrl: "Ecode AS",
            //         address: null,
            //         postalCode: "0479"
            //     },
            //     pool: "eu-west-1_CQNv3CT8s",
            //     email: "kristoffer4@ecode.no",
            //     createOrUpdate: "create",
            //     user: {
            //         Username: "kristoffer4@ecode.no",
            //         Attributes: [{
            //                 Name: "sub",
            //                 Value: "ed4a4ec3-1b81-4a0b-9a16-159435a66e2f"
            //             },
            //             {
            //                 Name: "email_verified",
            //                 Value: "true"
            //             },
            //             {
            //                 Name: "email",
            //                 Value: "kristoffer4@ecode.no"
            //             }
            //         ],
            //         UserCreateDate: "2021-11-10T19:22:43.934Z",
            //         UserLastModifiedDate: "2021-11-10T19:22:43.934Z",
            //         Enabled: true,
            //         UserStatus: "FORCE_CHANGE_PASSWORD"
            //     },
            //     adminAddUserToGroupResponse: {}
            // }
        }
    },
    
    methods: {
        async accountsCreate() {
            try {
                this.loading = 'signup';

                if (!this.accountNew.name) {
                    throw Error('The account needs a name')
                }
                if (!this.accountNew.email || !this.emailIsValid(this.accountNew.email)) {
                    throw Error('The account needs a valid email address')
                }

                this.accountNew.email = this.accountNew.email.toLowerCase().trim();

                const response = await this.$axios.post(process.env.VUE_APP_API_ENDPOINT + '/accounts', this.accountNew);
                this.response = response.data;
            } catch (e) {
                this.$notify({
                    type: 'error',
                    // title: 'Error', 
                    text: e.message || 'Problem Creating Ticket'
                });

                console.error(e);
            } finally {
                this.loading = null;
            }
        },
        
        getUserStatusText(status) {
            switch (status) {
                case 'UNCONFIRMED':
                    return 'User has been created but not confirmed.';
                    break;
                    
                case 'ARCHIVED':
                    return 'User is no longer active.';
                    break;
                    
                case 'COMPROMISED':
                    return 'User is disabled due to a potential security threat.';
                    break;
                    
                case 'UNKNOWN':
                    return 'User status is not known.';
                    break;
                    
                case 'RESET_REQUIRED':
                    return 'User is confirmed, but the user must request a code and reset his or her password before he or she can sign in.';
                    break;
            }
        },
    },
    
    async created() {
        const signedInStatus = await this.checkUser();
        console.log(signedInStatus);
        const emailAsParam = this.$route.query.email;
        if (this.emailIsValid(emailAsParam)) {
            this.email = emailAsParam;
        }
        
        if (signedInStatus) {
            this.$router.push({name: 'accounts'});
        }
        else {
            this.ready = true;
        }
    },
}
</script>
