<template>
	<div class="py-8 container mx-auto">
		
		<div class="mb-8">
			<div class="flex">
				<header class="flex-grow text-black mb-8">
					Customers
				</header>
				<div class="flex-none flex space-x-4">
					<div class="">
						<button 
							class="button transparent link"
							@click="onDownloadExcelFile">
							
							Download Excel File
						</button>
					</div>
					
					<div class="">
						<select 
							v-model="listStyle"
							class="border-none">
							
							<option value="simple">Simple list view</option>
							<option value="detailed">Detailed list view</option>
						</select>
					</div>
					
					<div 
						class="fas fa-redo-alt block flex items-center justify-center bg-white text-gray-400 w-12 h-12 rounded-lg cursor-pointer refresh"
						:class="{'loading': loading == 'refreshing'}"
						@click.prevent="onRefresh()"
					/>
					
					<button 
						@click.prevent="showCustomerNew = true"
						class="bg-blue-500 text-white w-12 h-12 rounded-lg">
						
						<i class="fas fa-plus text-lg" />
					</button>
				</div>
			</div>
			
			<div class="rounded-lg overflow-hidden shadow-sm">
				<table>
					<thead>
						<tr>
							<th class="border-none">Customer Name</th>
							<th>Status</th>
							<th>Created At</th>
							<th>Connected CloudCast Account</th>
							<th class="text-right">License Sum</th>
						</tr>
					</thead>
					<tbody>
						<template v-for="customer in customers">
							<tr :key="'customer_'+customer.customerId">
								<td>
									<router-link 
										:to="{ name: 'customer-details', params: {customerId: customer.customerId} }"
										class="link">
										
										{{customer.name}}
									</router-link>
								</td>
								<td>
									<div 
										v-if="statuses.find(s => s.statusId == customer.status)"
										class="">
										
										{{statuses.find(s => s.statusId == customer.status).name }}
									</div>
									<div 
										v-else
										class="opacity-50">
										
										Not set
									</div>
								</td>
								<td>{{customer.createdAt || customer.createdAt | moment('lll') }}</td>
								<td>
									<div 
										v-if="customer.accountId && cloudCastAccounts.find(cCA => cCA.accountId == customer.accountId)"
										class="flex space-x-4">
										
										<div 
											v-if="cloudCastAccounts.find(cCA => cCA.accountId == customer.accountId).version"
											class="w-8 px-1 bg-gray-700 text-white text-center rounded">
											
											v2
										</div>
										<div>
											{{cloudCastAccounts.find(cCA => cCA.accountId == customer.accountId).name}}
										</div>
									</div>
									<div 
										v-else
										class="opacity-50">
										
										Not connected
									</div>
								</td>
								<td 
									class="text-right"
									:class="listStyle == 'detailed' ? 'text-black' : ''">
									
									{{
										customer.licensesCalculation && customer.licensesCalculation.sum ? 
											new Intl.NumberFormat('no-nb', {
												style: 'currency',
												currency: 'NOK',
											}).format( customer.licensesCalculation.sum )
										: 
										0 
									}}
								</td>
							</tr>
							
							<template v-if="listStyle == 'detailed'">
								<tr 
									v-for="(product, i) in customer.licensesCalculation ? customer.licensesCalculation.products : []"
									:key="'customerDetailed_'+customer.customerId+'_'+i"
									class="weak">
									
									<td colspan="4">
										<div class="pl-6">
											{{product.product.productId}} - 
											{{product.product.name}}
											
											({{product.count}})
										</div>
									</td>
									<td class="text-right">
										{{
											product.sum ? 
												new Intl.NumberFormat('no-nb', {
													style: 'currency',
													currency: 'NOK',
												}).format( product.sum )
											: 
											0 
										}}
									</td>
								</tr>
							</template>
						</template>
					</tbody>
					<tfoot class="bg-gray-100 border-t-2 border-gray-200 text-black">
						<tr>
							<th colspan="4">Total Monthly (NOK)</th>
							<th class="text-right">
								{{
									licensesCalculationTotal ? 
										new Intl.NumberFormat('no-nb', {
											style: 'currency',
											currency: 'NOK',
										}).format( licensesCalculationTotal )
									: 
									0 
								}}
							</th>
						</tr>
					</tfoot>
				</table>
			</div>
			
			<Modal 
				v-if="showCustomerNew"
				@close="showCustomerNew = false"
				insideClasses="bg-white">
				
				<header slot="header">
					<div class="py-4 px-6">
						Create Customer
					</div>
				</header>
				
				<form 
					@submit.prevent="onCreateCustomer()"
					class="pb-2 px-6">
					
					<InputSingleline 
						v-model="customerNew.name"
						label="Customer Name"
						name="name"
						:autofocus="true"
					/>
				</form>
				
				<footer slot="footer">
					<div class="buttons-wrapper">
						<a 
							class="button transparent link" 
							@click="showCustomerNew = false">
							
							Cancel
						</a>
						
						<button 
							:disabled="loading"
							type="submit"
							class="button submit"
							:class="{'loading' : loading == 'creating' }"
							@click.prevent="onCreateCustomer()">
							
							Create
						</button>
					</div>
				</footer>
			</Modal>
		</div>
	</div>
</template>

<script>
	import writeXlsxFile from 'write-excel-file';
	
	export default {
		computed: {
			customers(){
				const customers = this.$store.getters.getCustomers;
				
				customers.forEach( customer => {
					if (customer.accountId) {
						const cloudCastAccount = this.cloudCastAccounts.find(cCA => cCA.accountId == customer.accountId);
						customer.licensesCalculation = this.getAccountSum( customer, cloudCastAccount);
					}
				});
				
				return customers;
			},
			
			cloudCastAccounts(){
				const cloudCastAccounts = this.$store.getters.getCloudCastAccounts;
				return cloudCastAccounts;
			},
			
			licensesCalculationTotal(){
				let total = 0;
			
				this.customers.forEach( customer => {
					if (customer.licensesCalculation) {
						total = total + customer.licensesCalculation.sum;
					}
				});
				
				return total;
			},
		},
		
		data() {
			return {
				showCustomerNew: false,
				customerNew: {
					name:null,
					licenses: {
						'CCA-W21-F': 1,
					},
				},
				loading: null,
				listStyle: 'simple',
				excel: {
					data: null,
					columns: null,
					options: {
						fileName: 'CloudCast Partner Export.xlsx',
						fontFamily: 'Arial',
						fontSize: 12,
					},
				},
			}
		},
		
		methods: {
			async onCreateCustomer(){
				try {
					if (!this.customerNew.name) {
						throw Error( 'The customer needs a name' )
					}
					
					this.loading = 'creating';
					const customer = await this.$store.dispatch('createCustomer', this.customerNew);
					this.customerNew.name = null;
					this.showCustomerNew = false;
					this.$router.push({ name: 'customer-details', params: {customerId: customer.customerId} });
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						// title: 'Error', 
						text: e.message || 'Problem creating customer'
					});
					
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
			async onRefresh(){
				try {
					this.loading = 'refreshing';
					await this.$store.dispatch('listCloudCastAccounts');
					await this.$store.dispatch('listCustomers');
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						// title: 'Error', 
						text: e.message || 'Problem refreshing customers list'
					});
					
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
			async onDownloadExcelFile(){
				const columnSetup = {
					height: 30,
					alignVertical: 'center',
				};
				
				const columnSetupHeader = {
					fontWeight: 'bold',
					backgroundColor: '#0D2D43',
					color: '#ffffff',
					stickyRowsCount: 1,
				};
				
				this.excel.data = [
					// Row #1
					[
						{ // Column #1
							value: 'Customer Name',
							...columnSetup,
							...columnSetupHeader,
						},
						// { // Column #2
						//     value: 'Customer Manager',
						//     ...columnSetup,
						//     ...columnSetupHeader,
						// },
						{ // Column #3
							value: 'Product',
							...columnSetup,
							...columnSetupHeader,
						},
						{ // Column #4
							value: 'Name',
							...columnSetup,
							...columnSetupHeader,
						},
						{ // Column #5
							value: 'QTY',
							align: 'center',
							...columnSetup,
							...columnSetupHeader,
						},
						{ // Column #6
							value: 'Cost',
							align: 'center',
							...columnSetup,
							...columnSetupHeader,
						},
						{ // Column #7
							value: 'Sum',
							align: 'center',
							...columnSetup,
							...columnSetupHeader,
						},
					],
				];
				
				const columnCustomer = {
					height: 50,
					alignVertical: 'bottom',
				};
				
				this.customers.forEach( (customer, cIndex) => {
					if ( 
						customer.licensesCalculation &&
						customer.licensesCalculation.products &&
						customer.licensesCalculation.products.length ) {
						
						this.excel.data.push(
							[
								{ // Column #1
									value: customer.name,
									fontWeight: 'bold',
									...columnSetup,
									...columnCustomer,
								},
								// { // Column #2
								//     value: 'Mr. Invoice',
								//     ...columnSetup,
								//     ...columnCustomer,
								// },
								{ // Column #3
									// value: 'Product',
									...columnSetup,
									...columnCustomer,
								},
								{ // Column #4
									// value: 'Name',
									...columnSetup,
									...columnCustomer,
								},
								{ // Column #5
									// value: 'QTY',
									...columnSetup,
									...columnCustomer,
								},
								{ // Column #6
									// value: 'Cost',
									...columnSetup,
									...columnCustomer,
								},
								{ // Column #7
									value: customer.licensesCalculation.sum,
									format: '#,##0',
									fontWeight: 'bold',
									...columnSetup,
									...columnCustomer,
								},
							]
						);
						
						customer.licensesCalculation.products.forEach( (product, pIndex) => {
							const columnSetupProduct = {
								color: '#444444',
							};
							
							this.excel.data.push(
								[
									{ // Column #1
										value: '',
										...columnSetup,
										...columnSetupProduct,
									},
									// { // Column #2
									//     value: '',
									//     ...columnSetup,
									//     ...columnSetupProduct,
									// },
									{ // Column #3
										value: product.product.productId,
										...columnSetup,
										...columnSetupProduct,
									},
									{ // Column #4
										value: product.product.name,
										...columnSetup,
										...columnSetupProduct,
									},
									{ // Column #5
										value: product.count,
										...columnSetup,
										...columnSetupProduct,
										align: 'center',
									},
									{ // Column #6
										value: product.sum,
										format: '#,##0',
										align: 'right',
										...columnSetup,
										...columnSetupProduct,
									},
									{ // Column #7
										// value: 'Sum',
										...columnSetup,
										...columnSetupProduct,
									},
								]
							);
						});
					}
				});
				
				const columnSetupFooter = {
					fontWeight: 'bold',
					backgroundColor: '#f2f2f2',
				};
				
				this.excel.data.push(
					[
						{
							value: 'Total',
							...columnSetup,
							...columnSetupFooter,
						},
						// {
						//     ...columnSetup,
						//     ...columnSetupFooter,
						// },
						{
							...columnSetup,
							...columnSetupFooter,
						},
						{
							...columnSetup,
							...columnSetupFooter,
						},
						{
							...columnSetup,
							...columnSetupFooter,
						},
						{
							...columnSetup,
							...columnSetupFooter,
						},
						{ // Column #7
							value: this.licensesCalculationTotal,
							format: '#,##0',
							...columnSetup,
							...columnSetupFooter,
						},
					]
				);
				
				this.excel.columns = [
					{
						// Column #1
						width: 40
					},
					// {
					//     // Column #2
					//     width: 30
					// },
					{
						// Column #3
						width: 15
					},
					{
						// Column #4
						width: 40
					},
					{
						// Column #5
						width: 15
					},
					{
						// Column #6
						width: 10
					},
					{
						// Column #7
						width: 20
					},
				];
				
				// will use file download to blob in browser.
				await writeXlsxFile( this.excel.data, 
					{
						columns: this.excel.columns, // optional
						...this.excel.options,
					}
				);
			}
		}
	}
</script>