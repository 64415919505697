<template>
	<!-- <div 
		v-if="isInit"
		class="fixed left-0 top-0 bottom-0 w-64 bg-black text-white overflow-auto"
		style="z-index: 1000">
		
		active:
		<pre class="text-xs">{{ active }}</pre>
		
		expanded:
		<pre class="text-xs">{{ expanded }}</pre>
	</div> -->
	
	<ul 
		v-if="folders && (isInit || isExpanded)"
		class="p-0 m-0 ml-6">
		
		<!-- <pre class="text-xs">{{ active }}</pre> -->
		
		<li 
			v-for="(folder,i) in folders"
			:key="'folder_'+folder.name+'_'+i"
			class="p-0 m-0 list-none">
			
			<div class="flex">
				<div class="cursor-pointer flex-shrink truncate mb-1">
					<span 
						@click="folderExpandToggle( folder.folderId )"
						class="inline-block w-6 h-6 text-gray-300 relative">
						
						<i 
							class="w-5 h-5 fas fa-folder text-lg"
						/>
						
						<i 
							v-if="folder.children && folder.children.length"
							class="fas text-white absolute top-0 left-0 w-3 h-3 flex items-center justify-center" 
							style="font-size: .5rem; margin-top: .55rem; margin-left: .2rem;"
							:class="expanded.includes( folder.folderId ) ? 'fa-minus' : 'fa-plus'"
						/>
					</span>
					
					<a 
						@click="folderClick(folder)"
						class="bg-none leading-none"
						:class="isFolderActiveCheck( folder ) ? 'text-black' : null"
						@drop="onDrop( $event, folder )"
						@dragover.prevent
  						@dragenter.prevent>
						
						{{ folder.name }}
						<!-- <span class="opacity-50">({{ folder.folderId }})</span> -->
					</a>
				</div>
			</div>
			
			<FoldersTreeSimple
				v-if="folder.children && folder.children.length"
				:folders="folder.children"
				:isExpanded="expanded.includes( folder.folderId )"
				:active="active"
				@folderClick="folderClick"
				@fileDropped="$emit('fileDropped', $event)"
				@onFolderExpand="onFolderExpand"
				@onFolderCollapse="onFolderCollapse"
				:expanded="expanded"
			/>
		</li>
	</ul>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
	components: {
		FoldersTreeSimple: defineAsyncComponent( () => import('@/components/files/FoldersTreeSimple.vue') ),
	},
	
	emits: [
		'fileDropped',
		'folderClick',
		'onFolderCollapse',
		'onFolderExpand',
		'fileDropped',
	],
	
	props: {
		folders: {
			type: Array,
			required: true,
		},
		isInit: {
			type: Boolean,
			default: false,
			required: false,
		},
		isExpanded: {
			type: Boolean,
			default: false,
			required: false,
		},
		active: {
			type: Object,
			required: false,
		},
		// expandedInit:  {
		// 	type: Array,
		// 	required: false,
		// },
		expanded:  {
			type: Array,
			required: false
		},
	},
	
	data(){
		return {
			// expanded: [],
		}
	},
	
	methods: {
		folderClick( folder ){
			console.log('folderClick', folder);
			this.$emit('folderClick', folder);
			
			// this.folderExpandToggle( folder );
			if ( !this.expanded.includes( folder.folderId ) ) {
				// this.expanded.push( folder.folderId );
				this.folderExpandToggle( folder.folderId );
			}
		},
		
		onFolderExpand( folderId ){
			console.log('onFolderExpand', folderId);
			this.folderExpandToggle( folderId );
		},
		
		onFolderCollapse( folderId ){
			console.log('onFolderCollapse', folderId);
			this.folderExpandToggle( folderId );
		},
		
		folderExpandToggle( folderId ){
			if ( this.expanded.includes( folderId ) ) {
				console.log('included in expanded! so take it away');
				// this.expanded = this.expanded.filter( f => f.folderId != folder.folderId );
				// this.expanded = this.expanded.filter( f => f !== folder.folderId); 
				
				// this.expanded = [];
				this.$emit('onFolderCollapse', folderId);
				
			}
			else {
				console.log('NOT included in expanded! add it');
				// this.expanded.push( folder.folderId );
				this.$emit('onFolderExpand', folderId);
			}
			
			// console.log('this.expanded');
			// console.log(this.expanded);
		},
		
		isFolderActiveCheck( folder ) {
			if ( 
				this.active &&
				this.active.folderId  &&
				folder &&
				this.active.folderId == folder.folderId ) {
				
				return true
			}
			else if (
				this.active && 
				folder && 
				this.active == folder ) {
				
				return true;
			}
			
		},
		
		// onDragover( event ){
		// 	console.log( 'onDragover', event);
		// },
		
		// onDragleave( event ){
		// 	console.log( 'onDragleave', event);
		// },
		
		onDrop( event, folder ){
			event.preventDefault();
			// console.log( 'FoldersTreeSimple component onDrop', event, folder );
			
			if ( event.dataTransfer.getData('file') ) {
				const file = JSON.parse( event.dataTransfer.getData('file') );
				// console.log( 'onDrop data:' );
				// console.log( file );
				this.$emit('fileDropped', {
					file,
					folder,
				});
			}
			else {
				console.log('dropped something else than a file....');
			}
		},
	},
	mounted(){
		if ( 
			this.isInit &&
			this.folders &&
			this.folders.length ) {
			
			this.folderClick( this.folders[0] );
		}
		
		// if ( this.expandedInit ){
		// 	this.expanded = this.expandedInit;
		// }
		
	}
}
</script>