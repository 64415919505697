<template>
    <div class="">
        
        <div class="panel">
            
            <div class="flex items-end">
                <InputSingleline 
                    v-model="invitation.email"
                    type="email"
                    label="Invite by email"
                    placeholder="john@company.com"
                    :required="true"
                    class="flex-grow"
                />
                <div class="flex-none">
                    <button 
                        class="button submit ml-4 mb-4"
                        :class="{'loading' : loading == 'inviting'}"
                        @click.prevent="onUserInvite()">
                        
                        Invite Now
                    </button>
                </div>
            </div>
            
            <div 
                v-if="!usersList"
                class="my-4 flex items-center justify-center">
                
                <div class="spinner"></div>
            </div>
            
            <div 
                v-if="usersList"
                class="space-y-4 mt-8">
                
                <div 
                    v-for="user in usersList"
                    :key="user.Username"
                    class="border bg-white p-4 flex rounded-lg relative items-center">
                    
                    <!-- <i 
                        class="absolute p-2 fas fa-trash m-1 top-0 right-0 text-sm text-gray-500"
                        title="removeUser"
                        @click="onRemoveUser(user)"
                    /> -->
                    
                    <div class="flex-none w-16 h-16 mr-12 flex items-center justify-center bg-gray-300 text-white rounded">
                        <i 
                            class="fas text-4xl" 
                            :class="account.owner.email == user.Username ? 'fa-user-shield' : 'fa-user-tie'"
                        />
                    </div>
                    
                    <div class="flex-grow">
                        
                        <!-- <pre>{{account.owner}}</pre>
                        <pre>{{user.Username}}</pre> -->
                        
                        <div class="text-black">
                            {{user.Attributes.find(a => a.Name == 'email').Value}}
                            
                            <span 
                                v-if="user.Username == getUser.username"
                                class="ml-2 py-0 px-1 bg-gray-400 text-white rounded text-sm">
                                You
                            </span>
                        </div>
                        
                        <div v-if="account.owner.email == user.Username">
                            Partner account owner
                        </div>
                        
                        <div v-if="user.UserStatus != 'CONFIRMED'">
                            Waiting for email confirmation by user
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading: null,
                usersList: null,
                invitation: {
                    email: '',
                    role: 'admin',
                    accountName: '',
                },
                invitationResponse: null,
            }
        },
        
        computed: {
            getUser(){
                return this.$store.getters.getUser;
            },
        },
        
        methods: {
            async onUserInvite(){
                try {
                    if (
                        this.invitation.email == '' || 
                        !this.emailIsValid(this.invitation.email) ) {
                        
                        throw Error('Need a valid email address to invite a new user');
                    }
                    
                    this.loading = 'inviting';
                    
                    this.invitationResponse = await this.accountsUsers( {
                        method: 'invite',
                        invitation: this.invitation,
                    });
                    
                    this.usersList = await this.accountsUsers( {method: 'list' } );
                    this.$notify({ type: 'success', text: 'Invited new user by email' });
                    this.invitation.email = '';
                } 
                catch (err) {
                    console.log(err);
                    this.$notify( {type: 'error', text: err.message} );
                }
                finally{
                    this.loading = null;
                }
            },
            
            async onRemoveUser( user ){
                alert('Future feature');
            },
        },
        
        async mounted() {
            this.usersList = await this.accountsUsers( {method: 'list' } );
            this.invitation.accountName = this.account.name;
        }
    }
</script>
