<template>
    <div class="flex">
        <div 
            v-if="timelineEvents && timelineEvents.length" 
            class="flex-grow relative">
            
            <div class="w-full flex justify-center">
                <button 
                    @click.prevent="showTimelineEventNew = true"
                    class="bg-blue-500 text-white w-10 h-10 rounded-lg ml-8 relative"
                    style="z-index: 100;">
                    
                    <i class="fas fa-plus text-lg" />
                </button>
                
                <div 
                    class="absolute top-0 bottom-0 border-l-2 border-dashed border-gray-400" 
                    style="margin-left: 2rem; z-index: 0;"
                />
            </div>
            
            <div 
                v-for="tE in timelineEvents"
                :key="'tE_'+tE.timelineEventId"
                class="relative">
                
                <div class="bg-white rounded-lg mt-8 flex overflow-hidden border ">
                    <div class="flex-none bg-gray-100 p-8">
                        <div class="text-2xl text-black">{{ tE.createdAt | moment('ll') }}</div>
                        {{ tE.createdAt | moment('HH:mm') }}
                        <br>
                        
                        <a 
                            class="link"
                            :href="'mailto:'+tE.email">
                            
                            {{tE.email}}
                        </a>
                    </div>
                    <div class="flex-grow p-8">
                        <header class="mb-2 flex">
                            <div class="flex-grow">
                                <h2 class="text-lg text-black">{{tE.subject}}</h2>
                            </div>
                            <div class="flex space-x-4">
                                <i 
                                    @click.prevent="onDeleteTimelineEvent(tE)"
                                    class="fas fa-trash text-md link block text-center" 
                                />
                                <a 
                                    @click.prevent="timelineEventEdit = JSON.parse( JSON.stringify( tE ))"
                                    class="link leading-none">
                                    
                                    Edit
                                </a>
                            </div>
                        </header>
                         
                        <p 
                            v-if="tE.content"
                            v-html="tE.content.replace(/(?:\r\n|\r|\n)/g, '<br />')" 
                            class="max-w-3xl"
                        />
                        
                        <div 
                            v-if="files && tE.files.length"
                            class="mt-8">
                            
                            <h3 class="text-black">Attachments</h3>
                            
                            <div class="flex flex-wrap">
                                <div 
                                    v-for="file in files.filter( f => tE.files.find( tEFile => tEFile.fileId == f.fileId) )"
                                    :key="file.fileId"
                                    class="p-4 bg-gray-100 rounded mb-4 mr-4 w-48"
                                    :title="file.name">
                                    
                                    <img
                                        v-if="file.type.startsWith('image')"
                                        :src="fileToUrl( file )" 
                                        class="h-32 w-full object-cover bg-white mb-3 cursor-pointer"
                                        @click="imageModalFile = file"
                                    />
                                    
                                    <div class="">
                                        <div 
                                            class="truncate"
                                            :title="file.name">
                                            
                                            {{ file.name }}
                                        </div>
                                        
                                        <span class="mr-2">{{humanFileSize(file.size)}}</span>
                                        
                                        <a 
                                            class="fas fa-download link mr-2"
                                            :href="fileToUrl( file ) "
                                            target="_blank"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div 
            v-else
            class="flex w-full h-100 items-center justify-center">
            
            <div class="text-center">
                <h1 class="opacity-25">No timeline events here yet.</h1>
                
                <button 
                    type="submit"
                    class="button submit"
                    @click.prevent="showTimelineEventNew = true">
                    
                    <i class="fas fa-plus text-lg mr-4" />
                    Add new event
                </button>
            </div>
        </div>
        
        
        <Modal 
            v-if="showTimelineEventNew"
            @close="showTimelineEventNew = false"
            insideClasses="bg-white"
            width="70rem">
            
            <header slot="header">
                <div class="py-4 px-6">
                    New Timeline Event
                </div>
            </header>
            
            <form 
                @submit.prevent="onAddTimelineEvent()"
                class="pb-2 px-6 grid grid-cols-2 gap-12">
                
                <div class="h-full flex flex-col">
                    <InputSingleline 
                        v-model="timelineEventNew.subject"
                        label="Subject"
                        name="subject"
                        :autofocus="true"
                    />
                    
                    <InputMultiline
                        v-model="timelineEventNew.content"
                        label="Content"
                        name="content"
                        classes="h-64"
                    />
                </div>
                <div>
                    <label for="">Attachments</label>
                    <InputUpload
                        :name="'files'"
                        :label="'Upload Files'"
                        :multiple="true"
                        @input="onFilesAdded"
                        @allSettled="allSettled"
                        :path="path"
                        :useRealName="false"
                        :folderId="null"
                    />
                    <div class="grid grid-cols-3 gap-3 mb-4">
                        <div 
                            v-for="file in files.filter( f => timelineEventNew.files.find( tEFile => tEFile.fileId == f.fileId) )"
                            :key="file.fileId"
                            class="bg-gray-100 p-4">
                            
                            <div 
                                class="truncate"
                                :title="file.name">
                                
                                {{ file.name }}
                            </div>
                            
                            <span class="mr-2">{{humanFileSize(file.size)}}</span>
                            
                            <i 
                                class="fas fa-download link mr-2"
                                :href="fileToUrl( file ) "
                                target="_blank"
                            />
                            <i 
                                class="fas fa-trash link" 
                                @click.prevent="timelineEventNew.files = timelineEventNew.files.filter( f => f.fileId != file.fileId )"
                            />
                        </div>
                    </div>
                </div>
            </form>
            
            <footer slot="footer">
                <div class="buttons-wrapper">
                    <a 
                        class="button transparent link" 
                        @click.prevent="showTimelineEventNew = false">
                        
                        Cancel
                    </a>
                    
                    <button 
                        :disabled="loading"
                        type="submit"
                        class="button submit"
                        :class="{'loading' : loading == 'creating' }"
                        @click.prevent="onAddTimelineEvent()">
                        
                        Add
                    </button>
                </div>
            </footer>
        </Modal>
        
        <Modal 
            v-if="timelineEventEdit"
            @close="timelineEventEdit = null"
            insideClasses="bg-white"
            width="70rem">
            
            <header slot="header">
                <div class="py-4 px-6">
                    Edit Timeline Event
                </div>
            </header>
            
            <form 
                @submit.prevent="onUpdateTimelineEvent()"
                class="pb-2 px-6 grid grid-cols-2 gap-12">
                
                <div class="h-full flex flex-col">
                    <InputSingleline 
                        v-model="timelineEventEdit.subject"
                        label="Subject"
                        name="subject"
                        :autofocus="true"
                    />
                    
                    <InputMultiline
                        v-model="timelineEventEdit.content"
                        label="Content"
                        name="content"
                        classes="h-64"
                    />
                </div>
                <div>
                    <label for="">Attachments</label>
                    <InputUpload
                        :name="'files'"
                        :label="'Upload Files'"
                        :multiple="true"
                        @input="onFilesAdded"
                        @allSettled="allSettled"
                        :path="path"
                        :useRealName="false"
                        :folderId="null"
                    />
                    
                    <div class="grid grid-cols-3 gap-3 mb-4">
                        <div 
                            v-for="file in files.filter( f => timelineEventEdit.files.find( tEFile => tEFile.fileId == f.fileId) )"
                            :key="file.fileId"
                            class="bg-gray-100 p-4">
                            
                            <div 
                                class="truncate"
                                :title="file.name">
                                
                                {{ file.name }}
                            </div>
                            
                            <span class="mr-2">{{humanFileSize(file.size)}}</span>
                            
                            <i 
                                class="fas fa-download link mr-2"
                                :href="fileToUrl( file ) "
                                target="_blank"
                            />
                            <i 
                                class="fas fa-trash link" 
                                @click.prevent="timelineEventEdit.files = timelineEventEdit.files.filter( f => f.fileId != file.fileId )"
                            />
                        </div>
                    </div>
                    
                </div>
            </form>
            
            <footer slot="footer">
                <div class="buttons-wrapper">
                    <a 
                        class="button transparent link" 
                        @click="timelineEventEdit = null">
                        
                        Cancel
                    </a>
                    
                    <button 
                        :disabled="loading"
                        type="submit"
                        class="button submit"
                        :class="{'loading' : loading == 'updating' }"
                        @click.prevent="onUpdateTimelineEvent()">
                        
                        Update
                    </button>
                </div>
            </footer>
        </Modal>
        
        
        
        <Modal 
            v-if="imageModalFile"
            @close="imageModalFile = null"
            width="auto">
            
            <img
                :src="fileToUrl( imageModalFile )" 
                class=""
            />
        </Modal>
            
    </div>
</template>

<script>
    export default {
        computed: {
            timelineEvents(){
                const timelineEvents = this.$store.getters.getTimelineEvents;
                return timelineEvents
                    .filter( tE => !tE.deletedAt)
                    .filter( tE => tE.timelineEventId.startsWith( this.customerId ) );
            },
            
            files(){
                const files = this.$store.getters.getFiles;
                return files;
            },
            
            path() {
                return 'files';
            },
        },
        
        data() {
            return {
                showTimelineEventNew: false,
                timelineEventNew: {
                    subject: null,
                    content: null,
                    files: [],
                },
                timelineEventEdit: null,
                loading: null,
                customerId: null,
                imageModalFile: null,
            }
        },
        
        methods: {
            async onAddTimelineEvent(){
                try {
                    if (!this.timelineEventNew.subject || !this.timelineEventNew.content) {
                        throw Error( 'The event needs a subject and some content' )
                    }
                    
                    this.loading = 'creating';
                    this.timelineEventNew.customerId = this.customerId;
                    
                    const timelineEvent = await this.$store.dispatch('createTimelineEvent', this.timelineEventNew);
                    this.timelineEventNew.subject = null;
                    this.timelineEventNew.content = null;
                    this.timelineEventNew.files = [];
                    this.showTimelineEventNew = false;
                } 
                catch (e) {
                    this.$notify({ 
                        type: 'error', 
                        // title: 'Error', 
                        text: e.message || 'Problem adding timeline event'
                    });
                    
                    console.error(e);
                } 
                finally {
                    this.loading = null;
                }
            },
            
            async onUpdateTimelineEvent(){
                try {
                    this.loading = 'updating';
                    const timelineEventFieldsToUpdate = {
                        timelineEventId: this.timelineEventEdit.timelineEventId,
                        subject: this.timelineEventEdit.subject,
                        content: this.timelineEventEdit.content,
                        files: this.timelineEventEdit.files,
                    };
                    
                    await this.$store.dispatch('updateTimelineEvent', timelineEventFieldsToUpdate);
                    
                    this.$notify({ type: 'success', text: 'Updated Timeline Event' });
                } 
                catch (e) {
                    this.$notify({ 
                        type: 'error', 
                        text: e.message
                    });
                    
                    console.error(e);
                } 
                finally {
                    this.timelineEventEdit = null;
                    this.loading = null;
                }
            },
            
            async onDeleteTimelineEvent( timelineEvent ){
                try {
                    if (! confirm('Are you sure?')) return;
                    this.loading = 'deleting';
                    await this.$store.dispatch('deleteTimelineEvent', timelineEvent);
                } 
                catch (e) {
                    this.$notify({ 
                        type: 'error', 
                        // title: 'Error', 
                        text: e.message || 'Problem deleting timeline event'
                    });
                    
                    console.error(e);
                } 
                finally {
                    this.loading = null;
                }
            },
            
            onFilesAdded( fileData ){
                console.log('onFilesAdded');
                console.log({fileData});
                
                if (this.timelineEventEdit) {
                    this.timelineEventEdit.files.push( {
                        fileId: fileData.file.fileId,
                    });
                }
                else {
                    this.timelineEventNew.files.push( {
                        fileId: fileData.file.fileId,
                    });
                }
            },
            
            allSettled( promises ){
                console.log( 'allSettled' );
                console.log( promises );
            },
        },
        
        async mounted(){
            this.customerId = this.$route.params.customerId;
            await this.$store.dispatch('listTimelineEvents');
            await this.$store.dispatch('listFiles', {path: 'files'});
        },
    }
</script>