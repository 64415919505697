<template>
    <div class="bg-white my-4 bg-gray-200 rounded overflow-hidden">
        <div 
            @click="isOpen = !isOpen"
            class="px-4 flex items-center">
            
            <div
                class="handle text-3xl mr-4 cursor-move"
                v-html="'='"
            />
            <div 
                class="flex-grow cursor-pointer"
                :class="isOpen ? 'text-black' : ''">
                {{block.type.name}}
            </div>
            <i 
                class="flex-none fas fa-trash block w-4 h-4 flex justify-center items-center mr-4 text-xs"
                @click.prevent="$emit('remove')"
            />
            <i 
                class="flex-none fas block w-4 h-4 flex justify-center items-center"
                :class="isOpen ? 'fa-chevron-down' : 'fa-chevron-right'"
            />
        </div>
        <div 
            v-if="isOpen"
            class="bg-gray-100 px-4 border-t py-2">
            
            <div v-if="block.type.code == 'header'">
                <div class="overflow-hidden">
                    <label>Customer Logo</label>
                    <div 
                        v-if="block.customerLogo"
                        class="border overflow-hidden rounded bg-white mb-4">
                        
                        <div class="flex px-4 py-2 bg-gray-200">
                            <div class="flex-grow">
                                {{humanFileSize( block.customerLogo.size) }}
                            </div>
                            <a 
                                @click="block.customerLogo = null"
                                class="text-blue-500">
                                
                                Remove
                            </a>
                        </div>
                        <img
                            :src="fileToUrl( block.customerLogo )" 
                            class="max-h-32 w-full object-contain"
                        />
                    </div>
                    <InputUpload
                        v-else
                        name="block.customerLogo"
                        :multiple="false"
                        @input="onFilesAdded"
                        @allSettled="$event && $event[0].status == 'fulfilled' ? block.customerLogo = $event[0].value.file : null"
                        path="files"
                        :useRealName="false"
                        :folderId="null"
                    />
                </div>
                <InputSingleline 
                    v-model="block.heading"
                    label="Heading"
                    type="text"
                    :required="true"
                />
                <InputMultiline 
                    v-model="block.text"
                    label="Text"
                    name="text"
                    :required="false"
                />
                
                <InputSingleline 
                    v-model="block.background.color"
                    label="Background Color"
                    type="text"
                    placeholder="#f2f2f6"
                    :required="false"
                />
                <InputSingleline 
                    v-model="block.background.text"
                    label="Background Text color"
                    type="text"
                    placeholder="#f2f2f6"
                    :required="false"
                />
                
                <div class="overflow-hidden">
                    <label>Background Image</label>
                    <div 
                        v-if="block.background.image"
                        class="border overflow-hidden rounded bg-white mb-4">
                        
                        <div class="flex px-4 py-2 bg-gray-200">
                            <div class="flex-grow">
                                {{humanFileSize( block.background.image.size) }}
                            </div>
                            <a 
                                @click="block.background.image = null"
                                class="text-blue-500">
                                
                                Remove
                            </a>
                        </div>
                        <img
                            :src="fileToUrl( block.background.image )" 
                            class="max-h-32 w-full object-contain"
                        />
                    </div>
                    <InputUpload
                        v-else
                        name="block.backgroundImage"
                        :multiple="false"
                        @input="onFilesAdded"
                        @allSettled="$event && $event[0].status == 'fulfilled' ? block.background.image = $event[0].value.file : null"
                        path="files"
                        :useRealName="false"
                        :folderId="null"
                    />
                </div>
            </div>
            <div v-else-if="block.type.code == 'mediaCarousel'">
                <label>Images and Videos</label>
                
                <div v-if="block.files">
                    <div 
                       v-for="(file, index) in block.files"
                       :key="'file_'+file.fileId"
                       class="border overflow-hidden rounded bg-white mb-4">
                       
                       <div class="flex px-4 py-2 bg-gray-200">
                           <div class="flex-grow">
                               {{humanFileSize(file.size)}}
                           </div>
                           
                           <a 
                               v-if="block.files.length > 1"
                               class="px-2"
                               :class="index == 0 ? 'opacity-25' : null"
                               @click.stop="onMoveOrder( file, index, 'up', block.files)">
                               
                               <i class="fas fa-chevron-up mr-1 text-center" />
                           </a>
                           <a 
                               v-if="block.files.length > 1"
                               class="px-2"
                               :class="index+1 == block.files.length ? 'opacity-25' : null"
                               @click.stop="onMoveOrder( file, index, 'down', block.files)">
                               
                               <i class="fas fa-chevron-down mr-1 text-center" />
                           </a>
                           <a 
                               @click="block.files = block.files.filter( f => f != file)"
                               class="text-blue-500">
                               
                               Remove
                           </a>
                       </div>
                       
                       <img
                           v-if="file.type.startsWith('image')"
                           :src="fileToUrl( file )" 
                           class="max-h-32 w-full object-contain"
                       />
                       
                   </div>
                </div>
                
                <InputUpload
                    name="mediaCarousel"
                    :multiple="true"
                    @input="onFilesAdded"
                    @allSettled="$event && $event[0].status == 'fulfilled' ? block.files.push( $event[0].value.file ) : null"
                    path="files"
                    :useRealName="false"
                    :folderId="null"
                />
                
                <!-- <InputSingleline 
                    v-model="block.height.desktop"
                    label="Desktop Height"
                    type="number"
                    placeholder="300px"
                    :required="false"
                    step="1"
                />
                
                <InputSingleline 
                    v-model="block.height.mobile"
                    label="Mobile Height"
                    type="number"
                    placeholder="300px"
                    :required="false"
                    step="1"
                /> -->
            </div>
            <div v-else-if="block.type.code == 'info'">
                <InputSingleline 
                    v-model="block.background.color"
                    label="Background Color"
                    type="text"
                    placeholder="#f2f2f6"
                    :required="false"
                />
                <InputSingleline 
                    v-model="block.background.text"
                    label="Background Text color"
                    type="text"
                    placeholder="#000000"
                    :required="false"
                />
                <InputSingleline 
                    v-model="block.heading"
                    label="Heading"
                    type="text"
                    :required="true"
                />
                <InputMultiline 
                    v-model="block.text"
                    label="Text"
                    name="text"
                    :required="false"
                />
                
                <div class="overflow-hidden">
                    <label>Media File</label>
                    <div 
                        v-if="block.media"
                        class="border overflow-hidden rounded bg-white mb-4">
                        
                        <div class="flex px-4 py-2 bg-gray-200">
                            <div class="flex-grow">
                                {{humanFileSize( block.media.size) }}
                            </div>
                            <a 
                                @click="block.media = null"
                                class="text-blue-500">
                                
                                Remove
                            </a>
                        </div>
                        <img
                            :src="fileToUrl( block.media )" 
                            class="max-h-32 w-full object-contain"
                        />
                    </div>
                    <InputUpload
                        v-else
                        name="block.media"
                        :multiple="false"
                        @input="onFilesAdded"
                        @allSettled="$event && $event[0].status == 'fulfilled' ? block.media = $event[0].value.file : null"
                        path="files"
                        :useRealName="false"
                        :folderId="null"
                    />
                </div>
                <InputSelect 
                    v-model="block.mediaPosition"
                    label="Media Position"
                    :name="'mediaPosition'"
                    :options="['left','right']"
                />
            </div>
            <div v-else-if="block.type.code == 'sellerContacts'">
                <InputSingleline 
                    v-model="block.heading"
                    label="Heading"
                    type="text"
                    :required="true"
                />
                <InputMultiline 
                    v-model="block.text"
                    label="Text"
                    name="text"
                    :required="false"
                />
                <InputSingleline 
                    v-model="block.background.color"
                    label="Background Color"
                    type="text"
                    placeholder="#f2f2f6"
                    :required="false"
                />
                <InputSingleline 
                    v-model="block.background.text"
                    label="Background Text color"
                    type="text"
                    placeholder="#000000"
                    :required="false"
                />
                
                <div class="flex py-2">
                    <div class="flex-grow">
                        Contacts
                    </div>
                    <a 
                        @click.prevent="$emit('onAddBlock', 'sellerContact');"
                        class="text-blue-500">
                        
                        Add
                    </a>
                </div>
                
                <div 
                    v-if="!block.contacts.length"
                    class="bg-white rounded-lg p-8 border border-dashed mb-4 text-center"
                    @click.prevent="$emit('onAddBlock', 'sellerContact');">
                    No contacts yet
                </div>
                
                <template v-if="block.contacts">
                    <div 
                        v-for="(contact,index) in block.contacts"
                        :key="'contact_'+index"
                        class="mb-2 border overflow-hidden rounded bg-white ">
                        
                        <div class="flex bg-gray-200 px-4 py-2">
                            <div class="flex-grow text-black">
                                {{'Contact '+(index+1)}}
                            </div>
                            <a 
                                v-if="block.contacts.length > 1"
                                class="px-2"
                                :class="index == 0 ? 'opacity-25' : null"
                                @click.stop="onMoveOrder( contact, index, 'up', block.contacts)">
                                
                                <i class="fas fa-chevron-up mr-1 text-center" />
                            </a>
                            <a 
                                v-if="block.contacts.length > 1"
                                class="px-2"
                                :class="index+1 == block.contacts.length ? 'opacity-25' : null"
                                @click.stop="onMoveOrder( contact, index, 'down', block.contacts)">
                                
                                <i class="fas fa-chevron-down mr-1 text-center" />
                            </a>
                            <a 
                                @click="block.contacts = block.contacts.filter( fId => fId != contact)"
                                class="text-blue-500">
                                
                                Remove
                            </a>
                        </div>
                        
                        <div class="px-4 py-2">
                            <InputSingleline 
                                v-model="contact.name"
                                label="Name"
                                type="text"
                                :required="true"
                            />
                            <InputSingleline 
                                v-model="contact.title"
                                label="Title"
                                type="text"
                                :required="true"
                            />
                            <InputSingleline 
                                v-model="contact.phone"
                                label="Phone"
                                type="text"
                                :required="false"
                            />
                            <InputSingleline 
                                v-model="contact.email"
                                label="Email"
                                type="text"
                                :required="false"
                            />
                            
                            <div class="overflow-hidden pb-2">
                                <label for="">Contact Image</label>
                                <div 
                                    v-if="contact.image"
                                    class="border overflow-hidden rounded bg-white">
                                    
                                    <div class="flex px-4 py-2 bg-gray-200">
                                        <div class="flex-grow">
                                            {{humanFileSize( contact.image.size) }}
                                        </div>
                                        <a 
                                            @click="contact.image = null"
                                            class="text-blue-500">
                                            
                                            Remove
                                        </a>
                                    </div>
                                    <img
                                        :src="fileToUrl( contact.image )" 
                                        class="max-h-32 w-full object-contain"
                                    />
                                </div>
                                
                                <InputUpload
                                    v-else
                                    name="block.image"
                                    :multiple="false"
                                    @input="onFilesAdded"
                                    @allSettled="$event && $event[0].status == 'fulfilled' ? contact.image = $event[0].value.file : null"
                                    path="files"
                                    :useRealName="false"
                                    :folderId="null"
                                />
                            </div>
                        </div>
                        
                    </div>
                </template>
            </div>
            <div v-else-if="block.type.code == 'products'">
                <InputSingleline 
                    v-model="block.heading"
                    label="Heading"
                    type="text"
                    :required="true"
                />
                <InputMultiline 
                    v-model="block.text"
                    label="Text"
                    name="text"
                    :required="false"
                />
                
                
                <div class="flex py-2">
                    <div class="flex-grow">
                        Products
                    </div>
                    <a 
                        @click.prevent="$emit('onAddBlock', 'product');"
                        class="text-blue-500">
                        
                        Add
                    </a>
                </div>
                
                <div 
                    v-if="!block.products.length"
                    class="bg-white rounded-lg p-8 border border-dashed mb-4 text-center"
                    @click.prevent="$emit('onAddBlock', 'product');">
                    No products yet
                </div>
                
                <template v-if="block.products">
                    <div 
                        v-for="(product,index) in block.products"
                        :key="'product_'+index"
                        class="mb-2 border overflow-hidden rounded bg-white ">
                        
                        <div class="flex bg-gray-200 px-4 py-2">
                            <div class="flex-grow text-black">
                                {{'Product '+(index+1)}}
                            </div>
                            <a 
                                v-if="block.products.length > 1"
                                class="px-2"
                                :class="index == 0 ? 'opacity-25' : null"
                                @click.stop="onMoveOrder( product, index, 'up', block.products)">
                                
                                <i class="fas fa-chevron-up mr-1 text-center" />
                            </a>
                            <a 
                                v-if="block.products.length > 1"
                                class="px-2"
                                :class="index+1 == block.products.length ? 'opacity-25' : null"
                                @click.stop="onMoveOrder( product, index, 'down', block.products)">
                                
                                <i class="fas fa-chevron-down mr-1 text-center" />
                            </a>
                            <a 
                                @click="block.products = block.products.filter( fId => fId != product)"
                                class="text-blue-500">
                                
                                Remove
                            </a>
                        </div>
                        
                        <div class="px-4 py-2">
                            <InputSingleline 
                                v-model="product.name"
                                label="Name"
                                type="text"
                                :required="true"
                            />
                            <InputMultiline 
                                v-model="product.description"
                                label="Description"
                                :required="false"
                            />
                            <InputSingleline 
                                v-model="product.price"
                                label="Price"
                                type="text"
                                :required="false"
                            />
                            <InputSingleline 
                                v-model="product.unit"
                                label="Unit"
                                type="text"
                                :required="true"
                            />
                            <InputSingleline 
                                v-model="product.qty"
                                label="Quantity"
                                type="number"
                                :required="false"
                            />
                            
                            <div class="overflow-hidden pb-2">
                                <label for="">Product Image</label>
                                <div 
                                    v-if="product.image"
                                    class="border overflow-hidden rounded bg-white">
                                    
                                    <div class="flex px-4 py-2 bg-gray-200">
                                        <div class="flex-grow">
                                            {{humanFileSize( product.image.size) }}
                                        </div>
                                        <a 
                                            @click="product.image = null"
                                            class="text-blue-500">
                                            
                                            Remove
                                        </a>
                                    </div>
                                    <img
                                        :src="fileToUrl( product.image )" 
                                        class="max-h-32 w-full object-contain"
                                    />
                                </div>
                                
                                <InputUpload
                                    v-else
                                    name="block.image"
                                    :multiple="false"
                                    @input="onFilesAdded"
                                    @allSettled="$event && $event[0].status == 'fulfilled' ? product.image = $event[0].value.file : null"
                                    path="files"
                                    :useRealName="false"
                                    :folderId="null"
                                />
                            </div>
                        </div>
                        
                    </div>
                </template>
            </div>
            <div v-else-if="block.type.code == 'orderForm'">
                <InputSingleline 
                    v-model="block.heading"
                    label="Heading"
                    type="text"
                    :required="true"
                />
                
                <InputMultiline 
                    v-model="block.text"
                    label="Text"
                    name="text"
                    :required="false"
                />
                
                <InputSingleline 
                    v-model="block.button"
                    label="Button"
                    type="text"
                    :required="true"
                />
                
                <InputMultiline 
                    v-model="block.confirmation"
                    label="Confirmation text"
                    name="text"
                    :required="true"
                />
                
                <InputMultiline 
                    v-model="block.termsAndConditions"
                    label="Terms And Conditions"
                    name="text"
                    :required="false"
                />
                
                
                
                <div class="flex py-2">
                    <div class="flex-grow">
                        Form Fields
                    </div>
                    <a 
                        @click.prevent="$emit('onAddBlock', 'orderFormField');"
                        class="text-blue-500">
                        
                        Add
                    </a>
                </div>
                
                
                <template v-if="block.fields">
                    <div 
                        v-for="(field, index) in block.fields"
                        :key="'field_'+index"
                        class="mb-2 border overflow-hidden rounded bg-white ">
                        
                        <div class="flex bg-gray-200 px-4 py-2">
                            <div class="flex-grow text-black">
                                {{ 'Field '+(index+1) }}
                            </div>
                            <a 
                                v-if="block.fields.length > 1"
                                class="px-2"
                                :class="index == 0 ? 'opacity-25' : null"
                                @click.stop="onMoveOrder( field, index, 'up', block.fields)">
                                
                                <i class="fas fa-chevron-up mr-1 text-center" />
                            </a>
                            <a 
                                v-if="block.fields.length > 1"
                                class="px-2"
                                :class="index+1 == block.fields.length ? 'opacity-25' : null"
                                @click.stop="onMoveOrder( field, index, 'down', block.fields)">
                                
                                <i class="fas fa-chevron-down mr-1 text-center" />
                            </a>
                            <a 
                                @click="block.fields = block.fields.filter( fId => fId != field)"
                                class="text-blue-500">
                                
                                Remove
                            </a>
                        </div>
                        <div class="p-4">
                            <div class="flex">
                                <label class="w-32">Label</label>
                                <input 
                                    type="text" 
                                    v-model="field.name"
                                />
                            </div>
                            
                            <div class="flex">
                                <label class="w-32">Type</label>
                                <select v-model="field.type">
                                    <option value="string">String</option>
                                    <option value="textarea">Textarea</option>
                                    <option value="checkbox">Checkbox</option>
                                </select>
                            </div>
                            
                            <div 
                                v-if="field.type != 'checkbox'"
                                class="flex">
                                
                                <label class="w-32">Placeholder</label>
                                <input 
                                    type="text" 
                                    v-model="field.placeholder"
                                />
                            </div>
                            
                            <div class="flex items-center">
                                <label class="w-20 mr-2">Required</label>
                                <div class="flex-grow">
                                    <input 
                                        type="checkbox" 
                                        v-model="field.required"
                                    />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </template>
            </div>
            
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'block',
        ],
        
        data(){
            return {
                isOpen: false,
            }
        },
        
        methods: {
            onFilesAdded( event ){
                console.log('onFilesAdded');
                console.log('event', event);
            },
            
            onMoveOrder( object, currentIndex, direction, array){
                array.splice( currentIndex, 1);
                
                let indexNew;
                
                if (direction == 'up') {
                    indexNew = currentIndex - 1;
                }
                else if (direction == 'down') {
                    indexNew = currentIndex + 1;
                }
                
                array.splice( indexNew, 0, object);
            },
        },
    }
</script>

<style scoped lang="scss">
    
</style>
