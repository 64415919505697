<template>
    <div class="form-component">
        <label 
            v-if="label"
            :for="randomId">
            
            {{label}}
            
            <span 
                v-if="required"
                class="required">
                * 
            </span>
        </label>
        
        <div class="relative">
            <input 
                :type="typeFormated || 'text'" 
                :step="step" 
                :name="name" 
                :placeholder="placeholder" 
                :id="randomId"
                :value="value"
                @input="$emit('input', $event.target.value)"
                :required="required"
                :pattern="pattern"
                :readonly="readonly"
                :ref="randomId"
                
            />
            <!-- :ref="autofocus ? 'autofocus': null"
            <div v-if="autofocus">
                autofocus!
            </div> -->
            <i 
                v-if="type == 'password'"
                class="fas w-8 mr-2 absolute top-0 bottom-0 right-0 flex items-center justify-center"
                :class="showPassword ? 'fa-eye-slash' : 'fa-eye' "
                @click="showPassword = !showPassword"
            />
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'name',
            'type',
            'step',
            'label',
            'value',
            'pattern',
            'readonly',
            'required',
            'autofocus',
            'placeholder',
            'showRequiredWarning',
        ],
        
        data(){
            return {
                randomId: null,
                showPassword: false,
            }
        },
        
        computed: {
            typeFormated(){
                if ( this.showPassword) {
                    return 'text';
                }
                return this.type;
            }
        },
        
        mounted(){
            const numString = ''+Math.random()*10000;
            this.randomId = numString.replace('.','');
            
            this.$nextTick( () => {
                if ( this.autofocus) {
                    this.$refs[this.randomId].focus();
                }
            });
        },
    }
</script>

<style scoped lang="scss">
    
</style>
