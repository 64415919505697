<template>
    <div class="w-full h-full overflow-auto">
        <div class="max-w-lg mx-auto overflow-hidden rounded-lg my-12 ">
            <div class="bg-blue-900 text-white p-8">
                <div class="">
                    <img src="https://www.getcloudcast.com/logo.svg" class="w-32" />
                    <h1 class="text-white opacity-50 my-0 leading-none ml-3 text-4xl tracking-tight my-1">Partner Portal</h1>
                </div>
            </div>
            <div 
                class="bg-white p-8"
                v-if="ready">
                
                <form 
                    class="w-full mb-1" 
                    @submit.prevent="signIn()">
                    
                    <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                        {{error}}
                    </div>
                    
                    <InputSingleline 
                        v-model="email"
                        label="Email"
                        name="email"
                        placeholder="john@company.com"
                        class="w-full -mt-4"
                        type="email"
                        :required="true"
                    />
                    
                    <InputSingleline 
                        v-model="password"
                        label="Password"
                        name="password"
                        class="w-full "
                        type="password"
                        :required="true"
                    />
                    
                    <button 
                        type="submit" 
                        class="button submit mb-4 w-full" 
                        :class="{'loading' : loading}"
                        :disabled="loading">
                        
                        Sign In
                    </button>
                </form>
                
                <div class="text-center">
                    <router-link 
                        :to="{ name: 'resetpassword'}"
                        class="mb-2 inline-block mx-auto">
                        
                        Forgot your password
                    </router-link>
                    
                    <br>
                    <!-- 
                    <router-link 
                        :to="{ name: 'resend'}"
                        class="inline-block mx-auto">
                        
                        Resend email for validation
                    </router-link>
                    
                    <br> -->
                    
                    <router-link 
                        :to="{ name: 'signup'}"
                        class="inline-block mx-auto">
                        
                        Go to Partner Sign up page
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AuthMixins from '@/mixins/AuthMixins';
    
    export default {
        mixins: [
            AuthMixins,
        ],
        
        data() {
            return {
                ready: false,
                email: null,
                password: null,
                error: null,
                loading: false,
            }
        },
        
        async created() {
            const signedInStatus = await this.checkUser();
            
            const emailAsParam = this.$route.query.email;
            if (this.emailIsValid(emailAsParam)) {
                this.email = emailAsParam;
                // console.log(emailAsParam);
            }
            
            if (signedInStatus) {
                this.$router.push({name: 'accounts'});
            }
            else {
                this.ready = true;
            }
        },
    }
</script>
