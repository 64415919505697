<template>
    <div class="">
		<Explain
			heading="What are customer contacts?"
			text="These contacts are only visible for you as a partner, and are located here for your convience."
		/>
        
		<Contacts 
			:contacts="customerCurrent.customerEdit.contacts"
			@delete="onDeleteContact"
			:reset="customerCurrent.resetTimestamp"
		/>
		
        <div class="buttons-wrapper mt-8">
            <a 
                v-if="customerCurrent.isChangesMade && !loading"
                class="button "
                :disabled="loading"
                @click.prevent="$emit('onCancelCustomer')">
                
                Cancel
            </a>
            
            <button 
                :disabled="loading"
                type="submit"
                class="button submit ml-4"
                :class="[
                    {'loading' : loading == 'updating'},
                    {'opacity-25 pointer-events-none' : !customerCurrent.isChangesMade}
                ]"
                @click.prevent="onUpdateCustomer()">
                
                Update
            </button>
        </div>
        
		<!-- <pre v-if="customerCurrent">{{ customerCurrent.customerEdit.contacts }}</pre> -->
    </div>
</template>

<script>
    export default {
		components: {
			'Contacts': () => import('@/components/Contacts.vue'),
		},
		
        props: [
            'customerCurrent',
        ],
        
        data() {
            return {
                customerId: null,
				loading: null,
                // contactsEdit: null,
				// resetTimestamp: null,
            }
        },
        methods: {
			onDeleteContact( contact ){
				console.log('parent contact passed', contact);
				this.customerCurrent.customerEdit.contacts = this.customerCurrent.customerEdit.contacts.filter( c => c != contact); 
				this.customerCurrent.resetTimestamp = new Date().getTime();
			},
			
            async onUpdateCustomer(){
                try {
                    this.loading = 'updating';
                    
                    const customerFieldsToUpdate = {
                        customerId: this.customerCurrent.customerEdit.customerId,
                        partnerContacts: this.customerCurrent.customerEdit.contacts,
                    };
                    
                    await this.$store.dispatch('updateCustomer', customerFieldsToUpdate);
                    
                    this.$notify({ type: 'success', text: 'Updated Customer' });
                    this.customerCurrent.isChangesMade = false;
                } 
                catch (e) {
                    this.$notify({ 
                        type: 'error', 
                        text: e.message
                    });
                    
                    console.error(e);
                } 
                finally {
                    this.loading = null;
                }
            },
            
        }
    }
</script>