// import {Auth} from 'aws-amplify';
import {Auth} from '@aws-amplify/auth';

export default {
    data() {
        return {
            popUpWindow: null,
            polling: null,
        }
    },
    
    methods: {
        signUp(){
            this.error = null;
            this.loading = true;
            this.email = this.email.toLowerCase().trim();
            
            Auth.signUp({
                username: this.email,
                password: this.password,
                attributes: {
                    email: this.email,
                }
            })
            .then( user => {
                console.log('auth signup response');
                console.log(user);
                this.user = user;
                this.loading = false;
            })
            .catch( err => {
                console.log(err)
                this.error = err.message;
                this.loading = false;
            });
        },
        
        confirmSignUp(){
            this.error = null;
            this.loading = true;
            this.email = this.email.toLowerCase().trim();
            
            // After retrieving the confirmation code from the user
            Auth.confirmSignUp(this.email, this.confirmCode, {
                // Optional. Force user confirmation irrespective of existing alias. By default set to True.
                forceAliasCreation: true    
            })
            .then( data => {
                console.log('confirmSignUp response');
                console.log(data);
                this.signIn();
                this.loading = false;
            })
            .catch( err => {
                console.log(err)
                this.error = err.message;
                this.loading = false;
            });
        },
        
        resendSignUp(){
            this.error = null;
            this.loading = true;
            this.email = this.email.toLowerCase().trim();
            
            Auth.resendSignUp(this.email)
            .then(() => {
                this.loading = false;
                this.$notify({ type: 'success', text: 'The code was resent. Please check your email inbox' });
            })
            .catch( e => {
                this.$notify({ 
                    type: 'error', 
                    title: 'Error', 
                    text: 'There was a problem while resending the confirmation code'
                });
                console.error(e);
                this.error = e.message;
                this.loading = false;
            });
        },
        
        async signIn() {
            try {
                this.error = null;
                this.loading = true;
                this.email = this.email.toLowerCase().trim();
                
                const user = await Auth.signIn({
                    username: this.email,
                    password: this.password,
                });
                
                console.log('user', user);
                
                let signedInStatus = await this.checkUser();
                console.log('signedInStatus', signedInStatus);
                
                // this.loading = false;
                
                
                
                
                if (signedInStatus) {
                    // this.segmentTrack('Logged in', {
                    //     category: 'Account',
                    // });
                    this.$router.push({name: 'accounts'});
                }
                else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    console.log('NEW_PASSWORD_REQUIRED');
                    console.log('user.challengeParam', user.challengeParam);
                    this.$router.push({ name: 'newpasswordrequired', params: { user } });
                }
                else if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    // You need to get the code from the UI inputs
                    // and then trigger the following function with a button click
                    
                    const code = prompt('Enter Two-step authentication code', '');
                    if (!code) {
                        throw Error( 'Need a one time MFA token from authenticator app.' )
                    }
                    
                    // If MFA is enabled, sign-in should be confirmed with the confirmation code
                    const mfaType = 'SOFTWARE_TOKEN_MFA';
                    const loggedUser = await Auth.confirmSignIn( user, code, mfaType );
                    console.log('loggedUser', loggedUser);
                    
                    signedInStatus = await this.checkUser();
                    console.log('signedInStatus', signedInStatus);
                    
                    if (signedInStatus) {
                        this.$router.push({name: 'accounts'});
                    }
                    else {
                        throw Error( 'Could not authenticate with token.' );
                    }
                }
                else {
                    // this.segmentTrack('Logged in failed', {
                    //     category: 'Account',
                    // });
                    this.$router.push({ name: 'signin', params: { error: 'Could not sign in.' } });
                }
            } 
            catch (err) {
                console.log(err);
                this.error = err.message;
                this.loading = false;
            }
        },
        
        async checkUser(){
            try {
                const user = await Auth.currentAuthenticatedUser();
                // console.log('checkUser', user);
                // this.$store.state.signedIn = true;
                // 
                this.$store.commit('setUser', user);
                // this.$store.commit('setAuthorizationHeader', jwt);
                // console.log('checkUser in auth', user);
                return user;
            }
            catch (err) {
                console.log('checkUser error', err);
                // console.log('checkUser in auth NOPE');
                // this.$store.state.signedIn = false;
                // this.$store.state.user = null;
                this.$store.commit('setUser', null);
                // this.$store.commit('setAuthorizationHeader', null);
            }
        },
        
        signOut(){
            Auth.signOut()
            .then( data => {
                // this.segmentTrack('Signed Out', {
                //     category: 'Account',
                // });
                this.$store.commit('setUser', null);
                this.$store.commit('setAccount', null);
                this.$router.push({name: 'signin'});
                
            })
            .catch( err => {
                console.log(err);
                this.error = err.message;
            });
        },
        
        resetPasswordEmail(){
            this.error = null;
            this.loading = false;
            this.email = this.email.toLowerCase().trim();
            
            Auth.forgotPassword(this.email)
            .then(data => {
                console.log('data from forgotPassword', data);
                this.confirmInProgress = data;
            })
            .catch(err => {
                console.log(err);
                this.error = err.message;
            });
        },
        
        resetPasswordSubmit(){
            this.error = null;
            this.email = this.email.toLowerCase().trim();
            this.loading = false;
            
            // Collect confirmation code and new password, then
            Auth.forgotPasswordSubmit(this.email, this.confirmCode, this.password)
            .then(data => {
                console.log('data from forgotPasswordSubmit', data);
                this.signIn();
            })
            .catch(err => {
                console.log(err);
                this.error = err.message;
                this.loading = false;
            });
        },
        
        newPasswordSubmit(){
            this.error = null;
            this.loading = true;
            this.email = this.user.challengeParam.userAttributes.email;
            
            Auth.completeNewPassword( this.user, this.password)
            .then(user => {
                // at this time the user is logged in if no MFA required
                console.log(user);
                this.signIn();
            })
            .catch(err => {
                console.log(err);
                this.error = err.message;
                this.loading = false;
            });
        },
    }
}
