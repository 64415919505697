<template>
    <div 
		class="h-full"
		v-if="ready">
		
		<!--
        <h1>{{visual.title}}</h1>
        
        <div class="">
            Updated {{visual.updatedAt || visual.updatedAt | moment('lll') }}
        </div>
        
        <hr class="my-6">
        
		
        <div class="flex items-start">
            <button 
                class="button submit mr-4 flex-none"
                @click.prevent="showEdit = !showEdit">
                
                Edit Visual
            </button>
        </div>
         -->
		
		<!-- v-if="showEdit"
		@close="showEdit = false" -->
		
        <Modal 
            insideClasses="bg-white absolute inset-0 m-8 flex "
            width=""
			@close="onClose">
            
            <div class="w-full h-full overflow-hidden flex">
				
				<div class="flex-grow bg-gray-400 flex overflow-hidden relative">
					<div 
						id="canvasWrapper"
						class="overflow-auto w-full h-full relative">
						
						<div 
							v-if="wrapper.width && wrapper.height"
							class="overflow-hidden absolute"
							:style="`
								width: ${visualEdit.width * scaleFactor}px; 
								height: ${visualEdit.height * scaleFactor}px; 
								
								top: ${ (visualEdit.height * scaleFactor) < (wrapper.height) ? 50 : 0}%;
								margin-top: -${ (visualEdit.height * scaleFactor) < (wrapper.height) ? ((visualEdit.height*scaleFactor) /2) : 0}px; 
								
								left: ${ (visualEdit.width * scaleFactor) < (wrapper.width) ? 50 : 0}%;
								margin-left: -${ (visualEdit.width * scaleFactor) < (wrapper.width) ? ((visualEdit.width*scaleFactor) /2) : 0}px; 
							`"
							v-show="!exporting">
							
							<div 
								id="canvas"
								:style="`
									width: ${visualEdit.width}px; 
									height: ${visualEdit.height}px; 
									background: #fff url(${visualEdit.background && visualEdit.background.file ? visualEdit.background.file.url : ''}); 
									background-size: ${visualEdit.background.size ? (visualEdit.background.size && isNaN(visualEdit.background.size) ? visualEdit.background.size  : visualEdit.background.size+'%' ) : 'auto'};
									background-repeat: no-repeat;
									background-position-x: ${visualEdit.background.position ? visualEdit.background.position.x+'px' : '0'};
									background-position-y: ${visualEdit.background.position ? visualEdit.background.position.y+'px' : '0'};
									position: relative;
									overflow: hidden;
									flex: none;
									transform: scale(${scaleFactor ? scaleFactor : 1});
								`"
								class="border border-gray-500 "
								style="transform-origin: top left;"
								@click.prevent="onActivateTarget">
								
								<div
									v-for="screen in visualEdit.screens" 
									:key="screen.screenId">
									
									<div 
										v-if="
											(screen.source.type == 'image' && !screen.file) ||
											(screen.source.type == 'iframe' && (!screen.source.url || screen.source.url == '' ) )
										"
										:id="screen.screenId"
										:class="'target '+screen.screenId"
										:style="`background: #0f0; position: absolute; top: 0px; left: 0px; overflow: hidden; transform: translate(0px, 0px); ${screen.cssText} filter: blur(${screen.blur}px); outline: ${screen.frame ? screen.frame.width : 0}px solid ${screen.frame ? screen.frame.color : 'black'};` "
									/>
									
									<img 
										v-if="screen.source.type == 'image' && screen.file && screen.file.url"
										:id="screen.screenId"
										:class="'target '+screen.screenId"
										:style="`background: #000; position: absolute; top: 0px; left: 0px; overflow: hidden; transform: translate(0px, 0px); ${screen.cssText} filter: blur(${screen.blur}px); outline: ${screen.frame ? screen.frame.width : 0}px solid ${screen.frame ? screen.frame.color : 'black'};` "
										:src="screen.file.url" 
									/>
									
									<div 
										v-if="screen.source.type == 'iframe' && screen.source.url && screen.source.url != ''"
										:id="screen.screenId"
										:class="'target '+screen.screenId"
										:style="`background: #000; position: absolute; top: 0px; left: 0px; overflow: hidden; transform: translate(0px, 0px); ${screen.cssText} filter: blur(${screen.blur}px); outline: ${screen.frame ? screen.frame.width : 0}px solid ${screen.frame ? screen.frame.color : 'black'};` ">
										
										<iframe 
											:src="screen.source.url" 
											scrolling="no"
											:style="`background: #000; position: absolute; top: 0; left: 0; ${screen.orientation && screen.orientation == 'portrait' ? 'width: 1080px; height: 1920px;' : 'width: 1920px; height: 1080px;'} display: block; pointer-events:none; border: 0; pointer-events: none; transform-origin: top left; transform: scale(${screen.scale}); `"
										/>
									</div>
									
									<Moveable
										v-if="activeScreenId == screen.screenId"
										className="moveable"
										:target="['.target.'+screen.screenId]"
										:draggable="true"
										:rotatable="false"
										:keepRatio="true"
										
										:resizable="activeMovementFunction == 'resize'"
										:warpable="activeMovementFunction == 'warp'"
										@drag="onDrag"
										@resize="onResize"
										@rotate="onRotate"
										@warp="onWarp"
									/>
								</div>
							</div>
						</div>
					</div>
						
					<div class="absolute bottom-0 right-0 m-4 flex h-12">
						<a 
							v-if="activeScreen"
							class=" rounded bg-white border text-center p-3"
							:class="activeMovementFunction == 'resize' ? 'opacity-100': 'opacity-50'"
							@click="activeMovementFunction = 'resize'">
							
							Resize
						</a>
						<a 
							v-if="activeScreen"
							class=" rounded bg-white border text-center p-3"
							:class="activeMovementFunction == 'warp' ? 'opacity-100': 'opacity-50'"
							@click="activeMovementFunction = 'warp'">
							
							Warp
						</a>
						
						<input 
							type="number" 
							step=".1" 
							v-model.number="scaleFactor"
							class="p-3 m-0 ml-4"
							style="margin-bottom: 0; width: 5rem; text-align: center;"
						/>
					</div>
				</div>
				
				<div class="flex-none w-64 border-l bg-gray-100 flex flex-col">
					<!-- <pre>width: {{ visualEdit.width }}</pre>
					<pre>width sized: {{ visualEdit.width * scaleFactor }}</pre>
					
					<pre>height: {{ visualEdit.height }}</pre>
					<pre>height sized: {{ visualEdit.height * scaleFactor }}</pre>
					
					<pre>scaleFactor: {{ scaleFactor }}</pre>
					<pre>wrapper: {{ wrapper }}</pre> -->
					
					<div class="flex-grow overflow-auto px-6 pt-8">
						<section v-if="!activeScreen">
							<input 
								v-model="visualEdit.visualId"
								type="hidden" 
							/>
							
							<label for="">Name</label>
							<input 
								v-model="visualEdit.name"
								type="text" 
							/>
							
							<label for="">Width</label>
							<input 
								v-model.number="visualEdit.width"
								type="number" 
								step="10"
							/> 
							
							<label for="">Height</label>
							<input 
								v-model.number="visualEdit.height"
								type="number" 
								step="10"
							/>
							
							<label>Background Image</label>
							
							<div 
								v-if="visualEdit.background && visualEdit.background.file && visualEdit.background.file.url"
								class="border overflow-hidden rounded bg-white">
								
								<img
									:src="visualEdit.background.file.url"
									class="h-32 w-full object-contain p-4"
								/>
								
								<a class="link m-3 block text-center" @click="visualEdit.background.file = null">Remove image</a>
							</div>
							<InputUpload
								v-else
								name="image"
								:label="'Upload Image'"
								:multiple="false"
								@input="onFilesAdded( $event, 'background' )"
								:path="path"
								:useRealName="false"
								:folderId="null"
							/>
							
							<label for="">Background Size</label>
							<select 
								v-model="visualEdit.background.size"
								@input="visualEdit.background.position.x = 0; visualEdit.background.position.y = 0">
								<option value="cover" >Cover</option>
								<option value="contain" >Contain</option>
								<option value="fill" >Fill</option>
								<option value="auto" >Auto</option>
								<option :value="100">Zoomed</option>
							</select>
							
								
							<div v-if="!isNaN(visualEdit.background.size)">
								<input 
									v-model="visualEdit.background.size"
									type="number" 
									step="1"
									min="10"
								/>
								
								<div v-if="visualEdit.background && visualEdit.background.position">
									<label for="">Background Position</label>
									
									<div class="flex flex-wrap">
										<div class="flex items-center">
											<div class="w-8 mb-3">
												X
											</div>
											<input 
												v-model.number="visualEdit.background.position.x"
												type="number" 
												step="10"
											/>
										</div>
										
										<div class="flex items-center">
											<div class="w-8 mb-3">
												Y
											</div>
											<input 
												v-model.number="visualEdit.background.position.y"
												type="number" 
												step="10"
											/>
										</div>
									</div>
								</div>
							</div>
						</section>
						
						<section 
							class="border-t my-4 py-4"
							v-if="!activeScreen">
							
							<h3 class="text-black">Add Screen</h3>
							
							<div class="flex space-x-3 items-start">
								<select v-model="newScreenSelected">
									<option 
										v-for="(s,sI) in screensLibrary"
										:key="sI"
										:value="s">
										
										{{s.name}}
									</option>
								</select>
								<a 
									v-if="newScreenSelected"
									class="button submit"
									@click="onAddScreen">
									Add
								</a>
							</div>
						</section>
						
						<section 
							v-if="activeScreen"
							class=""
							:style="activeScreenId ? '' : 'opacity: .25;'">
							
							<label for="">Screen Soure Type</label>
							<select v-model="activeScreen.source.type">
								<option value="iframe" >Iframe</option>
								<option value="image" >Image</option>
							</select>
							
							<template v-if="activeScreen.source && activeScreen.source.type && activeScreen.source.type == 'iframe'">
								<label for="">{{activeScreen.source.type == 'iframe' ? 'Iframe' : 'Image'}} URL</label>
								<input 
									v-model="activeScreen.source.url"
									type="text" 
								/>
								
							</template>
							<template v-else>
								<div 
									v-if="activeScreen.file && activeScreen.file.url"
									class="border overflow-hidden rounded bg-white">
									
									<img
										:src="activeScreen.file.url"
										class="h-32 w-full object-contain p-4"
									/>
									
									<a class="link m-3 block text-center" @click="onRemoveScreenImage">Remove image</a>
								</div>
								<InputUpload
									v-else
									name="image"
									:label="'Upload Image'"
									:multiple="false"
									@input="onFilesAdded( $event, 'screen' )"
									:path="path"
									:useRealName="false"
									:folderId="null"
								/>
							</template>
							
							<div v-if="activeScreen.source.type == 'iframe'">
								<label for="">Screen Scale</label>
								<input 
									v-model.number="activeScreen.scale"
									type="number"
									step=".01"
									min="0.1"
								/>
							</div>
							
							<label for="">Frame Width</label>
							<input 
								v-model.number="activeScreen.frame.width"
								type="number"
								step="1"
							/>
							
							<label for="">Frame Color</label>
							<input 
								v-model="activeScreen.frame.color"
								type="color"
							/>
							
							
							<label for="">Screen Blur</label>
							<input 
								v-model="activeScreen.blur"
								type="number"
								step=".1"
							/>
							
							<hr>
							
							<div class="grid grid-cols-2 gap-2 mt-4">
								<button 
									@click="onDeleteScreen( activeScreenId )"
									class="button">
									
									Delete
								</button>
								<button 
									@click="onDuplicateScreen( activeScreenId )"
									class="button">
									
									Duplicate
								</button>
							</div>
						</section>
					</div>
					
					<section class="flex-none bg-gray-300 py-4 flex gap-4 px-4">
						<a 
							v-if="isChangesMade && !loading"
							class="button flex-1"
							:disabled="loading"
							@click.prevent="onCancelVisual()">
							
							Cancel
						</a>
						<button 
							v-else
							class="button flex-1"
							@click.prevent="showExportModal = !showExportModal">
							
							Export
						</button>
						
						<Modal 
							v-if="showExportModal"
							@close="showExportModal = false">
							
							<header slot="header">
								<div class="py-4 px-6">
									Export image
								</div>
							</header>
							
							<form 
								@submit.prevent="onExportVisual()"
								class="pb-6 px-6">
								
								<label>File format</label>
								<select v-model="exportSettings.extension">
									<option value="jpg">JPEG</option>
									<option value="png">PNG</option>
								</select>
								
								<label>Delay in seconds before taking screenshot</label>
								<p class="opacity-75">If any of the screens use Iframe as a source type, the content needs to initialize before it is visible. A short delay can wait for the frame is ready.</p>
								<input 
									v-model.number="exportSettings.secondsDelay"
									type="number"
									step="1"
									max="5"
									min="0"
								/>
							</form>
							
							<footer slot="footer">
								<div class="buttons-wrapper">
									<a 
										class="button transparent link"
										@click.prevent="showExportModal = false">
										
										Cancel
									</a>
									<button 
										:disabled="loading"
										class="button submit"
										:class="[
											{'loading' : loading == 'exporting'},
										]"
										@click.prevent="onExportVisual()">
										
										Export
									</button>
								</div>
							</footer>
						</Modal>
						
						<button 
							:disabled="loading"
							type="submit"
							class="button submit flex-1"
							:class="[
								{'loading' : loading == 'updating'},
								{'opacity-25 pointer-events-none' : !isChangesMade}
							]"
							@click.prevent="onUpdateVisual()">
							
							Save
						</button>
					</section>
				</div>
				
			</div>
		</Modal>
	</div>
</template>

<script>
/* eslint-disable no-param-reassign,no-unused-expressions,no-console */
import Moveable from 'vue-moveable';

export default {
	
    components: {
        Moveable,
    },
    
	computed: {
		objectToCheckIfChanged(){
			return JSON.stringify(this.visualEdit);
		},
		
		activeScreen(){
            if (
				!this.activeScreenId || 
				!this.visualEdit || 
				!this.visualEdit.screens.length ) {
				
                return
            }
            
            let screen = this.visualEdit.screens.find(s => s.screenId == this.activeScreenId);
            if (!screen.frame) {
                screen.frame = {
                    width: 0,
                    color: 'black',
                };
            }
            
            return screen;
        },
		
		path() {
			return 'files';
		},
	},
	
	watch: {
		objectToCheckIfChanged: function(newValue_str, oldValue_str) {
			if ( newValue_str != oldValue_str) {
				this.isChangesMade = true;
			}
			
			if ( !JSON.parse(oldValue_str) || this.isReverted ) {
				this.isChangesMade = false;
				this.isReverted = false;
			}
		},
		
		scaleFactor: function(newValue_str, oldValue_str) {
			// canvasWrapper
			let box = document.querySelector('#canvasWrapper');
			this.wrapper.width = box.offsetWidth;
			this.wrapper.height = box.offsetHeight;

		},
	},
	
    data() {
		return {
			visualId: null,
			visual: null,
			visualEdit: null,
			ready: false,
			loading: null,
			exporting: false,
			isReverted: false,
			isChangesMade: false,
			scaleFactor: 1,
			exportSettings: {
				extension: 'jpg',
				secondsDelay: 0,
			},
			wrapper: {
				width: 0,
				height: 0,
			},
			
			showExportModal: false,
			
			showEdit: false,
			activeScreenId: null,
			activeMovementFunction: 'resize',
			newScreenSelected: null,
			screensLibrary: [
				{
					name: 'Landscape',
					orientation: 'landscape',
					cssText: `width: 320px; height: 180px; left: calc(50% - 160px); top: calc(50% - 90px); `,
					frame: {
						width: 2,
						color: 'black',
					},
					source: {
						type: 'image',
					},
					file: {
						url: 'https://partner.getcloudcast.com/screens/screen_landscape.png',
					}
				},
				// {
				// 	name: 'Landscape Iframe',
				// 	orientation: 'landscape',
				// 	cssText: `width: 320px; height: 180px; left: calc(50% - 160px); top: calc(50% - 90px); `,
				// 	source: {
				// 		type: 'iframe',
				// 		url: 'https://www.getcloudcast.com/',
				// 	}
				// },
				
				{
					name: 'Portrait',
					orientation: 'portrait',
					cssText: `width: 180px; height: 320px; left: calc(50% - 90px); top: calc(50% - 160px); `,
					frame: {
						width: 2,
						color: 'black',
					},
					source: {
						type: 'image',
					},
					file: {
						url: 'https://partner.getcloudcast.com/screens/screen_portrait.png',
					}
				},
				// {
				// 	name: 'Portrait Iframe',
				// 	orientation: 'portrait',
				// 	cssText: `width: 180px; height: 320px; left: calc(50% - 90px); top: calc(50% - 160px); `,
				// 	source: {
				// 		type: 'iframe',
				// 		url: 'https://www.getcloudcast.com/',
				// 	}
				// },
			],
		}
	},
	
    methods: {
		onCancelVisual(){
			this.createVisualCopy();
			this.isReverted = true;
			this.$notify({ type: 'success', text: 'Changes was reset' });
		},
		
		createVisualCopy(){
			this.visualEdit = JSON.parse(
				JSON.stringify(
					Object.assign(
						this.visual,
					)
				)
			);
		},
		
		async onUpdateVisual(){
			try {
				this.loading = 'updating';
				
				document.querySelectorAll('.target').forEach( element => {
				    const screen = this.visualEdit.screens.find( s => s.screenId == element.id);
				    // console.log('screen', screen);
				    screen.cssText = element.style.cssText;
				});
				
				await this.$store.dispatch('updateVisual', this.visualEdit);
				this.$notify({ type: 'success', text: 'Updated Visual' });
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message
				});
				
				console.error(e);
			} 
			finally {
				this.loading = null;
				this.isChangesMade = false;
			}
		},
		
		onClose(){
			if ( this.isChangesMade ) {
				if (! confirm('Are you sure?')) return;
			}
			this.$router.push({ name: 'customer-visuals', params: { error: 'Could not redirect to Visuals' } });
		},
		
		
		
		updateScreen(){
			// this.activeScreenId = null;
            // this.visualEdit.screens = this.visualEdit.screens.filter( s => s.screenId != screenId);
			document.querySelectorAll('.target').forEach( element => {
				if (element.id == this.activeScreenId) {
					// console.log('screen', element.style.cssText);
					
					const screen = this.visualEdit.screens.find( s => s.screenId == element.id);
					if ( screen ) {
						screen.cssText = element.style.cssText;
						// console.log('screen', screen);
					}
				}
				
			});
			
		},
		
		
		
		
        onDragStart( event ) {
            // console.log('onDragStart', event);
			this.updateScreen();
        },
		
        onDrag({target, transform}) {
            // console.log(transform);
            target.style.transform = transform;
			this.updateScreen();
        },
		
        onResize(e) {
            // console.log(drag);
            // target.style.transform = drag.transform;
            e.target.style.width = `${e.width}px`;
            e.target.style.height = `${e.height}px`;
            e.target.style.transform = e.drag.transform;
			this.updateScreen();
        },
		
        onRotate({target, drag}) {
            // console.log(drag);
            target.style.transform = drag.transform;
			this.updateScreen();
        },
		
        onWarp({ target, transform }) {
            // console.log("onWarp", transform);
            target.style.transform = transform;
			this.updateScreen();
        },
		
        onActivateTarget( event ){
            if (event.target.id && event.target.id.startsWith('screen')) {
                this.activeScreenId = event.target.id;
            }
            else {
                this.activeScreenId = null;
            }
        },
        
        onAddScreen(){
            const newScreen = Object.assign(
                {},
                {
                    screenId: 'screen'+ new Date().getTime(),
                    blur: 0,
                    frame: {
                        width: 0,
                        color: 'black',
                    },
                    scale: 0.166666667,
                },
                this.newScreenSelected,
            );
            
            // console.log('onAddScreen', newScreen);
            this.visualEdit.screens.push( newScreen ); 
        },
        
        onDuplicateScreen( screenId ){
            // console.log('onDuplicateScreen', screenId);
            this.activeScreenId = null;
            const newScreenSelected = JSON.parse(JSON.stringify(this.visualEdit.screens.find( s => s.screenId == screenId)));
            
            this.visualEdit.screens.push( {
                ...newScreenSelected,
                screenId: 'screen_'+Date.now(),
            });
        },
        
        onDeleteScreen( screenId ){
            this.activeScreenId = null;
            this.visualEdit.screens = this.visualEdit.screens.filter( s => s.screenId != screenId);
        },
		
		onFilesAdded( fileData, entity ) {
			if (entity == 'background') {
				this.visualEdit.background = {
					...this.visualEdit.background,
					file: {
						...fileData.file,
						url: process.env.VUE_APP_FILES_ENDPOINT + '/'+this.partnerId+'/' + fileData.file.key
					}
				};
			}
			else {
				let screen = this.visualEdit.screens.find(s => s.screenId == this.activeScreenId);
				// activeScreen.file.url
				
				console.log(screen);
				
				if (screen) {
					screen.file = {
						...fileData.file,
						url: process.env.VUE_APP_FILES_ENDPOINT + '/'+this.partnerId+'/' + fileData.file.key
					};
				}
			}
		},
		
		onRemoveScreenImage(){
			let screen = this.visualEdit.screens.find(s => s.screenId == this.activeScreenId);
			
			if (screen) {
				screen.file = null;
			}
		},
		
		async onExportVisual(){
			try {
				this.exporting = true;
				this.loading = 'exporting';
				
				const oldScaleFactor = this.scaleFactor * 1;
				this.scaleFactor = 1;
				
				await new Promise( resolve => {
					setTimeout( () => {
						resolve('done scaling ');
					}, 1000);
				});
				
				const canvas = document.querySelector('#canvas');
				const canvasString = canvas.outerHTML.toString();
				
				const response = await this.$store.dispatch('exportVisual', {
					...this.visualEdit,
					canvas: canvasString,
					secondsDelay: this.exportSettings.secondsDelay,
					extension: this.exportSettings.extension,
				});
				
				// console.log('onExportVisual response', response );
				
				var element = document.createElement('a');
				element.setAttribute('href', response.fileBufferBase64 );
				element.setAttribute('download', this.visualEdit.name+'.'+this.exportSettings.extension );
				element.style.display = 'none';
				document.body.appendChild(element);
				element.click();
				document.body.removeChild(element);
				
				await new Promise( resolve => {
					setTimeout( () => {
						resolve('done exporting ');
					}, 1000);
				});
				
				this.scaleFactor = oldScaleFactor;
				
				this.$notify({ type: 'success', text: 'Exported Visual' });
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message
				});
				
				console.error(e);
			} 
			finally {
				this.loading = null;
				this.exporting = false;
			}
		},
    },
    
	async mounted(){
		this.visualId = this.$route.params.visualId;
		this.visual = await this.$store.dispatch('getVisual', this.visualId);
		this.createVisualCopy();
		this.ready = true;
		
		setTimeout(() => {
			let box = document.querySelector('#canvasWrapper');
			this.wrapper.width = box.offsetWidth;
			this.wrapper.height = box.offsetHeight;
			
			
			// temp! todo
			this.scaleFactor = .5;
			
			
		}, 100);
	}
};
</script>