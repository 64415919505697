<template>
	<div>
		<div class="py-8 container mx-auto flex">
			<div class="w-40 flex-none leading-loose">
				<ResourcesNavSidebar />
			</div>
			<div class="flex-grow">
				<section>
					<header class="text-black mb-4">
						Videos
					</header>
					
					<div class="py-8 container mx-auto ">
						<div class="bg-white rounded-lg p-8">
							<h2 class="text-black mb-2">Quick Walkthrough (NO)</h2>
							
							<p>
								A video walkthrough of CloudCast and some of the key consepts, such as Screen and connected Devices, Content Blocks and Templates. It is not a complete and thorough guide, but cover the basis.
								It's currently only narrated in Norwegian language. 
							</p>
							
							<div class="videoWrapper">
								<iframe width="560" height="315" src="https://www.youtube.com/embed/ZqKgdZ9qSAw?si=nQ_H_zMHRCnyrNW-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
							</div>
						</div>
					</div>
				</section>
				
			</div>
		</div>
		
	</div>
	
</template>

<script>
	export default {
		components: {
			'ResourcesNavSidebar': () => import('@/components/resources/ResourcesNavSidebar.vue'),
		},
		
		computed: {
			
		},
		
		data() {
			return {
				section: null,
				publicPath: process.env.BASE_URL,
			}
		},
		
		methods: {
			
		},
		
	}
</script>

<style>
	.videoWrapper {
		position: relative;
		padding-bottom: 56.25%;
		/* 16:9 */
		padding-top: 25px;
		height: 0;
	}

	.videoWrapper iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
</style>