<template>
	<div class="">
		<div class="panel grid grid-cols-1 ">
			<InputSingleline 
				v-model="customerCurrent.customerEdit.name"
				:required="true"
				label="Customer Name"
				name="name"
			/>
			
			<div class="">
				<label>Status</label>
				
				<Explain
					text="The status field for the customer tells where you consider to be in the process of time. "
				/>
				
				<select v-model="customerCurrent.customerEdit.status">
					<option 
						v-for="status in statuses"
						:key="'status_'+status.statusId"
						:value="status.statusId">
						
						{{status.name}}
					</option>
				</select>
			</div>
			
			<div class="">
				<label>Connected CloudCast Account
					<a 
						class="fas fa-redo-alt inline-flex items-center justify-center bg-white text-gray-400 w-4 h-4 cursor-pointer refresh text-xs ml-1"
						:class="{'loading': loading == 'refreshingCloudCastAccounts'}"
						@click.prevent="onRefreshCloudCastAccounts()"
					/>
				</label>
				
				<Explain
					:text="
					`
						Create and connect your customer's CloudCast account. If you cannot find the CloudCast account you need connect, get in touch and we will associate your partner account to the desired CloudCast account. 
						NB: This needs to be approved by the existing CloudCast account owner.
						
						In order to gain access to a specific CloudCast account, you need to be explicitly invited as user. 
						`"
				/>
				<!-- If you create a new CloudCast account below, it will automatically connect this Partner account <em>(${account.name})</em>, in addition to assign your current user <em>(${user.username})</em> as a CloudCast account user.  -->
				
				
				<div class="flex items-center">
					<select 
						v-model="customerCurrent.customerEdit.accountId"
						class="flex-grow mr-3">
						<optgroup label="CloudCast v1">
							<option 
								v-for="cloudCastAccount in cloudCastAccounts.filter( cCA => !cCA.version)"
								:key="'accountId_'+cloudCastAccount.accountId"
								:value="cloudCastAccount.accountId"
								:disabled="customerConnected.includes(cloudCastAccount.accountId)">
								
								{{cloudCastAccount.name}} ({{cloudCastAccount.accountId}})
							</option>
						</optgroup>
						
						<optgroup label="CloudCast v2">
							<option 
								v-for="cloudCastAccount in cloudCastAccounts.filter( cCA => cCA.version)"
								:key="'accountId_'+cloudCastAccount.accountId"
								:value="cloudCastAccount.accountId"
								:disabled="customerConnected.includes(cloudCastAccount.accountId)">
								
								{{cloudCastAccount.name}} ({{cloudCastAccount.accountId}})
							</option>
						</optgroup>
						
						<option :value="null">
							Not connected / Disconnect
						</option>
					</select>
					
					
					<i 
						v-if="!customerCurrent.customerEdit.accountId"
						class="flex-none fas fa-plus flex items-center justify-center w-6 h-6 bg-blue-500 text-white rounded-full mb-4" 
						:class="account.activatedAt ? '' : ' opacity-25'"
						:title="account.activatedAt ? 'Create CloudCast account' : 'Restricted to approved Partner accounts'"
						@click.prevent="account.activatedAt ? showCloudCastAccountNew = true : null"
					/>
				</div>
				
				
				
				
				<div v-if="cloudCastAccounts.find(cCA => cCA.accountId == customerCurrent.customerEdit.accountId)">
					<div v-if="user.signInUserSession.accessToken.payload['cognito:groups'].find( cognitoGroup =>  cognitoGroup.startsWith( customerCurrent.customerEdit.accountId ) )">
						Your user ({{ user.username }}) is a member of this CloudCast account.
					</div>
					<div v-else>
						NB: It seems like your user ({{ user.username }}) is <strong>not</strong> a member of this CloudCast account.
					</div>
					
					<div v-if="cloudCastAccounts.find(cCA => cCA.accountId == customerCurrent.customerEdit.accountId) && cloudCastAccounts.find(cCA => cCA.accountId == customerCurrent.customerEdit.accountId).version">
						<a 
							:href="'https://app2.getcloudcast.com/app/'+customerCurrent.customerEdit.accountId+'/dashboard'"
							target="_blank"
							class="mb-8 block link">
							
							Sign into CloudCast
						</a>
					</div>
					<div v-else>
						<a 
							:href="'https://app.getcloudcast.com/app/'+customerCurrent.customerEdit.accountId+'/dashboard'"
							target="_blank"
							class="mb-8 block link">
							
							Sign into CloudCast
						</a>
					</div>
				</div>
				
				
				
				
				
				
			</div>
			
			<InputSingleline 
				:value="customerCurrent.customer.createdAt || customer.createdAt | moment('lll')"
				label="Created At"
				readonly
			/>
			
			
			<div class="buttons-wrapper">
				<a 
					v-if="customerCurrent.isChangesMade && !loading"
					class="button "
					:disabled="loading"
					@click.prevent="$emit('onCancelCustomer')">
					
					Cancel
				</a>
				
				<button 
					:disabled="loading"
					type="submit"
					class="button submit ml-4"
					:class="[
						{'loading' : loading == 'updating'},
						{'opacity-25 pointer-events-none' : !customerCurrent.isChangesMade}
					]"
					@click.prevent="onUpdateCustomer()">
					
					Update
				</button>
			</div>
		</div>
		
				
		<div class="mt-8">
			<button 
				@click="onDelete()"
				class="button warning">
				
				Delete
			</button>
		</div>
		
		<Modal 
			v-if="showCloudCastAccountNew"
			@close="showCloudCastAccountNew = false"
			insideClasses="bg-white">
			
			<header slot="header">
				<div class="py-4 px-6">
					Create CloudCast Account
				</div>
			</header>
			
			<form 
				@submit.prevent="onCreateCloudCastAccount()"
				class="pb-2 px-6">
				
				<InputSingleline 
					v-model="cloudCastAccountNew.name"
					label="Account Name"
					name="name"
					:required="true"
					:autofocus="true"
				/>
			</form>
			
			<footer slot="footer">
				<div class="buttons-wrapper">
					<a 
						class="button transparent link" 
						@click="showCloudCastAccountNew = false">
						
						Cancel
					</a>
					
					<button 
						:disabled="loading"
						type="submit"
						class="button submit"
						:class="{'loading' : loading == 'creatingCloudCastAccount' }"
						@click.prevent="onCreateCloudCastAccount()">
						
						Create
					</button>
				</div>
			</footer>
		</Modal>
		
		
		<!-- 
		<Modal 
			v-if="showCloudCastAccountNew"
			@close="showCloudCastAccountNew = false"
			insideClasses="bg-white">
			
			<header slot="header">
				<div class="py-4 px-6">
					Create CloudCast Account
				</div>
			</header>
			
			<form 
				@submit.prevent="onCreateCloudCastAccount()"
				class="pb-2 px-6">
				
				<InputSingleline 
					v-model="cloudCastAccountNew.name"
					label="Account Name"
					name="name"
					:required="true"
					:autofocus="true"
				/>
			</form>
			
			<footer slot="footer">
				<div class="buttons-wrapper">
					<a 
						class="button transparent link" 
						@click="showCloudCastAccountNew = false">
						
						Cancel
					</a>
					
					<button 
						:disabled="loading"
						type="submit"
						class="button submit"
						:class="{'loading' : loading == 'creatingCloudCastAccount' }"
						@click.prevent="onCreateCloudCastAccount()">
						
						Create
					</button>
				</div>
			</footer>
		</Modal>
		 -->
	</div>
</template>

<script>
	export default {
		props: [
			'customerCurrent',
		],
		
		computed: {
			user(){
				return this.$store.getters.getUser;
			},
			
			cloudCastAccounts(){
				const cloudCastAccounts = this.$store.getters.getCloudCastAccounts;
				return cloudCastAccounts;
			},
			
			customers(){
				const customers = this.$store.getters.getCustomers;
				return customers;
			},
			
			customerConnected(){
				return this.customers.map( c => c.accountId);
			},
		},
		
		data() {
			return {
				customerId: null,
				loading: null,
				showCloudCastAccountNew: false,
				cloudCastAccountNew: {
					name: null,
				},
			}
		},
		methods: {
			async onRefreshCloudCastAccounts(){
				this.loading = 'refreshingCloudCastAccounts';
				await this.$store.dispatch('listCloudCastAccounts');
				this.loading = null;
			},
			
			async onCreateCloudCastAccount(){
				try {
					if (!this.cloudCastAccountNew.name) {
						throw Error( 'The account needs a name' )
					}
					
					this.loading = 'creatingCloudCastAccount';
					
					const response = await this.$store.dispatch('createCloudCastAccount', this.cloudCastAccountNew);
					console.log('response', response);
					
					if (!response.accountId) {
						throw Error('Could not get the accountId');
					}
					this.customerCurrent.customerEdit.accountId = response.accountId;
					this.$notify({ type: 'success', text: 'CloudCast account created and selected' });
					this.showCloudCastAccountNew = false;
					this.cloudCastAccountNew.name = null;
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						text: e.message
					});
					
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
			async onUpdateCustomer(){
				try {
					this.loading = 'updating';
					
					const customerFieldsToUpdate = {
						customerId: this.customerCurrent.customerEdit.customerId,
						name: this.customerCurrent.customerEdit.name,
						status: this.customerCurrent.customerEdit.status,
						accountId: this.customerCurrent.customerEdit.accountId,
					};
					
					console.log(customerFieldsToUpdate);
					
					await this.$store.dispatch('updateCustomer', customerFieldsToUpdate);
					
					this.$notify({ type: 'success', text: 'Updated Customer' });
					this.customerCurrent.isChangesMade = false;
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						text: e.message
					});
					
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
			async onDelete(){
				try {
					this.loading = 'deleting';
					if ( this.customerCurrent.customerEdit.accountId ) {
						throw Error('You need to disconnect the CloudCast before you can delete the customer');
					}
					
					if (! confirm('Are you sure?')) return;
					
					await this.$store.dispatch('deleteCustomer', this.customerCurrent.customerEdit );
					this.$router.push({ name: 'customers' });
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						// title: 'Error', 
						text: e.message || 'Problem deleting customer'
					});
					
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
		}
	}
</script>