<template>
	<div 
		id="pageTop"
		class="bg-blue-900 text-white flex justify-center">
		
		<nav class="py-8 container flex flex-col lg:flex-row items-center">
			<div class="flex-none flex px-4 lg:px-0 w-full lg:w-auto">
				<router-link 
					:to="{ name: 'dashboard'}"
					class="flex-grow flex items-center justify-start lg:justify-center ml-4 lg:ml-0  w-32">
					
					<img 
						class="mb-3"
						src="https://www.getcloudcast.com/logo.svg" 
					/>
				</router-link>
				
				<i 
					class="flex-none fas block w-12 h-12 flex justify-center items-center text-xl lg:hidden"
					:class="isOpen ? 'fa-times' : 'fa-bars'"
					@click="isOpen = !isOpen"
				/>
			</div>
			
			<div 
				class="flex-grow flex flex-col lg:flex-row items-center justify-center w-full py-12 lg:py-0"
				v-if="isOpen">
				
				<div class="flex-grow flex flex-col lg:flex-row lg:mx-8 lg:space-x-8 text-center">
					<router-link 
						:to="{ name: 'dashboard'}"
						class="link">
						
						Dashboard
					</router-link>
					
					<router-link 
						:to="{ name: 'customers'}"
						class="link">
						
						Customers
					</router-link>
					
					<router-link 
						:to="{ name: 'resources'}"
						class="link">
						
						Resources
					</router-link>
					
					<router-link 
						:to="{ name: 'support'}"
						class="link">
						
						Support
					</router-link>
					
					<router-link 
						:to="{ name: 'products'}"
						class="link">
						
						Services & Products
					</router-link>
					
					<router-link 
						:to="{ name: 'settings'}"
						class="link">
						
						Settings
					</router-link>
					
					<router-link 
						v-if="isSuperAdmin"
						:to="{ name: 'superadmin'}"
						class="bg-orange-400 text-blue-900 rounded px-4 p-2">
						
						SuperAdmin
					</router-link>
					
				</div>
				
				<div class="flex-none flex flex-col items-center lg:flex-row  lg:space-x-8 space-y-4 my-4 lg:space-y-0 lg:my-0 text-center">
					
					<div class="text-left leading-tight">
						{{ account.name }}
						<div class="opacity-50 text-left leading-tight">
							{{user.username}}<br>
						</div>
						
					</div>
					
					
					<a
						class="inline-block px-8 py-2 rounded-full text-white border border-white"
						@click="signOut()" >
					
						Sign out
					</a>
					
					
					
					<!-- <div class="flex justify-center">
						<img src="/css/globe.svg" class="mr-1"/>
						
						<select 
							class="bg-blue-900"
							v-model="language">
							
							<option 
								v-for="l in languages"
								:key="l.name"
								:value="l.code"
								:selected="language == l.code">
								
								{{l.name}}
							</option>
						</select>
					</div>
					
					<a href="https://admin.getcloudcast.com" target="_blank" class="text-yellow-500">
						{{$t('general.navigation.logIn')}}
					</a>
					
					<router-link 
						:to="{ name: 'getStarted'}"
						class="inline-block px-8 py-2 rounded-full text-white border border-white">
						
						{{$t('frontpage.getStarted')}}
					</router-link> -->
				</div>
			</div>
				
		</nav>
	</div>
</template>

<script>
	import AuthMixins from '@/mixins/AuthMixins';
	
	export default {
		mixins: [
			AuthMixins,
		],
		
		computed: {
			user(){
				return this.$store.getters.getUser;
			},
		},
		
		data () {
			return {
				isOpen: true,
			}
		},
		
		methods: {
			
		},
		
		created() {
			if (screen.width < 1024) {
				this.isOpen = false;
			}
		},
	}
</script>

<style lang="scss" scoped>
	.link {
		@apply border-b-2;
		@apply border-transparent;
		@apply py-2;
		transition: border .25s ease;
		color: #fff!important;
		
		&.router-link-active,
		&:hover {
			// @apply text-white;
			@apply border-b-2;
			@apply border-blue-800;
			// border-color: rgba(255,255,255, .25);
			// @apply border-white;
			@apply py-2;
			// @apply font-bold;
			
		}
	}
</style>
