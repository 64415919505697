<template>
    <div>
        <div>
            Billing.
            <pre>{{cloudCastAccount}}</pre>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'customerCurrent',
        ],
        computed: {
            cloudCastAccount(){
                return this.$store.getters.getCloudCastAccounts.find( cCA => cCA.accountId == this.customerCurrent.customerEdit.accountId );
            },
        },
        
        data() {
            return {
                loading: null,
            }
        },
        methods: {
            
        }
    }
</script>
