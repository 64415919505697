<template>
	<div class="container mx-auto">
		
		<div 
			v-if="visuals && visuals.length" 
			class="mb-8">
			
			<div class="flex">
				<header class="flex-grow text-black mb-8">
					Visuals
				</header>
				<div class="flex-none flex">
					<div 
						class="fas fa-redo-alt block flex items-center justify-center bg-white text-gray-400 w-10 h-10 rounded-lg cursor-pointer refresh"
						:class="{'loading': loading == 'refreshing'}"
						@click.prevent="refresh()"
					/>
					
					<button 
						@click.prevent="showVisualNew = true"
						class="bg-blue-500 text-white w-10 h-10 rounded-lg ml-4 ">
						
						<i class="fas fa-plus text-lg" />
					</button>
				</div>
			</div>
			
			<div class="rounded-lg overflow-hidden shadow-sm">
				<table>
					<thead>
						<tr>
							<th class="border-none">Visual Name</th>
							<th class="w-48">Created At</th>
							<th class="w-48">Updated At</th>
							<th class="w-12"></th>
							<th class="w-12"></th>
						</tr>
					</thead>
					<tbody>
						<tr 
							v-for="visual in visualsFiltered"
							:key="'visual_'+visual.visualId">
							
							<td>
								<router-link 
									:to="{ name: 'customer-visual', params: {visualId: visual.visualId} }"
									class="link">
									
									{{visual.name}}
								</router-link>
							</td>
							<td>{{visual.createdAt || visual.createdAt | moment('lll') }}</td>
							<td>{{visual.updatedAt || visual.updatedAt | moment('lll') }}</td>
							<td>
								<a 
									class="link"
									@click="onDelete( visual )">
								
									Delete
								</a>
							</td>
							<td>
								<router-link 
									:to="{ name: 'customer-visual', params: {visualId: visual.visualId} }"
									class="link">
									
									Edit
								</router-link>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			
		</div>
		
		<div 
			v-else
			class="flex w-full h-100 items-center justify-center">
			
			<div class="text-center">
				<h1 class="opacity-25">No visuals here yet.</h1>
				
				<button 
					type="submit"
					class="button submit"
					@click.prevent="showVisualNew = true">
					
					<i class="fas fa-plus text-lg mr-4" />
					Create new visual
				</button>
			</div>
		</div>
		
		
		
		<Modal 
			v-if="showVisualNew"
			@close="showVisualNew = false"
			insideClasses="bg-white">
			
			<header slot="header">
				<div class="py-4 px-6">
					Create Visual
				</div>
			</header>
			
			<form 
				@submit.prevent="onCreateVisual()"
				class="pb-2 px-6">
				
				<InputSingleline 
					v-model="visualNew.name"
					label="Name"
					name="name"
					:autofocus="true"
				/>
			</form>
			
			<footer slot="footer">
				<div class="buttons-wrapper">
					<a 
						class="button transparent link" 
						@click="showVisualNew = false">
						
						Cancel
					</a>
					
					<button 
						:disabled="loading"
						type="submit"
						class="button submit"
						:class="{'loading' : loading == 'creating' }"
						@click.prevent="onCreateVisual()">
						
						Create
					</button>
				</div>
			</footer>
		</Modal>
		
	</div>
</template>

<script>
	export default {
		computed: {
			visuals(){
				const visuals = this.$store.getters.getVisuals;
				return visuals.filter( v => v.customerId == this.customerId);
			},
			
			visualsFiltered(){
				if ( !this.visuals ){
					return null;
				}
				return this.visuals.filter( v => !v.deletedAt);
			},
		},
		
		data() {
			return {
                loading: null,
				showVisualNew: false,
				customerId: null,
				visualNew: {
					name: null,
					customerId: null,
					width: 1200,
					height: 700,
					background: {
						type: 'image',
						file: {
							url: 'https://partner.getcloudcast.com/screens/pexels-max-vakhtbovych-7534209.jpg',
						},
						size: 'cover',
						position: {
							x: 0,
							y: 0,
						}
					},
					screens: [],
				},
			}
		},
		
		methods: {
			async onCreateVisual(){
				try {
					if (!this.visualNew.name) {
						throw Error( 'The visual needs a name' )
					}
					
					this.loading = 'creating';
					this.visualNew.customerId = this.customerId;
					
					const visual = await this.$store.dispatch('createVisual', this.visualNew);
					this.visualNew.name = null;
					this.showVisualNew = false;
					this.$router.push({ name: 'customer-visual', params: {visualId: visual.visualId} });
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						// title: 'Error', 
						text: e.message || 'Problem creating visual'
					});
					
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
			async refresh(){
				try {
					this.loading = 'refreshing';
					await this.$store.dispatch('listVisuals');
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						// title: 'Error', 
						text: e.message || 'Problem refreshing visuals list'
					});
					
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
			async onDelete( visual ){
				if (! confirm('Are you sure?')) return;
				
				try {
					this.loading = 'deleting';
					await this.$store.dispatch('deleteVisual', visual);
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						// title: 'Error', 
						text: e.message || 'Problem deleting visual'
					});
					
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
		},
		
		async mounted(){
			this.customerId = this.$route.params.customerId;
			await this.refresh();
		}
	}
</script>

<style lang="scss" scoped>
	
</style>