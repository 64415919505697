<template>
    <div class="w-full h-full overflow-auto">
        <div class="max-w-lg mx-auto overflow-hidden rounded-lg my-12 ">
            <div class="bg-blue-900 text-white p-8">
                <div class="">
                    <img src="https://www.getcloudcast.com/logo.svg" class="w-32" />
                    <h1 class="text-white opacity-50 my-0 leading-none ml-3 text-4xl tracking-tight my-1">Partner Portal</h1>
                </div>
            </div>
            <div class="bg-white p-8">
                
                <form @submit.prevent="newPasswordSubmit()">
                    <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                        {{error}}
                    </div>
                    
                    <InputSingleline 
                        v-model="password"
                        label="Set new Password"
                        name="password"
                        class="w-full "
                        type="password"
                        :required="true"
                        :autofocus="true"
                    />
                    
                    <button 
                        type="submit" 
                        class="button submit mb-4 w-full" 
                        :class="{'loading' : loading}"
                        :disabled="loading">
                        
                        Set new password
                    </button>
                </form>
            </div>
        </div>
    </div>
    
    <!-- <div class="w-full h-full flex flex-col items-center md:justify-center bg-blue-900 overflow-auto">
        
        <form @submit.prevent="newPasswordSubmit()">
            <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                {{error}}
            </div>
            
            <InputSingleline 
                v-model="password"
                label="newPassword"
                name="password"
                placeholder="newPassword"
                class="w-full "
                type="password"
                :required="true"
            />
            
            <button 
                type="submit" 
                class="button submit mb-4 w-full" 
                :class="{'loading' : loading}"
                :disabled="loading">
                
                Set new password
            </button>
        </form>
        
    </div> -->
</template>

<script>
    import AuthMixins from '@/mixins/AuthMixins';
    
    export default {
        mixins: [
            AuthMixins,
        ],
        
        data() {
            return {
                error: null,
                password: null,
                loading: false,
                user: null,
            }
        },
        
        mounted(){
            if (!this.$route.params.user) {
                this.$router.push({name: 'signin'});
            }
            this.user = this.$route.params.user;    
        }
    }
</script>
