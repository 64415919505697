<template>
	<div class="py-8 container mx-auto">
		
		<div class="mb-8">
            <div class="flex">
                <header class="flex-grow text-black mb-8">
                    All CloudCast Accounts<br>
					<span v-if="loading && loading == 'refreshing'" class="opacity-75">Loading will take some time. Please wait...</span>
                </header>
				
                <div class="flex-none flex space-x-4 items-center">
					<div 
						v-if="allAccounts"
						class="flex-none">
						
						Showing 
						
						<span v-if="partnerSelected">
							{{ allAccountsFiltered.length }} of 
						</span>
						
						{{ allAccounts.length }} accounts
						
					</div>
					
					<select 
						v-if="allPartners"
						v-model="partnerSelected"
						class="border-none mb-0">
						
						<option :value="null">All accounts</option>
						<option value="missingPartner">Accounts without partner</option>
						<option 
							v-for="partner in allPartners"
							:key="'partnerId_'+partner.partnerId"
							:value="partner">
							
							{{ partner.name }}
						</option>
					</select>
					
                    <div 
                        class="flex-none fas fa-redo-alt flex items-center justify-center bg-white text-gray-400 w-12 h-12 rounded-lg cursor-pointer refresh"
                        :class="{'loading': loading == 'refreshing'}"
						@click.prevent="onFetchAccountsUsage()"
                    />
                </div>
            </div>
		</div>
		
		<div 
			v-if="allAccounts"
			class="rounded-lg overflow-hidden shadow-sm mt-8"
			:class="loading && loading == 'refreshing' ? 'opacity-50' : ''">
			
			<table>
				<thead>
					<tr>
						<th rowspan="2" class="border-none">Account Name</th>
						<th rowspan="2">Created At</th>
						<th rowspan="2">Partner</th>
						<th rowspan="2">Portals</th>
						<th rowspan="1" colspan="2">Users</th>
						<th rowspan="1" colspan="2">Screens</th>
					</tr>
					<tr class="bg-white">
						<th>Admin Users</th>
						<th>Portal Users</th>
						<th>Active / paired</th>
						<th>Inactive</th>
					</tr>
				</thead>
				<tbody>
					<template v-for="currentAccount in allAccountsFiltered">
						<tr 
							:key="'currentAccount_'+currentAccount.accountId"
							@click="accountSelected ? accountSelected = null : accountSelected = currentAccount">
							
							<td>
								{{currentAccount.name}}
							</td>
							<td>
								{{currentAccount.createdAt || currentAccount.createdAt | moment('lll') }}
							</td>
							<td>
								{{allPartners && allPartners.find( p => p.partnerId == currentAccount.partnerId )? allPartners.find( p => p.partnerId == currentAccount.partnerId ).name : currentAccount.partnerId}}
							</td>
							
							<!-- portals -->
							<td class="text-center">
								<div>{{currentAccount.usage.portals && currentAccount.usage.portals.Items.length ? currentAccount.usage.portals.Items.length : null}}</div>
							</td>
							
							<!-- users -->
							<td class="text-center">
								<div v-if="currentAccount.usage.users && currentAccount.usage.users.admins && currentAccount.usage.users.admins.value && currentAccount.usage.users.admins.value.Users">
									{{ currentAccount.usage.users.admins.value.Users.length ? currentAccount.usage.users.admins.value.Users.length : null }}
								</div>
							</td>
							<td class="text-center">
								<div v-if="currentAccount.usage.users && currentAccount.usage.users.users && currentAccount.usage.users.users.value && currentAccount.usage.users.users.value.Users">
									{{ currentAccount.usage.users.users.value.Users.length ? currentAccount.usage.users.users.value.Users.length : null }}
								</div>
							</td>
							
							<!-- screens -->
							<td class="text-center">
								<div v-if="currentAccount.usage.screens">
									{{ activeScreens( currentAccount.usage.screens ) }}
								</div>
							</td>
							<td class="text-center">
								<div v-if="currentAccount.usage.screens">
									{{ inactiveScreens( currentAccount.usage.screens ) }}
								</div>
							</td>
						</tr>
					</template>
				</tbody>
				<tfoot>
					<tr>
						<td>Total</td>
						<td></td>
						<td></td>
						<td class="text-center">{{sum.portals}}</td>
						<td class="text-center">{{sum.users.admins}}</td>
						<td class="text-center">{{sum.users.users}}</td>
						<td class="text-center">{{sum.screens.active}}</td>
						<td class="text-center">{{sum.screens.inactive}}</td>
					</tr>
				</tfoot>
			</table>
		</div>
		
		<Modal 
			v-if="accountSelected"
			@close="accountSelected = null"
			insideClasses="bg-white absolute inset-0 m-8 flex details"
			width="">
			
			<header slot="header">
				<div class="py-4 px-6">
					Account Details
				</div>
			</header>
			
			<AccountDetails 
				:accountSelected="accountSelected" 
				:activeScreens="accountSelected.usage.screens ? activeScreens( accountSelected.usage.screens ) : null"
				:inactiveScreens="accountSelected.usage.screens ? inactiveScreens( accountSelected.usage.screens ) : null"
			/> 
		</Modal>
	</div>
</template>

<script>
	export default {
		components: {
			'AccountDetails': () => import('@/components/superAdmin/AccountDetails.vue'),
		},
		
		computed: {
			allAccountsFiltered(){
				if (this.allAccounts){
					
					if ( this.partnerSelected && this.partnerSelected != 'missingPartner'){
						// selected partner
						return this.allAccounts.filter( a => a.partnerId == this.partnerSelected.partnerId);
					}
					else if ( this.partnerSelected && this.partnerSelected == 'missingPartner'){
						// missingPartner
						return this.allAccounts.filter( a => !a.partnerId );
					}
					else {
						//all
						return this.allAccounts;
					}
				}
				
				return null;
			},
			
			sum(){
				let response = {
					portals: 0,
					users: {
						admins: 0,
						users: 0,
					},
					screens: {
						active: 0,
						inactive: 0,
					},
				};
				
				
				this.allAccountsFiltered.forEach( a => {
					// portals
						try {
							response.portals += a.usage.portals.Items.length;
						} 
						catch (error) {
							// errors
						}
					
					// users
						try {
							// admins
							response.users.admins += a.usage.users.admins.value.Users.length;
							
							// users
							response.users.users += a.usage.users.users.value.Users.length;
								
						} 
						catch (error) {
							// errors
						}
						
					// screens
						try {
							response.screens.active += this.activeScreens( a.usage.screens );
							response.screens.inactive += this.inactiveScreens( a.usage.screens );
						} 
						catch (error) {
							// errors
						}
					
				})
				return response;
			},
		},
		
		data() {
			return {
				loading: null,
				allAccounts: null,
				accountSelected: null,
				allPartners: null,
				partnerSelected: null,
			}
		},
		
		methods: {
			async onFetchAccountsUsage() {
				try {
					this.loading = 'refreshing';
					
					const response = await this.$axios.post(process.env.VUE_APP_API_ENDPOINT + '/superadmin', { method: 'accountsUsage'});
					let allAccounts = response.data;
					localStorage.allAccounts = JSON.stringify( allAccounts );
					this.allAccounts = allAccounts.Items;
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						text: e.message || 'Problem fetching accounts'
					});
					
					console.error(e);
				}
				finally {
					this.loading = null;
				}
			},
			
			activeScreens( screens ) {
				if ( screens.Items ){
					let count = 0;
					screens.Items
						.filter( screen => !screen.deletedAt)
						.filter( screen => screen.deviceId || screen.status == 'active' )
						.forEach( screen => {
							count ++ ;
						});
					
					return count || null;
				}
				
				return null;
			},
			
			inactiveScreens( screens ) {
				if ( screens.Items ){
					let count = 0;
					
					screens.Items
						.filter( screen => !screen.deletedAt)
						.filter( screen => !screen.deviceId || screen.status == 'inactive' )
						.forEach( screen => {
							// console.log(screen);
							count ++ ;
						});
					
					return count || null;
				}
				
				return null;
			},
			
			async onFetchPartners(){
				const response = await this.$axios.post(process.env.VUE_APP_API_ENDPOINT + '/superadmin', { method: 'partners'});
				this.allPartners = response.data.Items;
			},
		},
		
		async mounted(){
			// if (localStorage.allAccounts) {
			// 	let allAccounts = JSON.parse( localStorage.allAccounts );
			// 	this.allAccounts = allAccounts.Items;
			// }
			// else {
			// 	this.onFetchAccountsUsage();
			// }
			this.onFetchAccountsUsage();
			
			this.onFetchPartners();
		}
	}
</script>