<template>
    <div
        class="h-full"
        v-if="ready">
        
        <h1>{{offer.title}}</h1>
        
        <div class="">
            Updated {{offer.updatedAt || offer.updatedAt | moment('lll') }}
        </div>
        
        <hr class="my-6">
        
        <div class="flex items-start">
            <button 
                class="button submit mr-4 flex-none"
                @click.prevent="showOfferWebpage = !showOfferWebpage">
                
                Edit Offer
            </button>
            
            <label class="flex-none w-32 text-right mr-2">Share URL</label>
            
            <div 
                class="relative mr-4 flex-grow w-full flex"
                @click.prevent="onShareUrlCopy">
                
                <input 
                    v-model="offerUrl"
                    type="text"
                    ref="shareUrl" 
                    readonly
                    class="w-full"
                />
                
                <i 
                    class="fas fa-copy top-0 right-0 mt-4 mr-4 absolute text-blue-500" 
                />
            </div>
            <a 
                class="button"
                :href="offerUrl" 
                target="_blank">
                
                <i class="fas fa-external-link-alt text-blue-500" />
            </a>
        </div>
        
        <div 
            v-if="offerEdit && offerId && !offerEdit.isDisabled"
            class="rounded-xl bg-black p-3 my-8">
            
            <iframe 
                :src="`/templates/offer/index.html?offerId=${offerId}`"
                class="w-full"
                style="height: 1000px;"
            />
        </div>
        <div 
            v-else
            class="text-center p-8">
            
            <h1 v-if="offerEdit.redirect">
                Offer is disabled and redirected to {{offerEdit.redirect}}
            </h1>
            <h1 v-else>
                Offer is disabled
            </h1>
        </div>
        
        
        
        <Modal 
            v-if="showOfferWebpage"
            @close="showOfferWebpage = false"
            insideClasses="bg-white absolute inset-0 m-8 flex "
            width="">
            
            <header slot="header">
                <div class="py-4 px-6">
                    Edit Offer
                </div>
            </header>
            
            <div 
                class="w-full h-full overflow-hidden flex"
                :class="offerEdit.isDisabled ? 'pointer-events-none opacity-25' : ''">
                
                <div class="flex-none w-100 overflow-y-auto px-6">
                    <div class="bg-gray-200 p-4">
                        <div class="">
                            <label class="">Page title</label>
                            <input
                                type="text" 
                                v-model="offerEdit.title" 
                                style="margin-bottom: 0;"
                            />
                        </div>
                        
                        <div class="mt-4">
                            <div class="flex mb-2">
                                <div class=" flex-grow">
                                    Email notifications
                                </div>
                                <div 
                                    @click="showNewEmail = !showNewEmail"
                                    class="link flex-none">
                                    Add
                                </div>
                            </div>
                            
                            <div 
                                v-if="showNewEmail"
                                class="relative  flex">
                                
                                <input
                                    type="email" 
                                    v-model="newEmail" 
                                    @keyup.enter="offerEdit.emails.push( newEmail ); newEmail = null;"
                                    class="flex-grow"
                                    placeholder="john@example.com"
                                />
                                
                                <button 
                                    v-if="newEmail && newEmail != ''"
                                    @click.prevent="offerEdit.emails.push( newEmail ); newEmail = null;"
                                    class="flex-none w-12 h-12">
                                    
                                    <i class="fas fa-plus text-lg text-blue-500" />
                                </button>
                            </div>
                            
                            
                            <div class="">
                                <div 
                                    v-for="e in offerEdit.emails"
                                    :key="e"
                                    class="flex my-2 rounded border bg-white px-2 py-1 mb-1">
                                    
                                    <div class="flex-grow">
                                        {{e}}
                                    </div>
                                    <div 
                                        @click="offerEdit.emails = offerEdit.emails.filter( email => email != e)"
                                        class="ml-2 link">
                                        Remove
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="flex">
                                <input
                                    type="email" 
                                    v-model="newEmail" 
                                    @keyup.enter="offerEdit.emails.push( newEmail ); newEmail = null;"
                                />
                            </div> -->
                        </div>
                    </div>
                    
                    <Draggable 
                        v-model="offerEdit.blocks"
                        handle=".handle">
                        
                        <div 
                            v-for="(block,i) in offerEdit.blocks"
                            :key="'block_'+i+'_'+block.type"
                            class="">
                            
                            <OfferBlockEdit 
                                :block="block"
                                @onAddBlock="onAddBlock($event)"
                                @remove="offerEdit.blocks = offerEdit.blocks.filter( b => b != block)"
                            />
                        </div>
                    </Draggable>
                    <div class="flex flex-col items-center">
                        
                        <button 
                            @click.prevent="showAddBlock = !showAddBlock"
                            class="bg-blue-500 text-white w-10 h-10 rounded-lg my-4">
                            
                            <i 
                                class="fas text-lg" 
                                :class="showAddBlock ? 'fa-minus' : 'fa-plus'"
                            />
                        </button>
                        
                        <div 
                            v-if="showAddBlock"
                            class="mt-4 border p-4">
                            <div class="grid grid-cols-2 gap-4">
                                <a 
                                    @click.prevent="onAddBlock('header');"
                                    class="p-2">
                                    Header
                                </a>
                                <a 
                                    @click.prevent="onAddBlock('mediaCarousel');"
                                    class="p-2">
                                    Media Carousel
                                </a>
                                <a 
                                    @click.prevent="onAddBlock('info');"
                                    class="p-2">
                                    Information
                                </a>
                                <a 
                                    @click.prevent="onAddBlock('sellerContacts');"
                                    class="p-2">
                                    Seller Contacts
                                </a>
                                <a 
                                    @click.prevent="onAddBlock('products');"
                                    class="p-2">
                                    Products
                                </a>
                                <a 
                                    @click.prevent="onAddBlock('orderForm');"
                                    class="p-2">
                                    Order Form
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-grow flex overflow-auto bg-gray-300">
                    <iframe 
                        v-if="offerEdit && offerId"
                        :src="`/templates/offer/index.html?offerId=${offerId}`"
                        ref="iframe"
                        style="width: 100%; height: 100%;"
                    />
                    <!-- &cb=${cacheBuster} -->
                </div>
            </div>
            
            <footer slot="footer">
                <div class="buttons-wrapper">
                    <div class="flex">
                        <div class="flex-grow text-left flex">
                            
                            <label class="flex-none w-48">
                                <input 
                                    type="checkbox" 
                                    v-model="offerEdit.isDisabled"
                                />
                                Disable / Redirect
                            </label>
                            
                            <input
                                v-if="offerEdit.isDisabled"
                                class="flex-grow"
                                type="text" 
                                v-model="offerEdit.redirect" 
                                placeholder="Redirect URL (optional)"
                                style="margin-bottom: 0;"
                            />
                            
                        </div>
                        <div class="">
                            <a 
                                v-if="isChangesMade && !loading"
                                class="button "
                                :disabled="loading"
                                @click.prevent="onCancelOffer()">
                                
                                Undo
                            </a>
                            
                            <button 
                                :disabled="loading"
                                type="submit"
                                class="button submit ml-4"
                                :class="[
                                    {'loading' : loading == 'updating'},
                                    {'opacity-25 pointer-events-none' : !isChangesMade}
                                ]"
                                @click.prevent="onUpdateOffer()">
                                
                                Update
                            </button>
                        </div>
                    </div>
                    
                    
                </div>
            </footer>
            
        </Modal>
    </div>
</template>

<script>
    import Draggable from 'vuedraggable';
    
    export default {
        components: {
            Draggable,
            'OfferBlockEdit': () => import('@/components/customer/OfferBlockEdit.vue'),
        },
        
        computed: {
            offers(){
                return this.$store.getters.getOffers;
            },
            
            offer(){
                if (!this.offers) {
                    return;
                }
                
                if (this.offerId) {
                    return this.offers.find( o => o.offerId == this.offerId);
                }
                
                return null;
            },
            
            objectToCheckIfChanged(){
                return JSON.stringify(this.offerEdit);
            },
        },
        
        watch: {
            objectToCheckIfChanged: function(newValue_str, oldValue_str) {
                if ( newValue_str != oldValue_str) {
                    this.isChangesMade = true;
                }
                
                if ( !JSON.parse(oldValue_str) || this.isReverted ) {
                    this.isChangesMade = false;
                    this.isReverted = false;
                }
            },
            offerEdit: {
                immediate: true,
                deep: true,
                handler(newValue_str, oldValue_str) {
                    if (newValue_str) {
                        if (this.refreshCounter) {
                            clearTimeout( this.refreshCounter );
                        }
                        
                        this.refreshCounter = setTimeout( () => {
                            const offerFormated = {
                                partnerId: newValue_str.partnerId,
                                offerId: newValue_str.offerId,
                                title: newValue_str.title,
                                updatedAt: newValue_str.updatedAt,
                                blocks: [
                                    ...newValue_str.blocks,
                                ],
                            };
                            
                            localStorage.setItem('offer_'+this.offerId, JSON.stringify(offerFormated) );
                        }, 250);
                    }
                }
            },
        },
        
        data() {
            return {
                offerId: null,
                offerEdit: null,
                newEmail: null,
                showNewEmail: false,
                ready: false,
                loading: null,
                showOfferWebpage: false,
                isReverted: false,
                isChangesMade: false,
                showAddBlock: false,
                offerUrl: null,
                refreshCounter: null,
            }
        },
        
        methods: {
            onCancelOffer(){
                this.createOfferCopy();
                this.isReverted = true;
                this.$notify({ type: 'success', text: 'Changes was reset' });
            },

            createOfferCopy(){
                this.offerEdit = JSON.parse(
                    JSON.stringify(
                        Object.assign(
                            {
                                blocks: [],
                                emails: [],
                                isDisabled: false,
                                redirect: null,
                            }, 
                            this.offer
                        )
                    )
                );
            },
            
            async onShareUrlCopy(){
                await navigator.clipboard.writeText( this.offerUrl );
                
                this.$notify({ type: 'success', text: 'Share URL copied to clipboard' });
            },
            
            async onUpdateOffer(){
                try {
                    this.loading = 'updating';
                    
                    const offerFieldsToUpdate = {
                        offerId: this.offerId,
                        title: this.offerEdit.title,
                        emails: this.offerEdit.emails,
                        blocks: this.offerEdit.blocks,
                        isDisabled: this.offerEdit.isDisabled,
                        redirect: this.offerEdit.redirect,
                    };
                    
                    let offerHtml;
                    
                    if ( !this.offerEdit.isDisabled ) {
                        offerHtml = await this.$axios.get('/templates/offer/index.html');
                        
                        if (offerHtml) {
                            offerFieldsToUpdate.offerHtml = offerHtml.data;
                        }
                    }
                    else {
                        if ( this.offerEdit.redirect ) {
                            offerFieldsToUpdate.offerHtml = `
                                <html>
                                  <head>
                                    <meta http-equiv="refresh" content="0;URL=${this.offerEdit.redirect}">
                                  </head>
                                </html>
                            `;
                        }
                        else {
                            offerFieldsToUpdate.offerHtml = `
                                <html>
                                  <body>This is page has been disabled</body>
                                </html>
                            `;
                        }
                    }
                    
                    await this.$store.dispatch('updateOffer', offerFieldsToUpdate);
                    
                    this.$notify({ type: 'success', text: 'Updated Offer' });
                } 
                catch (e) {
                    this.$notify({ 
                        type: 'error', 
                        text: e.message
                    });
                    
                    console.error(e);
                } 
                finally {
                    // this.offerEdit = null;
                    this.loading = null;
                    this.isChangesMade = false;
                }
            },
            
            onAddBlock( block ){
                switch (block) {
                    case 'header':
                        block = {
                            type: {
                                code: 'header',
                                name: 'Header',
                            },
                            customerLogo: null, //`https://res.cloudinary.com/obosit-prd-ch-clry/image/upload/q_auto/v1619689575/OBOS%20Merkevare/OBOS/Liggende/obos_liggende_hus_hvit.svg`
                            background: {
                                color: '#0d2d43',
                                image: null,
                                text: '#ffffff',
                            },
                            heading: `Signage made easy!`,
                            text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua and ut enim ad minim veniam.`,
                        };
                        this.offerEdit.blocks.push( block );
                        break;
                        
                    case 'mediaCarousel':
                        block = {
                            type: {
                                code: 'mediaCarousel',
                                name: 'Media Carousel',
                            },
                            // height: {
                            //     desktop: 300,
                            //     mobile: 200,
                            // },
                            files: [
                                // `https://partner.getcloudcast.com/files/pa_lhd.jkb/files/file_df8.aw4.jpg`,
                                // `https://a.getcloudcast.com/acc_g6f.4yg/content/media/file_r6q.8eb.mp4`,
                                // `https://partner.getcloudcast.com/files/pa_lhd.jkb/files/file_y1y.7jx.jpg`,
                            ]
                        };
                        this.offerEdit.blocks.push( block );
                        break;
                        
                    case 'info':
                        block = {
                            type: {
                                code: 'info',
                                name: 'Information',
                            },
                            media: null,
                            mediaPosition: 'left',
                            background: {
                                color: '#ffffff',
                                text: '#333333',
                            },
                            heading: `This is exactly what you need`,
                            text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
                        };
                        this.offerEdit.blocks.push( block );
                        break;
                        
                    case 'sellerContacts':
                        block = {
                            type: {
                                code: 'sellerContacts',
                                name: 'Seller Contacts',
                            },
                            background: {
                                color: '#f7f7f7',
                                text: '#333333',
                            },
                            heading: `Order directly to your store`,
                            text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.`,
                            contacts: [],
                        };
                        this.offerEdit.blocks.push( block );
                        break;
                        
                    case 'sellerContact':
                        // find sellerContactsBlock
                        const sellerContactsBlock = this.offerEdit.blocks.find( b => b.type.code == 'sellerContacts');
                        
                        if (!sellerContactsBlock) {
                            return;
                        }
                        
                        sellerContactsBlock.contacts.push({
                            name: 'John Doe',
                            title: 'Company X',
                            phone: '123 123 123',
                            email: 'example@company.com',
                            image: null,
                        });
                        break;    
                        
                    case 'products':
                        block = {
                            type: {
                                code: 'products',
                                name: 'Products',
                            },
                            heading: `Products`,
                            text: `All screens from CloudCast contains a licence to you the signage software and will be billed monthly or annually.`,
                            products: [],
                        };
                        this.offerEdit.blocks.push( block );
                        break;
                        
                    case 'product':
                        // find products block
                        const productsBlock = this.offerEdit.blocks.find( b => b.type.code == 'products');
                        
                        if (!productsBlock) {
                            return;
                        }
                        productsBlock.products.push({
                            type: 'product',
                            image: null,
                            name: `Screen 24” Touch`,
                            description: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
                            price: 6665.00,
                            unit: `per item`,
                            qty: 0,
                        });
                        break;
                    case 'orderForm':
                        block = {
                            type: {
                                code: 'orderForm',
                                name: 'Order Form',
                            },
                            heading: `Contact Details`,
                            text: ``,
                            fields: [
                                {
                                    name: 'First name',
                                    type: 'string',
                                    placeholder: null,
                                    required: true,
                                },
                                {
                                    name: 'Last name',
                                    type: 'string',
                                    placeholder: null,
                                    required: true,
                                },
                                {
                                    name: 'Phone number',
                                    type: 'string',
                                    placeholder: null,
                                    required: true,
                                },
                                {
                                    name: 'Email address',
                                    type: 'string',
                                    placeholder: null,
                                    required: true,
                                },
                                {
                                    name: 'Message',
                                    type: 'textarea',
                                    placeholder: null,
                                    required: false,
                                },
                                {
                                    name: 'I agree to the terms and conditions',
                                    type: 'checkbox',
                                    placeholder: null,
                                    required: true,
                                }
                            ],
                            confirmation: `Thank you for your order. We will get back to you as soon as possible. `,
                            termsAndConditions: `Quasi lumen aliquod probitatis et virtutis perspicere videamur. quam ob rem vita quidem talis fuit vel fortuna vel gloria, ut nihil posset accedere, moriendi autem sensum celeritas abstulit; quo de genere mortis difficile dictu est; quid homines suspicentur, videtis; hoc vere tamen licet dicere, p. scipioni ex multis diebus.`,
                            button: `Submit Now`,
                        };
                        
                        this.offerEdit.blocks.push( block );
                        break;
                        
                    case 'orderFormField':
                        // find orderFormBlock
                        const orderFormBlock = this.offerEdit.blocks.find( b => b.type.code == 'orderForm');
                        
                        if (!orderFormBlock) {
                            return;
                        }
                        
                        orderFormBlock.fields.push({
                            name: 'Form Field',
                            type: 'string',
                            placeholder: null,
                            required: false,
                        });
                        break;
                }
                
                this.showAddBlock = false;
                
            },
        },
        
        async mounted(){
            this.offerId = this.$route.params.offerId;
            // await this.$store.dispatch('getOffer', this.offerId);
            this.createOfferCopy();
            this.ready = true;
            
            this.offerUrl = this.offerToUrl( this.offerId );
        }
    }
</script>