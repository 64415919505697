<template>
    <div>
        <div class="py-8 container mx-auto flex">
            <div class="w-40 flex-none leading-loose">
                <ResourcesNavSidebar />
            </div>
            <div class="flex-grow">
                <section>
                    <header class="text-black mb-4">
                        Presentations
                    </header>
                    
                    <div class="py-8 container mx-auto grid grid-cols-2 gap-8">
                        <div class="bg-white rounded-lg p-8">
                            <h2 class="text-black mb-2">Salgsbrosjyre (NO)</h2>
                            
                            <p>
                                Primarily for large retail businesses. This presentation show slides to convince the client
                                of why digital signage is a good choice, the CloudCast products and what role they play, and 
                                examples of use. Also a brief presentation of the technical architecture. It's currently only in Norwegian language. 
                            </p>
                            <a 
                                class="inline-block px-8 py-2 rounded-full bg-blue-500 text-white mt-4"
                                href="https://support.getcloudcast.com/files/CloudCast-Salgsbrosjyre_NO.pdf"
                                download
                                target="_blank">
                                
                                Download
                            </a>
                        </div>
                        
                        <div class="bg-white rounded-lg p-8">
                            <h2 class="text-black mb-2">Bakgrunn. Teamet. Partnere. (NO)</h2>
                            
                            <p>
                                What is CloudCast, and what is it not. Some history of the concept and the background of our team members. 
                                A short presentation of the core areas of CloudCast and some examples of partners. 
                            </p>
                            <a 
                                class="inline-block px-8 py-2 rounded-full bg-blue-500 text-white mt-4"
                                href="https://support.getcloudcast.com/files/CloudCast-Bakgrunn.Teamet.Partnere_NO.pdf"
                                download
                                target="_blank">
                                
                                Download
                            </a>
                        </div>
                        
                    </div>
                </section>
                
            </div>
        </div>
        
    </div>
    
</template>

<script>
    export default {
        components: {
            'ResourcesNavSidebar': () => import('@/components/resources/ResourcesNavSidebar.vue'),
        },
        
        computed: {
            
        },
        
        data() {
            return {
                section: null,
                publicPath: process.env.BASE_URL,
            }
        },
        
        methods: {
            
        },
        
    }
</script>