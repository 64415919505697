<template>
    <div class="">
		<!-- <Explain
			heading="What are customer contacts?"
			text="These contacts are here for your convenience as a partner, and will not be visible for your customer."
		/> -->
		
		<label>Contacts</label>
		
        <div class="rounded-lg overflow-hidden shadow-sm">
            <table>
                <thead>
                    <tr>
						<th class="w-12"></th>
                        <th>Firstname</th>
                        <th>Lastname</th>
                        <th>Job Title</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th class="w-10"></th>
                    </tr>
                </thead>
                
				<Draggable 
					v-model="contactsEdit"
					handle=".handle"
					tag="tbody">
					
                    <tr 
                        v-for="(contact,i) in contactsEdit"
                        :key="'contact_'+i">
                        
                        <td>
                            <div
								class="handle text-3xl mr-4 cursor-move"
								v-html="'='"
							/>
                        </td>
                        <td>
                            <input type="text" v-model="contact.firstname" />
                        </td>
                        <td>
                            <input type="text" v-model="contact.lastname" />
                        </td>
                        <td>
                            <input type="text" v-model="contact.jobTitle" />
                        </td>
                        <td>
                            <input type="text" v-model="contact.phone" />
                        </td>
                        <td>
                            <input type="email" v-model="contact.email" />
                        </td>
                        <td>
                            <i 
                                class="fas fa-trash text-lg link block text-center mb-4" 
                                @click.prevent="onDeleteContact( contact )"
							/>
                        </td>
                    </tr>
				</Draggable>
				<tbody>
					<tr>
						<td>
							
						</td>
						<td>
							<input 
								type="text"
								v-model="contactNew.firstname" 
								placeholder="Firstname"
							/>
						</td>
						<td>
							<input 
								type="text"
								v-model="contactNew.lastname" 
								placeholder="Lastname"
							/>
						</td>
						<td>
							<input 
								type="text"
								v-model="contactNew.jobTitle" 
								placeholder="Job Title"
							/>
						</td>
						<td>
							<input 
								type="text"
								v-model="contactNew.phone" 
								placeholder="Phone"
							/>
						</td>
						<td>
							<input 
								type="email"
								v-model="contactNew.email" 
								placeholder="Email"
							/>
						</td>
						<td>
							<i 
								class="fas fa-plus flex items-center justify-center w-6 h-6 bg-blue-500 text-white rounded-full mb-4" 
								@click.prevent="onAddContact()"
							/>
						</td>
					</tr>
				</tbody>
			</table>
        </div>
    </div>
</template>

<script>
    import Draggable from 'vuedraggable';
	
    export default {
		components: {
			Draggable,
		},
        props: [
			'contacts',
			'reset',
        ],
        
        data() {
            return {
                customerId: null,
                loading: null,
                contactNew: {
					contactId: null,
                    firstname: null,
                    lastname: null,
					jobTitle: null,
                    phone: null,
                    email: null,
                },
                contactsEdit: null,
            }
        },
		
		watch: {
			reset(newVal, oldVal) {
				if (newVal != oldVal){
					this.contactsEdit = this.contacts;
				}
			},
		},
		
        methods: {
            onAddContact(){
                try {
                    if (!this.contactNew.firstname || !this.contactNew.lastname) {
                        throw Error( 'The contact needs a firstname and lastname' );
                    }
                    
                    this.contactNew.contactId = 'con_'+Math.round(Math.random()*100000);
                    this.contactsEdit.push( this.contactNew );
                    
                    this.contactNew = {
						contactId: null,
                        firstname: null,
                        lastname: null,
						jobTitle: null,
                        phone: null,
                        email: null,
                    };
                } 
                catch (e) {
                    this.$notify({ 
                        type: 'error', 
                        text: e.message
                    });
                    
                    console.error(e);
                }
            },
            
            onDeleteContact( contact ){
                try {
					console.log('contact', contact);
					
					this.contactsEdit = this.contactsEdit.filter( c => c != contact);
					this.$emit('delete', contact );
                } 
                catch (e) {
                    this.$notify({ 
                        type: 'error', 
                        text: e.message
                    });
                    
                    console.error(e);
                }
            },
        },
		
		mounted(){
			this.contactsEdit = this.contacts;
		}
    }
</script>