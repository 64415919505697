import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)
import moment from 'moment'

import AccountMixins from './mixins/AccountMixins';

export default new Vuex.Store({
	state: {
		user: null,
		account: null,
		cloudCastProducts: [],
		cloudCastAccounts: [],
		customers: [],
		offers: [],
		visuals: [],
		timelineEvents: [],
		masterProducts: [],
		products: [],
		tickets: [],
		files: [],
	},
	
	getters: {
		getUser: state => {
			return state.user;
		},
		
		getAccount: state => {
			return state.account;
		},
		
		getCloudCastProducts: state => {
			return state.cloudCastProducts;
		},
		
		getCloudCastAccounts: state => {
			return state.cloudCastAccounts
				.sort( (a, b) => a.name - b.name);
		},
		
		getCustomers: state => {
			return state.customers
				.sort( (a, b) => a.createdAt - b.createdAt)
				.reverse();
		},
		
		getOffers: state => {
			return state.offers
				.sort( (a, b) => a.createdAt - b.createdAt)
				.reverse();
		},
		
		getVisuals: state => {
			return state.visuals
				.sort( (a, b) => a.createdAt - b.createdAt)
				.reverse();
		},
		
		getMasterProducts: state => {
			return state.masterProducts;
		},
		
		getProducts: state => {
			return state.products;
		},
		
		getTimelineEvents: state => {
			return state.timelineEvents
				.sort( (a, b) => a.createdAt - b.createdAt)
				.reverse();
		},
		
		getTickets: state => {
			return state.tickets
				.sort( (a, b) => a.createdAt - b.createdAt)
				.reverse();
		},
		
		getFiles: state => {
			return state.files;
		},
	},
	
	mutations: {
		setUser(state, user){
			state.user = user;
		},
		
		setAccount(state, account){
			state.account = account;
		},
		
		setCloudCastProducts(state, cloudCastProducts){
			state.cloudCastProducts = cloudCastProducts;
		},
		
		setCloudCastAccounts(state, cloudCastAccounts){
			state.cloudCastAccounts = cloudCastAccounts;
		},
		
		addCloudCastAccount(state, cloudCastAccount){
			state.cloudCastAccounts.push( cloudCastAccount );
		},
		
		setCustomers(state, customers){
			state.customers = customers;
		},
		
		setCustomer(state, customer){
			state.customers = state.customers.map(c => {
				if (c.customerId == customer.customerId) {
					c = customer
				}
				return c;
			});
		},
		
		createCustomer(state, customer){
			state.customers.unshift( customer );
		},
		
		updateCustomer(state, updatedCustomer){
			state.customers = state.customers.map(c => {
				if (c.customerId == updatedCustomer.customerId) {
					c = updatedCustomer
				}
				return c;
			});
		},
		
		setOffers(state, offers){
			state.offers = offers;
		},
		
		createOffer(state, offer){
			state.offers.unshift( offer );
		},
		
		setOffer(state, offer){
			state.offers = state.offers.map(o => {
				if (o.offerId == offer.offerId) {
					o = offer
				}
				return o;
			});
		},
		
		
		
		
		
		setVisuals(state, visuals){
			state.visuals = visuals;
		},
		
		createVisual(state, visual){
			state.visuals.unshift( visual );
		},
		
		setVisual(state, visual){
			state.visuals = state.visuals.map(v => {
				if (v.visualId == visual.visualId) {
					v = visual
				}
				return v;
			});
		},
		
		
		
		
		
		
		
		
		
		
		
		
		
		setTimelineEvents(state, timelineEvents){
			state.timelineEvents = timelineEvents;
		},

		createTimelineEvent(state, timelineEvent){
			state.timelineEvents.push( timelineEvent );
		},

		updateTimelineEvent(state, updatedTimelineEvent){
			state.timelineEvents = state.timelineEvents.map(tE => {
				if (tE.timelineEventId == updatedTimelineEvent.timelineEventId) {
					tE = updatedTimelineEvent
				}
				return tE;
			});
		},
		
		
		
		
		
		setMasterProducts(state, products){
			state.masterProducts = products;
		},
		
		setProducts(state, products){
			state.products = products;
		},
		
		createProduct(state, product){
			state.products.push( product );
		},
		
		updateProduct(state, updatedProduct){
			state.products = state.products.map(item => {
				if (item.productId == updatedProduct.productId) {
					item = updatedProduct
				}
				return item;
			});
			
			// if ( state.products.find( item => item.productId == updatedProduct.productId ) ) {
			// 	state.products = state.products.map(item => {
			// 		if (item.productId == updatedProduct.productId) {
			// 			item = updatedProduct
			// 		}
			// 		return item;
			// 	});
			// }
			// else {
			// 	state.products.push( updatedProduct );
			// }
		},
		
		
		
		
		setTickets(state, tickets){
			state.tickets = tickets;
		},

		createTicket(state, ticket){
			state.tickets.unshift( ticket );
		},

		updateTicket(state, updatedTicket){
			state.tickets = state.tickets.map(c => {
				if (c.ticketId == updatedTicket.ticketId) {
					c = updatedTicket
				}
				return c;
			});
		},
		
		
		setFiles(state, files){
			state.files = files;
		},
		
		createFile(state, file){
			state.files.push( file );
		},
	},
	
	actions: {
		// async getAccount(context, account){
		//     const responseGet = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/accounts/'+context.state.account.partnerId);
		//     if (!responseGet.data || !responseGet.data.account) {
		//         throw Error('Could not fetch new account information');
		//     }
		//     const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/accounts/'+context.state.account.partnerId);
		//     // console.log('getCustomer', response);
		//     if (response.data) {
		//         await context.dispatch('setAccount', response.data);
		//     }
		// },
		
		
		// PARTNER ACCOUNT
		async setAccount(context, account){
			if (!account){
				return;
			}
			
			const accountAllResponse = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/accounts/'+account.partnerId);
			if (!accountAllResponse.data || !accountAllResponse.data.account) {
				throw Error('Could not fetch partner account information');
			}
			
			this.commit('setAccount', accountAllResponse.data.account);
			this.commit('setCloudCastProducts', accountAllResponse.data.products);
			
			// console.log('accountAllResponse.data.products');
			// console.log(accountAllResponse.data.products);
			
			// console.log('Account was dispatched', account);
			
			const accountPromises = [
				context.dispatch('listCloudCastAccounts'),
				context.dispatch('listCustomers'),
				// context.dispatch('listOffers'),
				// context.dispatch('listVisuals'),
				// context.dispatch('listTimelineEvents'),
				// context.dispatch('listTickets'),
				// context.dispatch('listFiles', {path: 'files'}),
			];
			
			const promisesResult = await Promise.allSettled( accountPromises );
			
			if ( promisesResult.find(p => p.status != 'fulfilled') ) {
				alert( promisesResult.find(p => p.status != 'fulfilled').reason.message || 'Error loading account. See console log for details.' );
			}
			
			return promisesResult;
		},
		
		async updateAccount(context, account){
			const responsePut = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/accounts/'+context.state.account.partnerId, account);
			// TODO
			const accountAllResponse = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/accounts/'+context.state.account.partnerId);
			if (!accountAllResponse.data || !accountAllResponse.data.account) {
				throw Error('Could not fetch new account information');
			}
			this.commit('setAccount', accountAllResponse.data.account);
		},
		
		async listCloudCastAccounts(context){
			const data = {
				method: 'list',
			};
			
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/accounts/'+context.state.account.partnerId+'/cloudcastaccounts', data);
			
			if (response.data) {
				// console.log(response.data);
				this.commit('setCloudCastAccounts', response.data.Items);
			}
		},
		
		async createCloudCastAccount(context, cloudCastAccount ){
			
			const data = {
				method: 'create',
				cloudCastAccount,
			};
			
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/accounts/'+context.state.account.partnerId+'/cloudcastaccounts', data);
			
			if (response.data) {
				console.log(response.data);
				this.commit('addCloudCastAccount', response.data);
				return response.data;
			}
			
			/* const data = {
				method: 'create',
				cloudCastAccount,
			};
			
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/accounts/'+context.state.account.partnerId+'/cloudcastaccounts', data);
			
			if (response.data) {
				console.log(response.data);
				this.commit('addCloudCastAccount', response.data);
				return response.data;
			} */
		},
		
		
		// CUSTOMERS
		async listCustomers(context){
			let response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/customers');
			
			if (response.data) {
				response.data.Items = response.data.Items.filter( c => !c.deletedAt);
				
				this.commit('setCustomers', response.data.Items);
			}
		},
		
		async getCustomer(context, customerId){
			const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/customers/'+customerId);
			
			if (response.data) {
				this.commit('setCustomer', response.data);
			}
		},
		
		async createCustomer(context, customer){
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/customers/', customer);
			this.commit('createCustomer', response.data);
			return response.data;
		},
		
		async updateCustomer(context, customer){
			console.log('customer', customer);
			
			// if (!customer.accountId) {
			// 	delete customer.accountId;
			// }
			
			const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/customers/' + customer.customerId, customer);
			this.commit('updateCustomer', response.data);
		},
		
		async deleteCustomer(context, customer){
			const response = await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/customers/' + customer.customerId);
			
			if (response.data && response.data.Attributes) {
				const updatedCustomer = Object.assign(
					customer,
					response.data.Attributes
				);
				
				this.commit('updateCustomer', updatedCustomer);
			}
		},
		
		
		
		// OFFERS
		async listOffers(context){
			const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/offers');
			
			if (response.data) {
				this.commit('setOffers', response.data.Items);
			}
		},
		
		async getOffer(context, offerId){
			const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/offers/'+offerId);
			console.log('getOffer', response);
			
			if (response.data) {
				this.commit('setOffer', response.data);
			}
		},
		
		async createOffer(context, offer){
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/offers/', offer);
			this.commit('createOffer', response.data);
			return response.data;
		},
		
		async updateOffer(context, offer){
			// console.log('offer', offer);
			const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/offers/' + offer.offerId, offer);
			this.commit('setOffer', response.data);
		},
		
		async deleteOffer(context, offer){
			const response = await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/offers/' + offer.offerId);
			
			if (response.data && response.data.Attributes) {
				const updatedOffer = Object.assign(
					offer,
					response.data.Attributes
				);
				
				this.commit('setOffer', updatedOffer);
			}
		},
		
		
		
		
		// VISUALS
		async listVisuals(context){
			const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/visuals');
			
			if (response.data) {
				this.commit('setVisuals', response.data.Items);
			}
		},

		async getVisual(context, visualId){
			const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/visuals/'+visualId);
			// console.log('getVisual', response);
			
			if (response.data) {
				this.commit('setVisual', response.data);
				return response.data;
			}
		},

		async createVisual(context, visual){
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/visuals/', visual);
			this.commit('createVisual', response.data);
			return response.data;
		},
		
		async updateVisual(context, visual){
			console.log('visual', visual);
			const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/visuals/' + visual.visualId, visual);
			this.commit('setVisual', response.data);
		},
		
		async deleteVisual(context, visual){
			const response = await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/visuals/' + visual.visualId);
			
			if (response.data && response.data.Attributes) {
				const updatedVisual = Object.assign(
					visual,
					response.data.Attributes
				);
				
				this.commit('setVisual', updatedVisual);
			}
		},
		
		async exportVisual(context, visual){
			console.log('visual', visual);
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/images', visual);
			return response.data;
			
			// const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/visuals/' + visual.visualId, visual);
			// this.commit('setVisual', response.data);
		},
		

		// TIMELINE EVENTS
		async listTimelineEvents(context){
			const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/timelineEvents');
			
			if (response.data) {
				this.commit('setTimelineEvents', response.data.Items);
			}
		},
		
		async getTimelineEvent(context, timelineEventId){
			const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/timelineEvents/'+timelineEventId);
			// console.log('getTimelineEvent', response);
			
			if (response.data) {
				this.commit('setTimelineEvent', response.data);
			}
		},
		
		async createTimelineEvent(context, timelineEvent){
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/timelineEvents/', timelineEvent);
			this.commit('createTimelineEvent', response.data);
			return response.data;
		},
		
		async updateTimelineEvent(context, timelineEvent){
			console.log('timelineEvent', timelineEvent);
			const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/timelineEvents/' + timelineEvent.timelineEventId, timelineEvent);
			this.commit('updateTimelineEvent', response.data);
		},
		
		async deleteTimelineEvent(context, timelineEvent){
			const response = await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/timelineEvents/' + timelineEvent.timelineEventId);
			
			if (response.data && response.data.Attributes) {
				const updatedTimelineEvent = Object.assign(
					timelineEvent,
					response.data.Attributes
				);
				
				this.commit('updateTimelineEvent', updatedTimelineEvent);
			}
		},
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		// PRODUCTS
		async listProducts(context){
			const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/products');
			
			if (response.data) {
				// console.log(response.data);
				this.commit('setMasterProducts', response.data.master);
				this.commit('setProducts', response.data.partner);
			}
		},
		
		// async getProduct(context, productId){
		//     const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/products/'+productId);
		//     // console.log('getProduct', response);
		//     if (response.data) {
		//         this.commit('setProduct', response.data);
		//     }
		// },
		
		async createProduct(context, product){
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/products/', product);
			this.commit('createProduct', response.data);
			return response.data;
		},
		
		async updateProduct(context, product){
			console.log('product', product);
			const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/products/' + product.productId, product);
			this.commit('updateProduct', response.data);
		},
		
		async deleteProduct(context, product){
			const response = await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/products/' + product.productId);
			await context.dispatch('listProducts');
			
			// if (response.data && response.data.Attributes) {
			// 	const updatedProduct = Object.assign(
			// 		product,
			// 		response.data.Attributes
			// 	);
				
			// 	this.commit('updateProduct', updatedProduct);
			// }
		},
		
		
		
		
		
		
		
		
		
		
		// TICKETS
		async listTickets(context){
			const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/tickets');
			
			if (response.data) {
				this.commit('setTickets', response.data.Items);
			}
		},
		
		async getTicket(context, ticketId){
			const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/tickets/'+ticketId);
		},
		
		async createTicket(context, ticket){
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/tickets/', ticket);
			this.commit('createTicket', response.data);
			return response.data;
		},

		async updateTicket(context, ticket){
			const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/tickets/' + ticket.ticketId, ticket);
			this.commit('updateTicket', response.data);
		},

		async deleteTicket(context, ticket){
			const response = await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/tickets/' + ticket.ticketId);
			
			if (response.data && response.data.Attributes) {
				const updatedTicket = Object.assign(
					ticket,
					response.data.Attributes
				);
				
				this.commit('updateTicket', updatedTicket);
			}
		},
		
		
		// FILES
		async listFiles(context, {folderId, path}){
			// console.log('folderId', folderId);
			// console.log('path', path);
			const params = {folderId, path};
			
			const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/files', {params} );
			
			if (response.data) {
				this.commit('setFiles', response.data.Items);
			}
			// 
			// if (response.data) {
			//     return response.data.Items
			//         .sort((a, b) => a.updatedAt - b.updatedAt)
			//         .reverse();
			// }
		},
		
		async uploadFile(context, file){
			// console.log('uploadFile file', file);
			// returns a presigned Url from AWS to handle directly with PUT
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/files/', file);
			this.commit('createFile', response.data.file);
			return response.data;
		},
		
		async deleteFile(context, file){
			const response = await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.partnerId+'/files/' + file.fileId);
			return response.data;
		},
	}
})