<template>
    <div class="container mx-auto">
        
        <div 
            v-if="offers && offers.length" 
            class="mb-8">
            
            <div class="flex">
                <header class="flex-grow text-black mb-8">
                    Offers
                </header>
                <div class="flex-none flex">
                    <div 
                        class="fas fa-redo-alt block flex items-center justify-center bg-white text-gray-400 w-10 h-10 rounded-lg cursor-pointer refresh"
                        :class="{'loading': loading == 'refreshing'}"
                        @click.prevent="refresh()"
                    />
                    
                    <button 
                        @click.prevent="showOfferNew = true"
                        class="bg-blue-500 text-white w-10 h-10 rounded-lg ml-4 ">
                        
                        <i class="fas fa-plus text-lg" />
                    </button>
                </div>
            </div>
            
            <div class="rounded-lg overflow-hidden shadow-sm">
                <table>
                    <thead>
                        <tr>
                            <th class="border-none">Offer Name</th>
                            <th>Created At</th>
                            <th>Status</th>
                            <th class="w-12"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr 
                            v-for="offer in offers"
                            :key="'offer_'+offer.offerId">
                            
                            <td>
                                <router-link 
                                    :to="{ name: 'customer-offer', params: {offerId: offer.offerId} }"
                                    class="link">
                                    
                                    {{offer.title}}
                                </router-link>
                            </td>
                            <td>{{offer.createdAt || offer.createdAt | moment('lll') }}</td>
                            <td>{{offer.isDisabled ? 'Disabled' : 'Active' }}</td>
                            <td>
                                <router-link 
                                    :to="{ name: 'customer-offer', params: {offerId: offer.offerId} }"
                                    class="link">
                                    
                                    Manage
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
        
        <div 
            v-else
            class="flex w-full h-100 items-center justify-center">
            
            <div class="text-center">
                <h1 class="opacity-25">No offers here yet.</h1>
                
                <button 
                    type="submit"
                    class="button submit"
                    @click.prevent="showOfferNew = true">
                    
                    <i class="fas fa-plus text-lg mr-4" />
                    Create new offer
                </button>
            </div>
        </div>
        
        
        
        <Modal 
            v-if="showOfferNew"
            @close="showOfferNew = false"
            insideClasses="bg-white">
            
            <header slot="header">
                <div class="py-4 px-6">
                    Create Offer
                </div>
            </header>
            
            <form 
                @submit.prevent="onCreateOffer()"
                class="pb-2 px-6">
                
                <InputSingleline 
                    v-model="offerNew.title"
                    label="Title"
                    name="title"
                    :autofocus="true"
                />
            </form>
            
            <footer slot="footer">
                <div class="buttons-wrapper">
                    <a 
                        class="button transparent link" 
                        @click="showOfferNew = false">
                        
                        Cancel
                    </a>
                    
                    <button 
                        :disabled="loading"
                        type="submit"
                        class="button submit"
                        :class="{'loading' : loading == 'creating' }"
                        @click.prevent="onCreateOffer()">
                        
                        Create
                    </button>
                </div>
            </footer>
        </Modal>
        
    </div>
</template>

<script>
    export default {
        computed: {
            offers(){
                const offers = this.$store.getters.getOffers;
                return offers.filter( o => o.customerId == this.customerId);
            },
        },
        
        data() {
            return {
                showOfferNew: false,
                customerId: null,
                offerNew: {
                    title: null,
                    blocks: [],
                    customerId: null,
                },
                loading: null,
            }
        },
        
        methods: {
            async onCreateOffer(){
                try {
                    if (!this.offerNew.title) {
                        throw Error( 'The offer needs a title' )
                    }
                    
                    this.loading = 'creating';
                    this.offerNew.customerId = this.customerId;
                    
                    const offer = await this.$store.dispatch('createOffer', this.offerNew);
                    this.offerNew.title = null;
                    this.showOfferNew = false;
                    this.$router.push({ name: 'customer-offer', params: {offerId: offer.offerId} });
                } 
                catch (e) {
                    this.$notify({ 
                        type: 'error', 
                        // title: 'Error', 
                        text: e.message || 'Problem creating offer'
                    });
                    
                    console.error(e);
                } 
                finally {
                    this.loading = null;
                }
            },
            
            async refresh(){
                try {
                    this.loading = 'refreshing';
                    await this.$store.dispatch('listOffers');
                } 
                catch (e) {
                    this.$notify({ 
                        type: 'error', 
                        // title: 'Error', 
                        text: e.message || 'Problem refreshing offers list'
                    });
                    
                    console.error(e);
                } 
                finally {
                    this.loading = null;
                }
            },
        },
        
        async mounted(){
            this.customerId = this.$route.params.customerId;
			await this.refresh();
        }
    }
</script>

<style lang="scss" scoped>
    
</style>