<template>
	<div v-if="ready">
		
		<div class="bg-white">
			<div class="py-8 container mx-auto flex items-center">
				<h2 class="flex-grow flex items-center">
					<router-link :to="{ name: 'customers'}">
						Customers
						
					</router-link>
					
					<div class="text-3xl mx-4 leading-none -mt-1">›</div>
					
					{{customer.name}}
				</h2>
			</div>
		</div>
		
		<div class="py-8 container mx-auto flex">
			<div class="w-40 flex-none leading-loose">
				<CustomerNavSidebar />
			</div>
			<div class="flex-grow">
				<component 
					:is="section" 
					:customerCurrent="this"
					@onCancelCustomer="onCancelCustomer"
				/>
			</div>
		</div>
		
	</div>
	
</template>

<script>
	export default {
		components: {
			'CustomerNavSidebar': () => import('@/components/customer/CustomerNavSidebar.vue'),
			'CustomerDetails': () => import('@/components/customer/Details.vue'),
			'CustomerContacts': () => import('@/components/customer/CustomerContacts.vue'),
			'CustomerPartnerContacts': () => import('@/components/customer/PartnerContacts.vue'),
			'CustomerLicenses': () => import('@/components/customer/Licenses.vue'),
			'CustomerBilling': () => import('@/components/customer/Billing.vue'),
			'CustomerTimeline': () => import('@/components/customer/Timeline.vue'),
			'CustomerProducts': () => import('@/components/customer/Products.vue'),
			'CustomerVisuals': () => import('@/components/customer/Visuals.vue'),
			'CustomerVisual': () => import('@/components/customer/Visual.vue'),
			'CustomerOffers': () => import('@/components/customer/Offers.vue'),
			'CustomerOffer': () => import('@/components/customer/Offer.vue'),
		},
		
		computed: {
			customers(){
				const customers = this.$store.getters.getCustomers;
				return customers;
			},
			
			customer(){
				const customers = this.customers;
				
				if (!customers) {
					return;
				}
				
				if (this.customerId) {
					return customers.find( c => c.customerId == this.customerId);
				}
			
				return null;
			},
			
			objectToCheckIfChanged(){
				return JSON.stringify(this.customerEdit);
			},
		},
		
		watch: {
			'$route' (to, from) {
				this.section = to.name;
			},
			
			objectToCheckIfChanged: function(newValue_str, oldValue_str) {
				if ( newValue_str != oldValue_str) {
					this.isChangesMade = true;
				}
				
				if ( !JSON.parse(oldValue_str) || this.isReverted ) {
					this.isChangesMade = false;
					this.isReverted = false;
				}
			},
		},
		
		data() {
			return {
				customerId: null,
				customerEdit: null,
				section: null,
				ready: false,
				
				customerDefault: {
					name: null,
					contacts: [],
					partnerContacts: [],
					partnerProducts: [],
					licenses: {},
					status: null,
					accountId: null,
				},
				
				isReverted: false,
				isChangesMade: false,
				resetTimestamp: null,
			}
		},
		
		methods: {
			onCancelCustomer(){
				this.createCustomerCopy();
				this.isReverted = true;
				this.resetTimestamp = new Date().getTime();
				this.$notify({ type: 'success', text: 'Changes was reset' });
			},
			
			createCustomerCopy(){
				this.customerEdit = JSON.parse(
					JSON.stringify(
						Object.assign(
							{}, 
							this.customerDefault,
							this.customer
						)
					)
				);
			},
		},
		
		async mounted(){
			this.customerId = this.$route.params.customerId;
			await this.$store.dispatch('getCustomer', this.customerId);
			this.section = this.$route.name;
			this.createCustomerCopy();
			
			this.ready = true;
		}
	}
</script>