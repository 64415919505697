<template>
    <div class="mb-8">
        <fieldset>
            <legend v-if="legend">
                {{legend}}
                
                <span 
                    v-if="required"
                    class="required">
                    *
                </span>
            </legend>
                
            <div 
                class="h-40 bg-white border border-dashed border-gray-400  rounded-lg hover:text-gray-700 relative" 
                :class="[
                    {'bg-blue-400 text-white': dragoverActive},
                ]"
                @dragover="dragover" 
                @dragleave="dragleave" 
                @drop="drop">
                
                <div class="absolute inset-0 cursor-pointer w-full flex flex-col items-center justify-center pointer-events-none">
                    <i 
                        class="fas fa-cloud-upload-alt text-4xl " 
                        :class="dragoverActive ? 'text-white' :'text-blue-400'"
                    />
                    <div>{{label || 'Upload File'}}</div>
                </div>
                
                <input 
                    ref="file" 
                    type="file" 
                    @input="onFilesDrop" 
                    :multiple="multiple"
                    id="assetsFieldHandle" 
                    name="fields[assetsFieldHandle][]" 
                    class="w-px h-px opacity-0 overflow-hidden absolute" 
                />
                
                <label 
                    for="assetsFieldHandle" 
                    class="absolute inset-0 cursor-pointer w-full flex flex-col items-center justify-center">
                    
                </label>
            </div>
        </fieldset>
        
        <div 
            v-if="items && items.length != 0"
            class="my-4 p-6 bg-gray-100 rounded-lg">
            
            <header class="mb-4 flex">
                <h3 class="text-black flex-grow m-0">Upload Progress</h3>
                
                <div 
                    class="close text-gray hover:text-black" 
                    @click="items = []">
                    
                    <i class="fas fa-times"></i>
                </div>
            </header>
            
            <div 
                v-for="item in items"
                :key="item.name+'-'+item.size+'_' + Math.random()"
                :title="item.name"
                class="py-2 flex items-center">
                
                <div class="flex-none w-48 pr-4">
                    <div class="truncate max-w-full opacity-50">
                        {{item.name}}
                    </div>
                </div>
                
                <div class="flex-grow relative -mt-2">
                    <div 
                        class="absolute bg-gray-200 h-2 rounded w-full">
                    </div>
                    <div 
                        class="absolute bg-blue-500 h-2 rounded"
                        :style="`width: ${item.completed}%;`">
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
        },
        path: {
            // NB: No prefix or suffix slash! I.e. 'tem_123/sub'
            type: String,
            required: false,
            default: '',
        },
        legend: {
            type: String,
            required: false,
        },
        multiple: {
            type: Boolean,
            default: false,
            required: false,
        },
        required: {
            type: Boolean,
            default: false,
            required: false,
        },
        useRealName: {
            type: Boolean,
            default: false,
            required: false,
        },
        folderId: {
            type: String,
            required: false,
            default: null,
        },
        
    },
    
    data() {
        return {
            filelist: [],
            items: [],
            loading: false,
            dragoverActive: false,
        }
    },

    methods: {
        async onFilesDrop() {
            // this.$refs.file.files = [];
            this.filelist = [];
            this.items = [];
            
            this.filelist = [...this.$refs.file.files];
            console.log('onFilesDrop');
            console.log( this.filelist );
            
            // TODO - keep sub folder intact
            // https://stackoverflow.com/questions/3590058/does-html5-allow-drag-drop-upload-of-folders-or-a-folder-tree
            
            this.loading = true;
            
            const promises = this.filelist.map( async file => {
                const fileData = {
                    input: file,
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    presigned: null,
                    completed: 0,
                    status: 'added',
                };
                
                // an optional path for the file.
                if (
                    this.path && 
                    (this.path != '' || this.path != '/' ) ){
                    
                    fileData.path = this.path;
                }
                
                // it the file path stored need an actual endpoint and 
                // i.e. being referenced in HTML-files as assets, it need
                // a path to not collide with other global files on account.
                if (
                    this.useRealName && 
                    this.path ){
                    
                    fileData.filename = file.name;
                }
                
                if (this.folderId ){
                    fileData.folderId = this.folderId;
                }
                
                this.items.push( fileData );
                // console.log('onFilesDrop pushing item:', fileData);
                // console.log(fileData);
                
                return await this.uploadFileLocal( fileData );
            });
            
            
            const promiseAllSettledResponse = await Promise.allSettled( promises );
            this.loading = false;
            // console.log('promiseAllSettledResponse', promiseAllSettledResponse);
            this.$emit('allSettled', promiseAllSettledResponse);
            // this.$notify({ type: 'success', text: 'all uploaded.' });
        },
        
        async uploadFileLocal(fileData) {
            try {
                const presignedResponse = await this.$store.dispatch('uploadFile', fileData);
                
                fileData.params = presignedResponse.params;
                fileData.presigned = presignedResponse.presigned;
                fileData.file = presignedResponse.file;
                
                const config = {
                    headers: {
                        'Content-Type': fileData.type
                    },
                    onUploadProgress: (progressEvent) => {
                        // NB: 
                        // NB: 
                        // NB: AXIOS onUploadProgress DOES NOT WORK WITH SERVICE WORKERS! 
                        // NB: 
                        // NB: 
                        const completed = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                        fileData.completed = completed;
                        
                        if (completed == 100){
                            fileData.status = 'success';
                        }
                        else {
                            fileData.status = 'uploading';
                        }
                    }
                };
                
                let payload;
            
                if (fileData.input.content) {
                    payload = fileData.input.content;
                }
                else {
                    payload = fileData.input;
                }
                
                // NB: s3 preflight upload is not default CORS:
                // https://docs.aws.amazon.com/AmazonS3/latest/dev/cors.html
                const uploadResponse = await this.$axios.put( fileData.presigned, payload, config );
                this.$emit('input', fileData);
                
                return fileData;
            } 
            catch (error) {
                console.log('error', error);
                fileData.status = 'error';
            }
        },
        
        dragover(event) {
            event.preventDefault();
            // Add some visual fluff to show the user can drop its files
            // if (!event.currentTarget.classList.contains('bg-green-300')) {
            //     event.currentTarget.classList.remove('bg-gray-100');
            //     event.currentTarget.classList.add('bg-green-300');
            // }
            this.dragoverActive = true;
        },
        
        dragleave(event) {
            // Clean up
            this.dragoverActive = false;
            
            // event.currentTarget.classList.add('bg-gray-100');
            // event.currentTarget.classList.remove('bg-green-300');
        },
        
        drop(event) {
            event.preventDefault();
            
            this.$refs.file.files = event.dataTransfer.files;
            this.onFilesDrop(); // Trigger the onChange event manually
            // Clean up
            // event.currentTarget.classList.add('bg-gray-100');
            // event.currentTarget.classList.remove('bg-green-300');
            this.dragoverActive = false;
        }
    }

}
</script>

<style scoped lang="scss">
.fileItem {
    // @apply border bg-white py-4 px-8 mr-4 mt-4 flex flex-col items-center justify-center text-black w-48 h-32 relative;
}
</style>
