<template>
    <div class="w-full h-full bg-gray-200 flex flex-col">
		
        <NavHeader 
            v-if="showNavHeader"
            class="flex-none " 
        />
        
        <!-- 
        <div 
            class="fixed bottom-0 right-0 h-8 flex text-white font-bold text-center"
            style="z-index: 1000;">
            
            <div class="w-16 hidden sm:block bg-yellow-500">sm</div>
            <div class="w-16 hidden md:block bg-red-500">md</div>
            <div class="w-16 hidden lg:block bg-blue-500">lg</div>
            <div class="w-16 hidden xl:block bg-gray-500">xl</div>
            <div class="w-16 hidden 2xl:block bg-orange-500">2xl</div>
        </div> -->
       
        <div class="flex-grow overflow-auto h-full ">
            <router-view id="routerView" />
        </div>
        
        <notifications 
            classes="notification" 
            position="top center"
        />
        
        <div 
            v-if="appVersion && appVersion.version && appVersion.stage == 'dev'"
            class="fixed top-0 right-0 mt-4 mr-4 pointer-events-none opacity-50 text-xs">
            
            v{{appVersion.version}}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'App',
        
        components: {
            'NavHeader': () => import('@/components/NavHeader.vue'),
        },
        
        computed: {
            showNavHeader(){
                if ( this.$route.path.startsWith('/app') ) {
                    return true;
                }
                return false;
            },
        },
        
        data() {
            return {
                appVersion: null,
            }
        },
        
        created() {
            this.appVersion = {
                version: process.env.VUE_APP_VERSION,
                stage: process.env.NODE_ENV == 'development' ? 'dev' : '',
            };
            
        }
    }
</script>
