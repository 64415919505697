<template>
    <div v-if="ready">
        
        <div class="bg-white">
            <div class="py-8 container mx-auto flex items-center">
                <h2 class="flex-grow flex items-center">
                    <router-link :to="{ name: 'support'}">
                        Support
                    </router-link>
                    
                    <div class="text-3xl mx-4 leading-none -mt-1">›</div>
                    
                    {{ticket.subject}}
                </h2>
            </div>
        </div>
        
        <div class="py-8 container mx-auto">
            
            <div class="panel mb-8">
                
                <div class="flex -mx-8 px-8 pb-8">
                    <div class="flex-none space-y-2 w-100">
                        <div class="flex">
                            <div class="py-0 px-3 bg-yellow-600 text-white rounded inline-block w-32 text-center mb-6">
                                {{ticket.ticketId}}
                            </div>
                        </div>
                        <div 
                            class="flex"
                            v-if="ticket.topic">
                            
                            <div class="w-32">Topic</div>
                            <div class="">
                                {{ticket.topic.topicCategory}}<br>
                                {{ticket.topic.topic}}
                            </div>
                        </div>
                        <div class="flex">
                            <div class="w-32">Created At</div>
                            <div class="">{{ticket.createdAt || ticket.createdAt | moment('lll')}}</div>
                        </div>
                        <div class="flex">
                            <div class="w-32">Author</div>
                            <div class="">{{ticket.author}}</div>
                        </div>
                        <div class="flex">
                            <div class="w-32">Status</div>
                            <div class="">{{ticket.status || 'Open'}}</div>
                        </div>
                    </div>
                    <div class="flex-grow max-w-xl">
                        <h1>{{ticket.subject}}</h1>
                        <div 
                            class=""
                            v-if="ticket.text"
                            v-html="lineBreakToBr(ticket.text)">
                        </div>
                    </div>
                </div>
                
                <div 
                    class="flex py-8 -mx-8 px-8 border-t"
                    v-for="reply in replies"
                    :key="'reply_'+reply.ticketId">
                    
                    <div class="flex-none space-y-2 w-100">
                        <div class="flex">
                            <div class="w-32">Reply At</div>
                            <div class="">{{reply.createdAt || reply.createdAt | moment('lll')}}</div>
                        </div>
                        <div class="flex">
                            <div class="w-32">Author</div>
                            <div class="">{{reply.author}}</div>
                        </div>
                    </div>
                    <div class="flex-grow max-w-xl">
                        <div 
                            v-if="reply.text"
                            v-html="lineBreakToBr(reply.text)">
                        </div>
                    </div>
                </div>
            </div>
            
            
            
            
            
            <div class="mb-8">
                <div class="flex py-4">
                    <div class="flex-none w-100">
                        
                    </div>
                    
                    <div class="panel flex-grow">
                        <form 
                            @submit.prevent="createTicketReply()"
                            class="">
                            
                            <InputMultiline
                                v-model="ticketNew.text"
                                label="New Reply"
                                name="text"
                            />
                        </form>
                        
                        <footer slot="footer">
                            <div class="buttons-wrapper">
                                <!-- <a 
                                    class="button transparent link" 
                                    @click="showTicketNew = false">
                                    
                                    Cancel
                                </a> -->
                                
                                <!-- v-if="ticketNew.text" -->
                                <button 
                                    :disabled="loading || !ticketNew.text"
                                    type="submit"
                                    class="button submit"
                                    :class="[
                                        {'loading' : loading == 'creating' },
                                        {'opacity-25 pointer-events-none' : !ticketNew.text },
                                    ]"
                                    @click.prevent="createTicketReply()">
                                    
                                    Reply Ticket
                                </button>
                            </div>
                        </footer>
                    </div>
                </div>
                
            </div>
            
        </div>
        
    </div>
    
</template>

<script>
    export default {
        computed: {
            user(){
                return this.$store.getters.getUser;
            },
            
            tickets(){
                const tickets = this.$store.getters.getTickets;
                return tickets;
            },
            
            ticket(){
                const tickets = this.tickets;
                
                if (!tickets) {
                    return;
                }
                
                if (this.ticketId) {
                    return tickets.find( t => t.ticketId == this.ticketId);
                }
            
                return null;
            },
            
            replies(){
                if (!this.tickets) {
                    return;
                }
                
                return this.tickets
                    .filter( t => t.ticketIdParent == this.ticket.ticketId)
                    .sort( (a, b) => a.createdAt - b.createdAt);
            },
        },
        
        data(){
            return {
                ticketId: null,
                loading: null,
                ready: false,
                ticketNew: {
                    ticketIdParent: null,
                    text: null,
                    author: null,
                }
            }
        },
        
        methods: {
            async createTicketReply(){
                try {
                    if (!this.ticketNew.text) {
                        throw Error( 'The ticket reply need some text' )
                    }
                    
                    this.ticketNew.ticketIdParent = this.ticketId;
                    this.ticketNew.author = this.user.username;
                    this.loading = 'creating';
                    const ticket = await this.$store.dispatch('createTicket', this.ticketNew);
                    this.ticketNew.text = null;
                } 
                catch (e) {
                    this.$notify({ 
                        type: 'error', 
                        // title: 'Error', 
                        text: e.message || 'Problem Creating Ticket'
                    });
                    
                    console.error(e);
                } 
                finally {
                    this.loading = null;
                }
            },
            
        },
        
        mounted(){
            this.ticketId = this.$route.params.ticketId;
            // await this.$store.dispatch('getCustomer', this.customerId);
            this.ready = true;
        }
    }
</script>