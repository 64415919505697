import Vue from 'vue'
import Router from 'vue-router'
import Store from './store'
// import {Auth} from 'aws-amplify';
import {Auth} from '@aws-amplify/auth';

Vue.use(Router)
Vue.use(Store)

import Axios from 'axios'
Vue.prototype.$axios = Axios;

const routes = [
    // signin root
    {
        path: '/',
        // component: () => import('./views/oauth2/SignIn.vue'), 
        component: () => import('./views/oauth2/SignUp.vue'), 
        meta: { category: 'Account', publicPage: true }
    },
    
    // signin
    {
        path: '/signin',
        name: 'signin',
        component: () => import('./views/oauth2/SignIn.vue'), 
        meta: { category: 'Account', publicPage: true }
    },
    
    // signup
    {
        path: '/signup',
        name: 'signup',
        component: () => import('./views/oauth2/SignUp.vue'), 
        meta: { category: 'Account', publicPage: true }
    },
    
    // signout
    {
        path: '/signout',
        name: 'signout',
        component: () => import('./views/oauth2/SignOut.vue'), 
        meta: { category: 'Account', publicPage: true }
    },
    
    // resetpassword
    {
        path: '/resetpassword',
        name: 'resetpassword',
        component: () => import('./views/oauth2/ResetPassword.vue'), 
        meta: { category: 'Account', publicPage: true }
    },
    
    // resetpassword
    {
        path: '/newpasswordrequired',
        name: 'newpasswordrequired',
        component: () => import('./views/oauth2/NewPasswordRequired.vue'), 
        meta: { category: 'Account', publicPage: true }
    },
    
    // resend
    {
        path: '/resend',
        name: 'resend',
        component: () => import('./views/oauth2/Resend.vue'), 
        meta: { category: 'Account', publicPage: true }
    },
    
    // // oauth2 callbacks from social connections and stuff
    // {
    //     path: '/oauth2/callback',
    //     name: 'oauth2',
    //     component: () => import('./views/oauth2/Callback.vue'), 
    //     meta: { category: 'Account', publicPage: true }
    // },

    // User Logged in
    // Now, Choose account
    {
        path: '/accounts',
        name: 'accounts',
        component: () => import('./views/oauth2/Accounts.vue'), 
        meta: { category: 'Account'}
    },
    
    
    


    // User Logged in
        // Account is chosen!
        {
            path: '/app/:partnerId/dashboard',
            name: 'dashboard',
            component: () => import('./views/app/dashboard/Index.vue'), 
            meta: { category: 'dashboard'}
        },
        {
            path: '/app/:partnerId/customers',
            name: 'customers',
            component: () => import('./views/app/customers/Index.vue'), 
            meta: { category: 'customers'}
        },
            {
                path: '/app/:partnerId/customers/:customerId/details',
                name: 'customer-details',
                component: () => import('./views/app/customers/Show.vue'), 
                meta: { category: 'customers'}
            },
            {
                path: '/app/:partnerId/customers/:customerId/contacts',
                name: 'customer-contacts',
                component: () => import('./views/app/customers/Show.vue'), 
                meta: { category: 'customers'}
            },
            {
                path: '/app/:partnerId/customers/:customerId/partner-contacts',
                name: 'customer-partner-contacts',
                component: () => import('./views/app/customers/Show.vue'), 
                meta: { category: 'customers'}
            },
            {
                path: '/app/:partnerId/customers/:customerId/licenses',
                name: 'customer-licenses',
                component: () => import('./views/app/customers/Show.vue'), 
                meta: { category: 'customers'}
            },
            {
                path: '/app/:partnerId/customers/:customerId/billing',
                name: 'customer-billing',
                component: () => import('./views/app/customers/Show.vue'), 
                meta: { category: 'customers'}
            },
            {
                path: '/app/:partnerId/customers/:customerId/timeline',
                name: 'customer-timeline',
                component: () => import('./views/app/customers/Show.vue'), 
                meta: { category: 'customers'}
            },
            {
                path: '/app/:partnerId/customers/:customerId/products',
                name: 'customer-products',
                component: () => import('./views/app/customers/Show.vue'), 
                meta: { category: 'customers'}
            },
            {
                path: '/app/:partnerId/customers/:customerId/visuals',
                name: 'customer-visuals',
                component: () => import('./views/app/customers/Show.vue'), 
                meta: { category: 'customers'}
            },
				{
					path: '/app/:partnerId/customers/:customerId/visuals/:visualId',
					name: 'customer-visual',
					component: () => import('./views/app/customers/Show.vue'), 
					meta: { category: 'customers'}
				},
            {
                path: '/app/:partnerId/customers/:customerId/offers',
                name: 'customer-offers',
                component: () => import('./views/app/customers/Show.vue'), 
                meta: { category: 'customers'}
            },
                {
                    path: '/app/:partnerId/customers/:customerId/offers/:offerId',
                    name: 'customer-offer',
                    component: () => import('./views/app/customers/Show.vue'), 
                    meta: { category: 'customers'}
                },
        {
            path: '/app/:partnerId/support',
            name: 'support',
            component: () => import('./views/app/support/Index.vue'), 
            meta: { category: 'support'}
        },
            {
                path: '/app/:partnerId/support/:ticketId',
                name: 'support-show',
                component: () => import('./views/app/support/Show.vue'), 
                meta: { category: 'support'}
            },
		{
			path: '/app/:partnerId/products',
			name: 'products',
			component: () => import('./views/app/products/Index.vue'), 
			meta: { category: 'products'}
		},
        {
            path: '/app/:partnerId/settings',
            name: 'settings',
            component: () => import('./views/app/settings/Index.vue'), 
            meta: { category: 'settings'}
        },
        // {
        //     path: '/app/:partnerId/resources',
        //     name: 'resources',
        //     component: () => import('./views/app/resources/Index.vue'), 
        //     meta: { category: 'resources'}
        // },
        
        
        {
            path: '/app/:partnerId/resources',
            name: 'resources',
            component: () => import('./views/app/resources/Presentations.vue'), 
            meta: { category: 'resources'}
        },
            {
                path: '/app/:partnerId/resources/presentations',
                name: 'resources-presentations',
                component: () => import('./views/app/resources/Presentations.vue'), 
                meta: { category: 'resources'}
            },
            {
                path: '/app/:partnerId/resources/mediaLibrary',
                name: 'resources-mediaLibrary',
                component: () => import('./views/app/resources/MediaLibrary.vue'), 
                meta: { category: 'resources'}
            },
            {
                path: '/app/:partnerId/resources/videos',
                name: 'resources-videos',
                component: () => import('./views/app/resources/Videos.vue'), 
                meta: { category: 'resources'}
            },
            // {
            //     path: '/app/:partnerId/resources/signageComposer',
            //     name: 'resources-signageComposer',
            //     component: () => import('./views/app/resources/SignageComposer.vue'), 
            //     meta: { category: 'resources'}
            // },
            // {
            //     path: '/app/:partnerId/resources/products',
            //     name: 'resources-products',
            //     component: () => import('./views/app/resources/Products.vue'), 
            //     meta: { category: 'resources'}
            // },
        
		{
			path: '/app/:partnerId/superadmin',
			name: 'superadmin',
			component: () => import('./views/app/superadmin/Index.vue'), 
			meta: { category: 'superadmin'}
		},
			// {
			// 	path: '/app/:partnerId/resources/presentations',
			// 	name: 'resources-presentations',
			// 	component: () => import('./views/app/resources/Presentations.vue'), 
			// 	meta: { category: 'resources'}
			// },
        
        // {
        //     path: '/app/:partnerId/settings',
        //     name: 'settings',
        //     component: () => import('./views/app/settings/Index.vue'), 
        //     meta: { category: 'settings'}
        // },
        //     {
        //         path: '/app/:partnerId/settings/:section/:subSection?',
        //         name: 'settings-section',
        //         component: () => import('./views/app/settings/Index.vue'), 
        //         meta: { category: 'settings'}
        //     },
]

const router = new Router({
    mode: 'history', // default commented out
    // base: process.env.BASE_URL,
    routes: routes
});

router.beforeEach(async (to, from, next) => {
    // console.log('Store.getters.setUser', Store.getters.getUser);
    
    if (to.matched.some(record => !record.meta.publicPage)) {
        // console.log('route requiresSignin TRUE');
        
        if (! Store.getters.getUser ){
            try {
                // console.log('user not set');
                
                const user = await Auth.currentAuthenticatedUser();
                // Store.state.signedIn = true;
                
                const jwt = user
                    .getSignInUserSession()
                    .getIdToken()
                    .getJwtToken();
                
                Store.commit('setUser', user);
                // console.log('setUser user', user);
                
                // Store.commit('setAuthorizationHeader', jwt);
                
                // console.log('--- Segment --- Identify user', user.username, user.attributes.email );
                // await window.analytics.identify( user.username, user.attributes);
                
                // Get the active account from the url IF the State is empty.
                if ( 
                    to.params.partnerId && 
                    !Store.getters.getAccount){ //&& !Store.state.account.partnerId
                    
                    const partnerId = to.params.partnerId;
                    console.log('partnerId', partnerId);
                    
                    const account = {
                        partnerId,
                    };
                    
                    // // fetch account!
                    // const response = await Axios.get(process.env.VUE_APP_API_ENDPOINT + '/accounts/'+partnerId);
                    // if (!response.data) {
                    //     throw Error('Could not connect to this account');
                    // }
                    // const account = response.data;
                    console.log(account);
                    // set account!
                    // Store.commit('setAccount', account);
                    
                    await Store.dispatch('setAccount', account);
                    
                    // // get account!
                    // const currentAccount = Store.getters.getAccount;
                    // console.log('currentAccount', currentAccount);
                    
                    // const response_account = await Axios.get(Store.state.apiEndpoint + '/accounts/' + partnerId);
                    // console.log('currentAccount', currentAccount);
                    
                    // // console.log('Account Login router.js');
                    // // console.log('state accountBrandKit should be reset so it can be refetched!');
                    // // console.log('----> state accountBrandKit',Store.state.accountBrandKit );
                    // 
                    // // Store.state.account = response_account.data.account;
                    // const account = response_account.data.account;
                    // Store.commit('setAccountGeneral', account);
                    // 
                    // // Store.state.account.users = response_account.data.users;
                    // const users = response_account.data.users;
                    // Store.commit('setAccountUsers', users);
                
                    // Store.state.usersAccounts.find( a => {
                    //     if (a.partnerId == partnerId) {
                    //         Store.state.account.accountName = a.accountName;
                    //     }
                    // })
                
                    // console.log('--- Segment --- Group partnerId:', partnerId, Store.state.account.accountName);
                    // await window.analytics.group( partnerId, Store.state.account);
                }
                
                // // console.log('--- Segment --- Identify user', user.username, user.attributes.email );
                // // await window.analytics.identify( user.username, user.attributes);
                // 
                // // Get all the account that this user is participating in
                // const response = await Axios.get(Store.state.apiEndpoint + '/account/usersAccounts');
                // Store.state.usersAccounts = response.data;
                // 
                // // Get the active account from the url IF the State is empty.
                // if ( to.params.partnerId && !Store.state.account.partnerId){
                //     const partnerId = to.params.partnerId;
                //     const response_account = await Axios.get(Store.state.apiEndpoint + '/account/accounts/' + partnerId);
                // 
                //     // console.log('Account Login router.js');
                //     // console.log('state accountBrandKit should be reset so it can be refetched!');
                //     // console.log('----> state accountBrandKit',Store.state.accountBrandKit );
                // 
                //     // Store.state.account = response_account.data.account;
                //     const account = response_account.data.account;
                //     Store.commit('setAccountGeneral', account);
                // 
                //     // Store.state.account.users = response_account.data.users;
                //     const users = response_account.data.users;
                //     Store.commit('setAccountUsers', users);
                // 
                //     // Store.state.usersAccounts.find( a => {
                //     //     if (a.partnerId == partnerId) {
                //     //         Store.state.account.accountName = a.accountName;
                //     //     }
                //     // })
                // 
                //     // console.log('--- Segment --- Group partnerId:', partnerId, Store.state.account.accountName);
                //     // await window.analytics.group( partnerId, Store.state.account);
                // }
                next()
            } 
            catch (err) {
                console.error(err);
                Store.commit('setUser', null);
                // Store.commit('setAccount', null);
                await Store.dispatch('setAccount', null);
                
                next({
                    path: '/signin'
                });
            }
        }
        else {
            next()
        }
    }
    else {
        // console.log('requiresSignin FALSE');
        next()
    }
    
    next()
    
})

router.afterEach( async (to, from) => {
    // page tracker
    // await window.analytics.page( to.name , { 
    //     path: to.path,
    //     category: to.meta && to.meta.category ? to.meta.category : null,
    // });
})

router.onReady( async (to, from) => {
    // if (to.query && to.query.embedParams) {
    //     const embedParams = JSON.parse( decodeURIComponent( to.query.embedParams ) );
    //     Store.state.embedParams = embedParams;
    //     // console.log('embedParams: ', embedParams);
    // }
    // 
    // // when router is ready, be sure to fetch the brandkit from AccountsMixins (router.app = VUE instance)
    // if (Store.state.signedIn) {
    //     await router.app.brandKitReset();
    //     await router.app.brandKitFetch();
    // }
})

export default router;
