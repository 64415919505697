// AccountMixins.js

export default {
	components: {
		'InputSingleline': () => import('@/components/input/InputSingleline.vue'),
		'InputMultiline': () => import('@/components/input/InputMultiline.vue'),
		'InputSelect': () => import('@/components/input/InputSelect.vue'),
		'InputUpload': () => import('@/components/input/InputUpload.vue'),
	},
	
	data() {
		return {
			currency: 'nok',
			supportTopics: {
				'General': [
					'Integrations',
					'Licenses',
					'Security',
					'Users',
				],
				'CloudCast Admin App': [
					'Bug Report',
					'Screens',
					'Portals',
					'Content Blocks and Media',
					'Templates',
					'Products',
					'Access',
				],
				'CloudCast Handheld App': [
					'Bug Report',
					'Screens',
					'Portals',
					'Content Blocks and Media',
					'Templates',
					'Products',
					'Access',
				],
				'CloudCast Players': [
					'Samsung Tizen',
					'LG WebOS',
					'Raspberry Pi',
					'Browser Player',
				],
			},
			
			statuses: [
				{
					name: 'Onboarding',
					statusId: 'onboarding',
				},
				{
					name: 'Setting Up',
					statusId: 'setup',
				},
				{
					name: 'Pilot',
					statusId: 'pilot',
				},
				{
					name: 'In Use',
					statusId: 'inuse',
				},
			],
			
			// products: [
			//     {
			//         productId: 'CCA-W21-F',
			//         name: 'CloudCast Admin - Free version',
			//         description: `
			//             The core application to set up and manage screens, content, portals and users for CloudCast. Free version includes 1 user, 
			//             and log history for detailed screens insights are kept available for 7 days back in time. Custom integrations not available. 
			//         `,
			//         cost: {
			//             'nok': 0,
			//         },
			//         includes: {
			//             'CCAU-W21': 1,
			//             'CCP-W21': 1,
			//         },
			//         type: 'admin',
			//     },
			//     {
			//         productId: 'CCA-W21-B',
			//         name: 'CloudCast Admin - Basic version',
			//         description: `
			//             The core application to set up and manage screens, content, portals and users for CloudCast. 
			//             Basic version includes 3 users, and log history for detailed screens insights are kept available for 60 days back in time.
			//         `,
			//         cost: {
			//             'nok': 3990,
			//         },
			//         includes: {
			//             'CCAU-W21': 3,
			//             'CCP-W21': 2,
			//         },
			//         type: 'admin',
			//     },
			//     {
			//         productId: 'CCA-W21',
			//         name: 'CloudCast Admin - Enterprise version',
			//         description: `
			//             The core application to set up and manage screens, content, portals and users for CloudCast. 
			//             Enterprise version includes 5 users and log history for detailed screens insights are kept available for 
			//             365 days back in time. Proof-of-play screenshots are also available for 60 days back in time.
			//         `,
			//         cost: {
			//             'nok': 14990,
			//         },
			//         includes: {
			//             'CCAU-W21': 5,
			//             'CCP-W21': 2,
			//             'CCI-B21': '∞'
			//         },
			//         type: 'admin',
			//     },
			//     {
			//         productId: 'CCAU-W21',
			//         name: 'CloudCast Additional Admin User',
			//         description: `If the count of included users in the Admin app plan is not sufficient, you can add additional users.`, //(Free = 1, Basic = 3, Enterprise = 5) 
			//         cost: {
			//             'nok': 800,
			//         }
			//     },
			//     {
			//         productId: 'CCP-W21',
			//         name: 'CloudCast Portal',
			//         description: `
			//             Portals open up CloudCast for a closed groups, to manage the screens connected to the same portal via the handheld CloudCast handheld application.
			//             In a case of working with retail stores, each store would be considered as a unique portal. Monthly portal cost is accumulated by portals set on active screens.
			//         `,
			//         cost: {
			//             'nok': 150,
			//         }
			//     },
			//     {
			//         productId: 'CCS-W21',
			//         name: 'CloudCast Screen',
			//         description: `Customer can run CloudCast on a modern signage screens from Samsung Tizen or LG WebOS, LG WebOS Box, Raspberry PI 4 or in a browser set to kiosk mode.`,
			//         cost: {
			//             'nok': 102,
			//         }
			//     },
			//     {
			//         productId: 'CCI-B21',
			//         name: 'Integrations: CloudCast hosting',
			//         description: `Instead of hosting API endpoints yourself, let CloudCast host it. Works with Product APIs, Portal APIs and Screen APIs.`,
			//         cost: {
			//             'nok': 350,
			//         }
			//     },
			//     {
			//         productId: 'CCPR-W21',
			//         name: 'Print (Poster to PDF) - Beta',
			//         description: `Create PDF from web templates directly for download from admin and handheld. Will be released Q3 2022.`,
			//         cost: {
			//             'nok': 1200,
			//         }
			//     },
			//     {
			//         productId: 'CCBR-B21',
			//         name: 'Auto Remove Image Background',
			//         description: `CloudCast is integrated with https://www.remove.bg/ and make use of it's AI technology to remove image backgrounds.`,
			//         cost: {
			//             'nok': 250,
			//         }
			//     },
			// ],
		}
	},
	
	computed: {
		partnerId() {
			if (this.$store.getters.getAccount) {
				return this.$store.getters.getAccount.partnerId;
			}
			return false;
		},
		
		account(){
			return this.$store.getters.getAccount;
		},
		
		products(){
			return this.$store.getters.getCloudCastProducts;
		},
		
		user(){
			return this.$store.getters.getUser;
		},
		
		isSuperAdmin(){
			try {
				if (this.user.attributes['custom:superadmin'] == 'true') {
					return true
				}
			} 
			catch (error) {
				// console.error(error);
			}
			
			return false;
		},
	},
	
	methods: {
		async accountsList() {
			const response = await this.$axios.get(process.env.VUE_APP_API_ENDPOINT + '/accounts');
			return response.data;
		},
		
		async accountsGet( partnerId ) {
			const response = await this.$axios.get(process.env.VUE_APP_API_ENDPOINT + '/accounts/'+partnerId);
			return response.data;
		},
		
		async accountsUsers( data ) {
			const response = await this.$axios.post(process.env.VUE_APP_API_ENDPOINT + '/accounts/'+ this.partnerId +'/users', data);
			return response.data;
		},
		
		emailIsValid(email) {
			return /\S+@\S+\.\S+/.test(email)
		},
		
		lineBreakToBr( text ){
			return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
		},
		
		humanFileSize(bytes, si = false, dp = 1) {
			const thresh = si ? 1000 : 1024;

			if (Math.abs(bytes) < thresh) {
				return bytes + ' B';
			}
			
			const units = si ?
				['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] :
				['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
			let u = -1;
			const r = 10 ** dp;

			do {
				bytes /= thresh;
				++u;
			} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


			return bytes.toFixed(dp) + ' ' + units[u];
		},
		
		fileToUrl( file ){
			// , partnerId = this.partnerId 
			return process.env.VUE_APP_FILES_ENDPOINT + '/'+ file.partnerId +'/' + file.key;
		},
		
		offerToUrl( offerId ){
			return process.env.VUE_APP_OFFERS_ENDPOINT + '/'+this.partnerId+'/' + offerId+'/index.html';
		},
		
		
		
		
		
		
		
		
		
		getUsageCount( customer, cloudCastAccount, productId ){
			// console.log({customer, cloudCastAccount, productId});
			
			if ( !cloudCastAccount || !cloudCastAccount.usage ) {
				return;
			}
			
			let count = 0;
			
			switch (productId) {
				case 'CCAU-W21': //admin users
					cloudCastAccount.usage.users.forEach( userGroup => {
						if (userGroup && userGroup.value && userGroup.value.Users) {
							count = count + userGroup.value.Users.length;
						}
					});
					break;
				case 'CCS-W21': //screens
					if (cloudCastAccount.usage.screens) {
						cloudCastAccount.usage.screens.Items.forEach( screen => {
							if (
								!screen.deletedAt && 
								( (!screen.version && screen.status == 'active') || screen.version) && // v1 or v2
								screen.deviceId ){
								
								count++;
							}
						});
					}
					break;
				case 'CCP-W21': // portals / locations
					const uniquePortalsArray = [];
					if (cloudCastAccount.usage.screens) {
						cloudCastAccount.usage.screens.Items.forEach( screen => {
							if (
								!screen.deletedAt && 
								( (!screen.version && screen.status == 'active') || screen.version) && // v1 or v2
								screen.deviceId ){
									
									
								// ( !screen.version && screen.portalId && !uniquePortalsArray.includes(screen.portalId) ) || (screen.version && screen.locationId && !uniquePortalsArray.includes(screen.locationId)) 
								
								if ( !screen.version ) { //v1
									if ( screen.portalId && !uniquePortalsArray.includes(screen.portalId) ) {
										uniquePortalsArray.push( screen.portalId );
									}
								}
								else if ( screen.version ) { //v2
									if ( screen.locationId && !uniquePortalsArray.includes(screen.locationId) ) {
										uniquePortalsArray.push( screen.locationId );
									}
								}
							}
						});
					}
					count = uniquePortalsArray.length;
					break;
					
				case 'CCI-B21':
					if ( 
						cloudCastAccount.screens && 
						cloudCastAccount.screens.source && 
						cloudCastAccount.screens.source == 'customClientApi' ) {
						
						count++;
					}
					
					if ( 
						cloudCastAccount.portals && 
						cloudCastAccount.portals.source && 
						cloudCastAccount.portals.source == 'customClientApi' ) {
						
						count++;
					}
					
					if ( 
						cloudCastAccount.products && 
						cloudCastAccount.products.source && 
						cloudCastAccount.products.source == 'customClientApi' ) {
						
						count++;
					}
					
					break;
					
				default:
					count = null;
					break;
			}
			
			return count;
		},
		
		getIncludes( customer, productId ){
			try {
				const adminProduct = this.products
					.filter( p => p.type == 'admin')
					.find( p => {
						if ( customer.licenses[p.productId] ) {
							return true
						}
					})
				
				if (adminProduct.includes) {
					return adminProduct.includes[productId];
				}
			} 
			catch (e) {
				// console.log('no admin type chosen');
				// console.error( e );
				return null;
			}
		},
		
		getProductSum( customer, cloudCastAccount, productId ){
			// console.log('getProductSum:');
			// console.log('- productId', productId);
			
			let usageCount = this.getUsageCount( customer, cloudCastAccount, productId );
			let overrideCount = customer && customer.licenses ? customer.licenses[productId] : null;
			// {{ this.customerCurrent.customerEdit.discounts[product.productId].includes}}
			
			let includesCount;
			if (
				customer.discounts &&
				customer.discounts[ productId ] &&
				customer.discounts[ productId ].includes) {
				
				includesCount = customer.discounts[ productId ].includes;
			}
			else {
				includesCount = this.getIncludes( customer, productId );
			}
			
			let count;
			
			if ( overrideCount === true ) { // is for products with toggle...
				count = 1;
			}
			else {
				// 1. check if usageCount is set
				if (usageCount) {
					count = usageCount;
				}
				
				// 2. check override value - if any. and if its higher count than overrideCount, then set usageCount instead.
				if (overrideCount !== undefined) {
					if (
						!count ||
						count < overrideCount) {
						
						count = overrideCount;
					}
				}
				
				// 3. subtract includes
				if ( includesCount ) {
					count = count - includesCount;
				}
				
				// 4. if count is less than 0, then set to 0
				if (count < 0) {
					count = 0;
				}
			}
			
			let cost = 0;
			const product = this.products.find( p => p.productId == productId);
			
			
			if ( 
				customer.discounts && 
				customer.discounts[ productId ] && 
				customer.discounts[ productId ].cost ) { //this is in customer currency 
				
				cost = customer.discounts[ productId ].cost;
			}
			else if (product) {
				cost = product.cost[ this.currency ];
			}
			
			// console.log('- cost', cost);
			
			let sum;
			
			if (count && cost) {
				sum = count * cost;
			}
			
			return {
				usageCount,
				overrideCount,
				includesCount,
				count,
				cost,
				sum,
				product,
			};
		},
		
		getAccountSum( customer, cloudCastAccount ){
			if ( !this.products ) {
				return;
			}
			
			// console.clear();
			
			let sum = 0;
			let products = [];
			
			try {
				this.products.forEach( product => {
					if (this.getProductSum( customer, cloudCastAccount, product.productId ).sum) {
						const productSum = this.getProductSum( customer, cloudCastAccount, product.productId );
						// console.log('sum', productSum );
						
						products.push( productSum );
						sum = sum + productSum.sum;
					}
					
				});
		
			} 
			catch (e) {
				console.log('e');
				console.log( e );
			}
			
			return {
				sum,
				products,
			};
		},
		
	},
}
