<template>
    <div class="">
        <router-link 
            v-for="section in sections"
            :key="section.route"
            :to="{ name: section.route }"
            class="">
            
            {{section.name}}
            <br>
        </router-link>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                sections: [
                    {
                        name: 'Details',
                        route: 'customer-details',
                    },
                    {
                        name: 'Licenses',
                        route: 'customer-licenses',
                    },
                    // {
                    //     name: 'Billing',
                    //     route: 'customer-billing',
                    // },
                    {
                        name: 'Customer Contacts',
                        route: 'customer-contacts',
                    },
                    {
                        name: 'Partner Contacts',
                        route: 'customer-partner-contacts',
                    },
                    {
                        name: 'Timeline',
                        route: 'customer-timeline',
                    },
                    {
                        name: 'Offers',
                        route: 'customer-offers',
                    },
                    // {
                    //     name: 'Products',
                    //     route: 'customer-products',
                    // },
                    {
                        name: 'Visuals',
                        route: 'customer-visuals',
                    },
					
					
                    // {
                    //     name: 'Composer',
                    //     route: 'customer-composer',
                    // },
                ]
            }
        },
    }
</script>