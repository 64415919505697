<template>
	<div v-if="cloudCastAccount">
		
		<div>
			<Explain
				heading="How does licences work?"
				text="Use this table to estimate a total price for your customer needs. Upon submitting the form, we will store the setup, and use this for the billing period."
			/>
			
			<div class="flex mb-8 space-x-8">
				<div
					v-for="(product,index) in products.filter( p => p.type == 'admin')"
					:key="'product_'+product.productId"
					@click="onTrClick( index )"
					class="rounded-lg overflow-hidden flex flex-col bg-white hover:bg-gray-100">
					
					
					<label 
						:for="product.productId"
						class="bg-gray-100 p-8 flex-none flex m-0">
						
						<div class="flex-grow ">
							<div class="text-sm capitalize">
								{{product.productId}}
							</div>
							<h4 class="text-black m-0">{{product.name}}</h4>
						</div>
						
						<div 
							class="flex-none ml-8"
							:class="account.activatedAt ? '' : 'opacity-50 pointer-events-none'">
							
							<input 
								type="radio" 
								@click="onSetAdmin(product.productId)"
								:checked="adminProduct && adminProduct.productId == product.productId"
								ref="products"
								:id="product.productId"
							/>
						</div>
					</label>
					
					<div class=" px-8 pt-8 flex-grow">
						<p class="max-w-xl">{{product.description}}</p>
					</div>
					
					<div 
						v-if="account.activatedAt"
						class="px-8 pb-8 flex-none">
						
						<span :class="customerCurrent.customerEdit.discounts && customerCurrent.customerEdit.discounts[product.productId] && customerCurrent.customerEdit.discounts[product.productId].cost ? 'line-through opacity-50 block': ''">
							{{
								new Intl.NumberFormat('no-nb', {
									style: 'currency',
									currency: 'NOK',
								}).format( product.cost[currency] )
							}}
						</span>
						
						<span v-if="customerCurrent.customerEdit.discounts && customerCurrent.customerEdit.discounts[product.productId] && customerCurrent.customerEdit.discounts[product.productId].cost">
							{{
								new Intl.NumberFormat('no-nb', {
									style: 'currency',
									currency: 'NOK',
								}).format( customerCurrent.customerEdit.discounts[product.productId].cost )
							}}
						</span>
					</div>
				</div>
			</div>
			
			<table 
				:class="adminProduct ? '' : 'opacity-50 pointer-events-none'"
				class="rounded-lg overflow-hidden">
				
				<thead>
					<tr>
						<th class="w-32">Product ID</th>
						<th>Name</th>
						<th v-if="account.activatedAt" class="text-right">Cost</th>
						<th 
							class="w-32 text-center"
							:class="account.activatedAt ? '' : 'opacity-50'"
							v-tooltip.top="`Usage is based on a current snapshot of this CloudCast account`">
							
							In use
							<i class="fas fa-info-circle link ml-2" />
						</th>
						<th 
							class="w-32 text-center"
							:class="account.activatedAt ? '' : 'opacity-50'"
							v-tooltip.top="`Override the to a fixed quatity for this customer account. If 'in use' is a higher count, the 'fixed quantity' will be ignored.`">
							
							Fix qty
							<i class="fas fa-info-circle link ml-2" />
						</th>
						<th 
							class="w-32 text-center whitespace-no-wrap"
							:class="account.activatedAt ? '' : 'opacity-50'"
							v-tooltip.top="`Depending on CloudCast Admin type, it will include various products`">
							
							Included
							<i class="fas fa-info-circle link ml-2" />
						</th>
						<th 
							v-if="account.activatedAt" 
							class="w-32 text-right">
							
							Sum
					</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(product,index) in products.filter( p => p.type != 'admin')"
						:key="'product_'+product.productId">
						
						<!-- @click="onTrClick( index )" -->
						
						<td style="vertical-align: top!important; padding-top: 1.3rem!important;">
							{{product.productId}}
						</td>
						<td>
							<h4 class="text-black">{{product.name}}</h4>
							<p class="max-w-xl">{{product.description}}</p>
						</td>
						<td 
							v-if="account.activatedAt"
							class="text-right">
							
							<span :class="customerCurrent.customerEdit.discounts && customerCurrent.customerEdit.discounts[product.productId] && customerCurrent.customerEdit.discounts[product.productId].cost ? 'line-through opacity-50 block': ''">
								{{
									new Intl.NumberFormat('no-nb', {
										style: 'currency',
										currency: 'NOK',
									}).format( product.cost[currency] )
								}}
							</span>
							
							<span v-if="customerCurrent.customerEdit.discounts && customerCurrent.customerEdit.discounts[product.productId] && customerCurrent.customerEdit.discounts[product.productId].cost">
								{{
									new Intl.NumberFormat('no-nb', {
										style: 'currency',
										currency: 'NOK',
									}).format( customerCurrent.customerEdit.discounts[product.productId].cost )
								}}
							</span>
						</td>
						<td 
							class="text-center"
							:class="account.activatedAt ? '' : 'opacity-50 pointer-events-none'">
							
							{{getUsageCount( customerCurrent.customerEdit, cloudCastAccount, product.productId )}}
						</td>
						<td 
							class="text-center"
							:class="account.activatedAt ? '' : 'opacity-50 pointer-events-none'">
							
							<template v-if="product.productId == 'CCPR-W21' || product.productId == 'CCBR-B21'">
								<!-- its for Print (Poster to PDF) - Beta and Auto Remove Image Background -->
								<input 
									type="checkbox"
									v-model="customerCurrent.customerEdit.licenses[product.productId]"
									style="margin-bottom: 0;"
								/>
							</template>
							<template v-else>
								<input 
									type="number"
									class="text-center"
									v-model="customerCurrent.customerEdit.licenses[product.productId]"
									:min="getUsageCount( customerCurrent.customerEdit, cloudCastAccount, product.productId ) || 0"
									:placeholder="getUsageCount( customerCurrent.customerEdit, cloudCastAccount, product.productId ) || 0"
									ref="products"
									style="margin-bottom: 0;"
									:style="
										customerCurrent.customerEdit.licenses[product.productId] && getUsageCount( customerCurrent.customerEdit, cloudCastAccount, product.productId ) > customerCurrent.customerEdit.licenses[product.productId] ? 'background: red; color: #fff;' : ''
									"
								/>
							</template>
						</td>
						<td 
							v-if="account.activatedAt"
							class="text-center">
							
							<span :class="customerCurrent.customerEdit.discounts && customerCurrent.customerEdit.discounts[product.productId] && customerCurrent.customerEdit.discounts[product.productId].includes ? 'line-through mr-3 opacity-50' : ''">
								{{ getIncludes( customerCurrent.customerEdit, product.productId) }}
							</span>
							
							<span v-if="customerCurrent.customerEdit.discounts && customerCurrent.customerEdit.discounts[product.productId] && customerCurrent.customerEdit.discounts[product.productId].includes">
								{{customerCurrent.customerEdit.discounts[product.productId].includes}}
							</span>
						</td>
						<td 
							v-if="account.activatedAt"
							class="w-32 text-right">
							
							<!-- <pre>{{ getProductSum(product.productId) }}</pre>
							<hr>
							 -->
							{{
								getProductSum(  customerCurrent.customerEdit,  cloudCastAccount, product.productId) && getProductSum( customerCurrent.customerEdit,  cloudCastAccount, product.productId).sum ? 
									new Intl.NumberFormat('no-nb', {
										style: 'currency',
										currency: 'NOK',
									}).format( getProductSum( customerCurrent.customerEdit,  cloudCastAccount, product.productId).sum )
								: 
								0 
							}}
						</td>
					</tr>
				</tbody>
				
				<tfoot 
					v-if="account.activatedAt"
					class="bg-blue-900 text-white border-t-2 border-gray-200">
					
					<tr>
						<th colspan="6">Total Monthly (NOK)</th>
						<th class="text-right">
							{{
								new Intl.NumberFormat('no-nb', {
									style: 'currency',
									currency: 'NOK',
								}).format( getAccountSum( customerCurrent.customerEdit, cloudCastAccount ).sum )
							}}
						</th>
					</tr>
				</tfoot>
			</table>
		</div>
		
		<div class="buttons-wrapper mt-8">
			<a 
				v-if="customerCurrent.isChangesMade && !loading"
				class="button "
				:disabled="loading"
				@click.prevent="$emit('onCancelCustomer')">
				
				Cancel
			</a>
			
			<button 
				:disabled="loading"
				type="submit"
				class="button submit ml-4"
				:class="[
					{'loading' : loading == 'updating'},
					{'opacity-25 pointer-events-none' : !customerCurrent.isChangesMade}
				]"
				@click.prevent="onUpdateCustomer()">
				
				Update
			</button>
		</div>
	</div>
	<div 
		v-else
		class="flex w-full h-100 items-center justify-center">
		
		<div class="text-center">
			<h1 class="opacity-25">No CloudCast account connected yet.</h1>
			
			<router-link 
				:to="{ name: 'customer-details'}"
				class="button submit">
				
				<i class="fas fa-plus text-lg mr-4" />
				Connect now
			</router-link>
		</div>
	</div>
	
	
</template>

<script>
	export default {
		props: [
			'customerCurrent',
		],
		computed: {
			cloudCastAccount(){
				return this.$store.getters.getCloudCastAccounts.find( cCA => cCA.accountId == this.customerCurrent.customerEdit.accountId );
			},
			
			adminProduct(){
				const adminProduct = this.products
					.filter( p => p.type == 'admin')
					.find( p => {
						if ( this.customerCurrent.customerEdit.licenses[p.productId] ) {
							return true
						}
					})
				
				return adminProduct;
			}
		},
		
		data() {
			return {
				loading: null,
			}
		},
		methods: {
			onSetAdmin( productId ){
				// 1. remove all admin products from current licenses array.
				this.products.forEach( p => {
					if (p.type == 'admin') {
						delete this.customerCurrent.customerEdit.licenses[ p.productId ];
					}
				});
				
				// 2. set new admin product on customer.
				this.customerCurrent.customerEdit.licenses = {
					...this.customerCurrent.customerEdit.licenses,
					[productId]: 1,
				}
			},
			
			onTrClick( index ){
				this.$refs.products[index].focus();
			},
			
			async onUpdateCustomer(){
				try {
					this.loading = 'updating';
					
					const customerFieldsToUpdate = {
						customerId: this.customerCurrent.customerEdit.customerId,
						licenses: this.customerCurrent.customerEdit.licenses,
					};
					
					await this.$store.dispatch('updateCustomer', customerFieldsToUpdate);
					
					this.$notify({ type: 'success', text: 'Updated Customer' });
					this.customerCurrent.isChangesMade = false;
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						text: e.message
					});
					
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
			
			
		}
	}
</script>
