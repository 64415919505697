<template>
	<div class="py-8 container mx-auto">
		<div v-if="loading && loading == 'refreshing'">
			Fetching...
		</div>
		
		<div v-else-if="products">
			<div class="flex mt-8">
				<header class="flex-grow text-black mb-8">
					Services
				</header>
				<div class="flex-none flex">
					<div 
						class="fas fa-redo-alt block flex items-center justify-center bg-white text-gray-400 w-10 h-10 rounded-lg cursor-pointer refresh"
						:class="{'loading': loading == 'refreshing'}"
						@click.prevent="refresh()"
					/>
				</div>
			</div>
			
			<Explain
				heading="What are services?"
				text="CloudCast App administrators will be shown various hints on how to get export help in context of their location in the app. The contents of these services will be show as default, 
				but each of them are open for customization from you as a CloudCast partner. If you delete a customized service, the default content from CloudCast will be shown."
			/>
			
			<div 
				v-if="masterProducts"
				class="rounded-lg overflow-hidden shadow-sm">
				
				<table>
					<thead>
						<tr>
							<!-- <th class="">Name</th> -->
							<th>Name</th>
							<th>Text</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr 
							v-for="product in productsFiltered.filter( p => p.type == 'service')"
							:key="'productId_'+product.productId"
							@click="onClickProduct( product )">
							
							<!-- <td class="">
								{{ product.productId }}
							</td> -->
							<td class="">
								{{ product.name }}
							</td>
							<td>
								<div class="truncate max-w-md">{{ product.text }}</div>
								<!-- <pre>{{ product }}</pre> -->
							</td>
							<td class="w-40">
								<div 
									v-if="products.find( p => p.productId == product.productId)"
									class="link">
									
									Edit
								</div>
								<a 
									v-else
									class="link">
									
									Customize
								</a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			
			<div class="flex mt-12">
				<header class="flex-grow text-black mb-8">
					Products
				</header>
				<div class="flex-none flex">
					<div 
						class="fas fa-redo-alt flex items-center justify-center bg-white text-gray-400 w-10 h-10 rounded-lg cursor-pointer refresh"
						:class="{'loading': loading == 'refreshing'}"
						@click.prevent="refresh()"
					/>
					
					<button 
						@click.prevent="onShowProductNew()"
						class="bg-blue-500 text-white w-10 h-10 rounded-lg ml-4 ">
						
						<i class="fas fa-plus text-lg" />
					</button>
				</div>
			</div>
			
			<Explain
				heading="What are products?"
				text='On the CloudCast App dashboard there is a section called "Need displays?", where there is a default list of options on how to get CloudCast up and running on our recommended displays and devices. 
				If you as a CloudCast Partner want to have your own customized range of products, you can start by adding your own list below.'
			/>
			<div 
				v-if="products.find( p => p.type == 'product')"
				class="rounded-lg overflow-hidden shadow-sm">
				
				<table>
					<thead>
						<tr>
							<th class="w-64">Name</th>
							<th>Text</th>
							<th>Platform</th>
							<th>Inches</th>
							<th>Price</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr 
							v-for="product in products.filter( p => p.type == 'product')"
							:key="'productId_'+product.productId"
							@click="onClickProduct( product )">
							
							<td class="">
								{{ product.name }}
							</td>
							<td>
								{{ product.text }}
							</td>
							<td>
								{{ product.platformGroupCode ? platformGroups.find( platform => platform.code == product.platformGroupCode).name : '' }}
							</td>
							<td>
								{{ product.inches ? product.inches+'"': '' }}
							</td>
							<td>
								{{ product.price }}
							</td>
							<td class="w-40">
								<div 
									v-if="products.find( p => p.productId == product.productId)"
									class="link">
									
									Edit
								</div>
								<a 
									v-else
									class="link">
									
									Customize
								</a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div 
				v-else
				class="text-center">
				
				Found no products yet. Start adding if you do not want to use the default recommended products.
				<!-- <pre>{{ masterProducts }}</pre> -->
			</div>
		</div>
		
		<Modal 
			v-if="showProductEdit"
			@close="onCloseProductEdit"
			insideClasses="bg-white"
            width="50rem">
			
			<header slot="header">
				<div class="py-4 px-6">
					Edit item
				</div>
				<div 
					v-if="
						masterProducts.find( p => p.productId == productEdit.productId && productEdit.partnerId == partnerId ) && 
						productEdit.type == 'service'
					"
					class="py-4 px-6 bg-yellow-200">
					
					<div class="opacity-75">Original name of service:</div>
					{{ masterProducts.find( p => p.productId == productEdit.productId).name }}
				</div>
			</header>
			
			<!-- 
			<div class="pb-2 px-6">
				<Explain
					heading="How does licences work?"
					text="Use this table to estimate a total price for your customer needs. Upon submitting the form, we will store the setup, and use this for the billing period."
				/>
			</div> -->
			
			<form 
				class="pb-2 px-6"
				:class="productEdit.partnerId == partnerId || productEdit.type == 'product' ? 'opacity-100' : 'opacity-50 cursor-not-allowed '">
				
				<div 
					class="flex gap-12 "
					:class="productEdit.partnerId == partnerId || productEdit.type == 'product' ? '' : 'pointer-events-none '">
					
					<div class="flex-1 ">
						<InputSingleline 
							v-model="productEdit.name"
							:required="true"
							label="Name"
							name="name"
						/>
						
						<div 
							v-if="productEdit.image && productEdit.image.fileId"
							class="border overflow-hidden rounded bg-white mb-4">
							
							<img
								:src="fileToUrl( productEdit.image )" 
								class="h-32 w-full object-contain py-2"
							/>
							
							<a class="link m-3 block text-center" @click="productEdit.image = null">Remove image</a>
						</div>
						<InputUpload
							v-else
							name="productEdit.image"
							:multiple="false"
							@input="onFilesAdded"
							@allSettled="$event && $event[0].status == 'fulfilled' ? productEdit.image = $event[0].value.file : null"
							path="files"
							:useRealName="false"
							:folderId="null"
						/>
						
						<InputMultiline
							v-model="productEdit.text"
							:required="false"
							label="Text"
							name="text"
							placeholder="A short and selling description"
						/>
						
						<InputSingleline 
							v-model="productEdit.price"
							:required="false"
							label="Price"
							name="price"
						/>
						
						<!-- <InputSingleline 
							v-model.number="productEdit.email"
							type="text"
							label="Enquiries will be sent to email receipient"
							name="email"
						/> -->
					</div>
					
					<div 
						v-if="productEdit.type && productEdit.type == 'product'"
						class="flex-1">
						
						<label>Device Platform Group</label>
						<select 
							v-model="productEdit.platformGroupCode"
							required>
							
							<option 
								v-for="pG in platformGroups"
								:key="'pG_'+pG.code"
								:value="pG.code">
								
								{{pG.name}}
							</option>
						</select>
						
						<InputSingleline 
							v-model.number="productEdit.inches"
							type="number"
							:required="false"
							label="Inches"
							name="inches"
						/>
						
						<!-- <InputSingleline 
							v-model="productEdit.os"
							:required="false"
							label="Operating System"
							name="os"
							placeholder="Ex: WebOS 4.0 or Tizen 6.5"
						/> -->
						
						<label>
							<input 
								type="checkbox" 
								v-model="productEdit.touch"
							/>
							
							Has touch support
						</label>
					</div>
				</div>
			</form>
			
			<footer slot="footer">
				<div class="flex">
					<div class="flex-none">
						<button 
							v-if="
								(productEdit.partnerId == partnerId && productEdit.partnerId != masterPartnerId) || 
								productEdit.type == 'product'
							"
							:disabled="loading"
							type="submit"
							class="button warning"
							:class="{'loading' : loading == 'deleting' }"
							@click.prevent="onDeleteProduct( productEdit )">
							
							Delete
						</button>
					</div>
					<div class="flex-grow flex justify-end">
						<a 
							class="button transparent link" 
							@click="showProductEdit = false">
							
							Cancel
						</a>
						
						<button 
							v-if="productEdit.partnerId == partnerId"
							:disabled="loading"
							type="submit"
							class="button submit"
							:class="{'loading' : loading == 'updating' }"
							@click.prevent="onUpdateProduct()">
							
							Update
						</button>
						
						<button 
							v-else
							:disabled="loading"
							type="submit"
							class="button submit"
							:class="{'loading' : loading == 'creating' }"
							@click.prevent="onCreateProduct()">
							
							{{ productEdit.type && productEdit.type == 'product' ? 'Create' : 'Customize' }}
						</button>
					</div>
				</div>
			</footer>
		</Modal>
	</div>
</template>

<script>
	export default {
		computed: {
			masterProducts(){
				const products = this.$store.getters.getMasterProducts;
				return products;
			},
			
			products(){
				const products = this.$store.getters.getProducts;
				// filter out the ones that are customer specific...
				return products;
			},
			
			productsFiltered(){
				let response = this.masterProducts.map( mP => {
					const partnerProduct = this.products.find( p => p.productId == mP.productId);
					
					return {
						...mP,
						...partnerProduct,
					}
				});
				
				return response;
				// return masterProducts.filter( p => p.type == 'service');
			},
			
			masterPartnerId(){
	            return process.env.VUE_APP_MASTER_PARTNER_ID;
			},
		},
		
		data() {
			return {
				loading: null,
				presets: [
					// {
					// 	code: 'integrationProducts',
					// 	title: `Need product catalog integration?`,
					// 	text: '',
					// },
					// {
					// 	code: 'integrationPortals',
					// 	title: `Need a store locations integration?`,
					// 	text: '',
					// },
					// {
					// 	code: 'integrationMediaLibrary',
					// 	title: `Integrate an existing media library?`,
					// 	text: '',
					// },
					// {
					// 	code: 'templatesDevelopment',
					// 	title: `Need help from designers to build brand safe templates?`,
					// 	text: '',
					// },
					// {
					// 	code: 'strategyDisplays',
					// 	title: `Our experts can help your business with signage strategy`,
					// 	text: '',
					// },
				],
				showProductEdit: false,
				productEdit: {
					partnerId: null,
					name: null,
					text: null,
					image: {
						fileId: null,
					},
					type: 'service',
					price: null,
					
					// inches: null,
					// os: null,
					// touch: false,
					// platformGroupCode: null,
				},
				platformGroups:  [
					{
						code: 'tizen',
						name: 'Samsung device',
					},
					{
						code: 'webos',
						name: 'LG device',
					},
					{
						code: 'raspberryPi',
						name: 'Raspberry Pi 4+',
					},
					// {
					// 	code: 'webBrowser',
					// 	name: 'Web Browser',
					// },
				],
			}
		},
		
		methods: {
			onShowProductNew(){
				this.productEdit.type = 'product';
				this.productEdit.platformGroupCode = 'tizen';
				this.showProductEdit = true;
				
				// this.productNew = {
				// 	partnerId: null,
				// 	name: null,
				// 	text: null,
				// 	image: {
				// 		fileId: null,
				// 	},
				// 	type: 'product',
				// 	price: null,
					
				// 	inches: null,
				// 	os: null,
				// 	touch: false,
				// 	platformGroupCode: null,
				// };
			},
			
			onSetOverride(){
				this.productEdit.partnerId = this.partnerId;
			},
			
			onCloseProductEdit(){
				this.productEdit = {
					partnerId: null,
					name: null,
					text: null,
					type: 'service',
					image: null,
					price: null,
					
					// inches: null,
					// image: null,
					// os: null,
					// touch: false,
				};
				
				this.showProductEdit = false;
			},
			
			onFilesAdded( event ){
                console.log('onFilesAdded');
                console.log('event', event);
            },
			
			async onClickProduct( product ){
				// is overridden
				const partnerProduct = this.products.find( p => p.productId == product.productId);
				
				if ( partnerProduct ) {
					this.productEdit = JSON.parse( JSON.stringify( partnerProduct ) ); 
				}
				else {
					this.productEdit = JSON.parse( JSON.stringify( product ) ); 
				}
				
				this.showProductEdit = true;
			},
			
			async onDeleteProduct( product ){
				if (! confirm('Are you sure?')) return;
				
				try {
					this.loading = 'deleting';
					await this.$store.dispatch('deleteProduct', product );
					
					this.$notify({ type: 'success', text: 'Item was deleted' });
					this.onCloseProductEdit();
					
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						title: 'Error', 
						text: 'There was a problem while deleting the item'
					});
					console.error(e);
				}
				finally {
					this.loading = null;
				}
			},
            
			onCancel(){
				// this.createAccountCopy();
				this.$notify({ type: 'success', text: 'Item was reverted' });
			},
			
			async onUpdateProduct() {
				try {
					this.loading = 'updating';
					await this.$store.dispatch('updateProduct', this.productEdit);
					console.log(this.productEdit);
					
					this.$notify({ type: 'success', text: 'Item was saved' });
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						title: 'Error', 
						text: 'There was a problem while saving the item'
					});
					console.error(e);
				}
				finally {
					this.loading = null;
				}
			},
			
			async onCreateProduct() {
				try {
					// console.log(this.productEdit);
					
					this.loading = 'creating';
					const response = await this.$store.dispatch('createProduct', this.productEdit);
					
					if (!response || !response.partnerId ) {
						throw Error('An error occured when creating item');
					}
					
					this.productEdit.partnerId = response.partnerId;
					
					this.$notify({ type: 'success', text: 'Item was created' });
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						title: 'Error', 
						text: 'There was a problem while saving the item'
					});
					console.error(e);
				}
				finally {
					this.loading = null;
				}
			},
			
			async refresh(){
				try {
					this.loading = 'refreshing';
					await this.$store.dispatch('listProducts');
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						// title: 'Error', 
						title: e.message || 'Problem refreshing items list'
					});
					
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
		},
		
		async mounted(){
			this.refresh();
		},
	}
</script>