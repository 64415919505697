<template>
	<div class="px-6 py-3 bg-yellow-100 border-l-4 border-yellow-500 rounded-r-lg mb-8">
		<h3 class="text-black">{{ heading }}</h3>
		<p 
			class="text-black opacity-50"
			v-html="text"
		/>
	</div>
</template>

<script>
	export default {
		props: [
		   'heading',
		   'text',
		],
		
		data() {
			return {
				
			}
		},
	}
</script>