<template>
    <div>
        <div class="py-8 container mx-auto flex">
            <div class="w-40 flex-none leading-loose">
                <ResourcesNavSidebar />
            </div>
            <div class="flex-grow">
                <section class="bg-yellow-400">
                    <header class="text-black mb-4">
                        Media Library
                    </header>
                    
					<MediaLibraryInternal />
					
					<!-- <div 
						v-for="file in files"
						:key="file.fileId">
						
						<pre>{{ file.key }}</pre>
					</div> -->
					
                    <!-- <h3 class="my-4">Private Files</h3>
                    
                    <div class="grid grid-cols-4 gap-8 mb-12">
                        <div
                            v-for="(m,i) in 10"
                            :key="'m_'+i"
                            class="p-2 bg-gray-300 text-gray-500 h-32 flex items-center justify-center text-2xl">
                            
                            {{m}}
                        </div>
                    </div>
                    
                    <h3 class="my-4">Public Files</h3>
                    
                    <div class="grid grid-cols-4 gap-8 mb-12">
                        <div
                            v-for="(m,i) in 10"
                            :key="'m_'+i"
                            class="p-2 bg-gray-300 text-gray-500 h-32 flex items-center justify-center text-2xl">
                            
                            {{m}}
                        </div>
                    </div> -->
                </section>
            </div>
        </div>
        
    </div>
    
</template>

<script>
    export default {
        components: {
            'ResourcesNavSidebar': () => import('@/components/resources/ResourcesNavSidebar.vue'),
            'MediaLibraryInternal': () => import('@/components/mediaLibrary/MediaLibraryInternal.vue'),
        },
        
		computed: {
            files(){
                const files = this.$store.getters.getFiles;
                return files;
            },
            
            path() {
                return 'files';
            },
        },
        
        data() {
            return {
                section: null,
            }
        },
        
        methods: {
            
        },
        
        async mounted(){
            // await this.$store.dispatch('listFiles', {path: 'files'});
        },
    }
</script>