<template>
	<div class="w-full h-full overflow-hidden flex flex-col">
		<div class="flex-grow w-full h-full overflow-hidden flex relative">
			<div class="flex-none overflow-auto p-12 bg-gray-200">
				<FoldersTreeSimple
					v-if="folders && folders.length"
					:folders="folders"
					:isInit="true"
					:isExpanded="true"
					:active="activeFolder"
					ref="FoldersTreeSimple"
					:expanded="expanded"
					
					@folderClick="onClickFolder"
					@fileDropped="onMoveFile"
					@onFolderExpand="onFolderExpand"
					@onFolderCollapse="onFolderCollapse"
				/>
			</div>
			
			<div 
				class="flex-grow w-full h-full overflow-hidden flex flex-col relative"
				style="z-index: 0;">
				
				<div class="flex-none flex items-center space-x-4 p-6 sm:p-12 ">
					<div class="flex-grow h-10 flex items-center truncate">
						<h3 
							v-if="activeFolder"
							class="truncate">
							
							{{ activeFolder.name }}
						</h3>
					</div>
					
					<div class="flex-none relative overflow-hidden w-10 h-10 rounded bg-white ">
						<div 
							class="absolute fas fa-redo-alt flex items-center justify-center text-gray-400 w-10 h-10 rounded cursor-pointer refresh"
							:class="{'loading': loading == 'refreshing'}"
							@click="onRefresh"
						/>
					</div>
					
					
					<!--				
					<div v-if="activeFolder"
						class="flex-none relative">
						
						<div 
							@click="showFolderMenu = !showFolderMenu"
							class="flex items-center justify-center bg-white w-10 h-10 rounded cursor-pointer">
							
							<i 
								class="fas fa-ellipsis-v"
								:class="showFolderMenu ? 'text-black' : 'text-gray-400'"
							/>
						</div>
						<div v-show="showFolderMenu">
							<div 
								@click="showFolderMenu = !showFolderMenu"
								class="dim"
								style="z-index: 1000;"
							/>
							
							<div
								class="
									bg-white shadow-xl rounded 
									absolute right-0 w-64 mt-4
								"
								style="z-index: 1000; ">
								
								<div 
									class="absolute top-0 right-0 bg-white w-4 h-4 -mt-2 mr-4" 
									style="transform: rotate(45deg);"
								/>
								
								<div 
									v-if="activeFolder"
									class="flex flex-col space-y-1">
									
									<a 
										class="hover:text-black px-6 py-4 border-b border-gray-100"
										@click="showFolderMenu = false; showNewModal = true;">
										
										Upload to folder
									</a>
									<a 
										class="hover:text-black px-6 py-4 border-b border-gray-100"
										@click="
											showFolderMenu = false; 
											folderEdit = {
												name: '',
											};
											showNewFolderModal = true;
										">
										
										New sub folder
									</a>
									<a 
										class="hover:text-black px-6 py-4 border-b border-gray-100"
										:class="activeFolder.folderId == 'homeFolder' ? 'opacity-50 pointer-events-none' : ''"
										@click="showFolderMenu = false; onDeleteFolder( activeFolderId )">
										
										Delete folder
									</a>
									<a 
										class="hover:text-black px-6 py-4 border-b border-gray-100"
										:class="activeFolder.folderId == 'homeFolder' ? 'opacity-50 pointer-events-none' : ''"
										@click="
											showFolderMenu = false; 
											folderEdit = {
												name: activeFolder.name,
											};
											showRenameFolderModal = true;
										">
										
										Rename folder
									</a>
									<a 
										class="hover:text-black px-6 py-4"
										:class="activeFolder.folderId == 'homeFolder' ? 'opacity-50 pointer-events-none' : ''"
										@click="
											showFolderMenu = false; 
											folderEdit = {
												folderId_parent: activeFolder.folderId_parent || null,
											};
											showMoveFolderModal = true;
										">
										
										Move folder
									</a>
								</div>
							</div>
						</div>
					</div>
					-->
				</div>
				
				<div 
					v-if="loading == 'fetchingFiles'"
					class="flex-grow flex items-center justify-center">
					
					<div class="spinner" />
				</div>
				<div 
					v-else-if="files && files.length > 0 && listView"
					class="@container flex-grow overflow-auto px-6 sm:px-12 pb-24">
					
					<table>
						<thead>
							<tr>
								<!-- <th></th> -->
								<th>Thumbnail</th>
								<th>Filename</th>
								<th>Date</th>
								<th class="text-right">Filesize</th>
								<th v-if="buttons.includes('delete') && showFileActions"></th>
								<th v-if="buttons.includes('download')"></th>
								<th v-if="buttons.includes('use')"></th>
							</tr>
						</thead>
						<tbody>
							<tr 
								v-for="file in files"
								:key="file.fileId"
								@click="onClickFile( file )"
								class="cursor-default"
								:draggable="true"
								@dragstart="onDragStart( $event, file )">
								
								<!-- <td class="w-8">
									<input 
										type="checkbox"
										:checked="filesSelected.includes( file )"
									/>
								</td> -->
								<td class="w-32">
									<div class="h-20 overflow-hidden flex justify-center items-center my-4">
										<Thumbnail 
											:file="file" 
										/>
									</div>
								</td>
								<td class="">
									<div class="truncate max-w-sm">
										{{ file.name }}
									</div>
								</td>
								<td class="">
									<span v-if="file.updatedAt && file.updatedAt > file.createdAt">
										Updated {{ $moment( file.updatedAt ).format('ll') }}
									</span>
									<span v-else>
										Created {{ $moment( file.createdAt ).format('ll') }}
									</span>
									<!-- <span v-if="file.createdAt || file.updatedAt">
										<div v-if="file.createdAt == file.updatedAt">
											Created {{ $moment( file.createdAt ).format('ll') }}
										</div>
										<div v-else>
											Updated {{ $moment( file.updatedAt).format('ll') }}
										</div>
									</span> -->
								</td>
								<td class="text-right">
									{{humanFileSize(file.size)}}
								</td>
								<td 
									v-if="buttons.includes('delete') && showFileActions"
									class="w-8">
									
									<a
										:disabled="loading"
										:class="{'loading' : loading == 'deletingFile'}"
										@click.stop="onDeleteFile( file )"
										class="link">
										
										<i class="fas fa-trash" />
									</a>
								</td>
								<td 
									v-if="buttons.includes('download')"
									class="w-8">
									
									<a
										:href="fileToUrl( file ) "
										target="_blank"
										download
										class="link">
										
										<i class="fas fa-download" />
									</a>
								</td>
								<td 
									v-if="buttons.includes('use')"
									class="w-8">
									
									<a
										@click.stop="onUseFile( file )"
										class="link">
										
										Use
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div 
					v-else
					class="flex-grow flex items-center justify-center">
					
					Found no files in folder
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		components: {
            'FoldersTreeSimple': () => import('@/components/files/FoldersTreeSimple.vue'),
		},
		
		props: {
			buttons: {
				type: Array,
				required: false,
			},
			
			fileTypeFilter: {
				type: Array,
				required: false,
			},
		},
		
		computed: {
			showFileActions() {
				return true;
			},
			
			path(){
				if ( this.visibility == 'private' ){
					if ( this.userPortalId) {
						return 'content/media/'+ this.userPortalId;
					}
					else {
						return 'content/media/admin';
					}
				}
				
				return 'content/media';
			},
			
			isMobile(){
				return this.$store.getters.getIsMobile;
			},
			
			// foldersFlat(){
			// 	return this.$store.getters.getFolders;
			// },
			
			folders(){
				if ( this.foldersFlat ){
					let folders = this.foldersFlat
						.filter( f => !f.folderId_parent)
						.filter( f => !f.deletedAt)
						.map( f => {
							let children = this.categoryGetNameAndChildren( f );
							
							return {
								...f,
								children,
							}
						});
					
					let response = [
						{
							folderId: 'homeFolder',
							name: 'Home Folder',
							children: folders,
						},
					];
					
					return response;
				}
				
				return null;
			},
			
			activeFolder(){
				// if (
				// 	this.activeFolderId && 
				// 	this.foldersFlat) {
					
				// 	return this.foldersFlat.find( f => f.folderId == this.activeFolderId);
				// }
				
				if ( !this.activeFolderId ){
					console.log('not defined');
					return null;
				}
				
				let folder;
				
				if ( this.activeFolderId == 'homeFolder') {
					folder = this.folders.find( f => f.folderId == this.activeFolderId);
				}
				else {
					folder = this.foldersFlat.find( f => f.folderId == this.activeFolderId);
				}
				// let folder = this.foldersFlat.find( f => f.folderId == this.activeFolderId);
				console.log(folder);
				
				return folder;
			},
			
			activeFolderMove(){
				if ( !this.activeFolderIdMove ){
					console.log('not defined');
					return null;
				}
				
				let folder;
				
				if ( this.activeFolderIdMove == 'homeFolder') {
					folder = this.folders.find( f => f.folderId == this.activeFolderIdMove);
				}
				else {
					folder = this.foldersFlat.find( f => f.folderId == this.activeFolderIdMove);
				}
				
				return folder;
			},
			
			
			files(){
				if ( !this.allFiles || this.allFiles.length == 0) {
					return null;
				}
				
				return this.allFiles
					.filter( file => {
						if (file.fileId) {
							if ( file.fileId.startsWith('design')  && !this.fileTypeFilter.includes('design') ) {
								return false;
							}
							else if ( file.type.includes('image')  && !this.fileTypeFilter.includes('image') ) {
								return false;
							}
							else if ( file.type.includes('video') && !this.fileTypeFilter.includes('video') ) {
								return false;
							}
						}
						
						return true;
					})
					.filter( file => {
						if ( 
							// file.folderId == this.activeFolder.folderId ||
							// this.activeFolder.folderId == 'homeFolder' == !file.folderId ){
							file.folderId == this.activeFolderId ||
							this.activeFolderId == 'homeFolder' == !file.folderId ){
								
							return true;
						}
						
						return false;
					});
			},
		},
		
		data(){
			return {
				loading: null,
				folderType: 'media',
				// activeFolder: null,
				listType: 'cards',
				// path: 'content/media',
				listView: true,
				file: null,
				foldersFlat: null,
				allFiles: null,
				fileView: null,
				// filesSelected: [],
				showFolderMenu: false,
				showFoldersTree: false,
				showNewMenu: false,
				showNewModal: false,
				showDesignerModal: false,
				
				folderEdit: {},
				showNewFolderModal: false,
				showRenameFolderModal: false,
				showMoveFolderModal: false,
				maxPixels: 150,
				
				isDraggingOver: false,
				
				expanded: [
					'homeFolder',
					// 'fol-byo.pvg'
				],
				
				activeFolderId: null,
				// expandedInit: [
				// 	'homeFolder',
				// ],
				
				
				expandedMove: [
					'homeFolder',
				],
				
				activeFolderIdMove: null,
				
			}
		},
		
		methods: {
			
			async onClickFolder( folder ){
				console.log( 'onClickFolder', folder);
				this.activeFolderId = folder.folderId;
				// console.log( 'activeFolderId', this.activeFolderId);
				
				if (!folder.folderId) {
					return null;
				}
				
				if ( folder.folderId == 'homeFolder' ){
					console.log('its the homeFolder');
					await this.fetchFiles();
				}
				else {
					await this.fetchFiles( folder.folderId );
				}
			},
			
			onFolderExpand( folderId ){
				console.log('onFolderExpand in root', folderId);
				
				if ( !this.expanded.includes( folderId ) ) {
					this.expanded.push( folderId );
				}
			},
			
			onFolderCollapse( folderId ){
				console.log('onFolderCollapse in root', folderId);
				
				if ( this.expanded.includes( folderId ) ) {
					// this.expanded.push( folderId );
					this.expanded = this.expanded.filter( f => f !== folderId); 
				}
			},
			
			onOpenDesigner( file = null ){
				this.file = file; // could be null
				this.showDesignerModal = true;
				this.fileView = null;
			},
			
			async onRefresh(){
				try {
					this.loading = 'refreshing';
					
					const refreshResponse = await Promise.allSettled( [
						this.onRefreshFolder(),
						// this.fetchFolders(),
						// this.$store.dispatch('listFolders', {
						// 	visibility: this.visibility, // 'public' |'private'
						// 	portalId: this.userPortalId ? this.userPortalId : null,
						// }),
					]);
					
					console.log('refreshResponse', refreshResponse);
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						// title: 'Error', 
						text: e.message || 'Problem refreshing folders and files'
					});
					
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
			onChangeListType( listType ){
				this.listType = listType;
				localStorage[ 'mediaLibrary_listType_'+this.accountId ] = this.listType;
			},
			
			categoryGetNameAndChildren( folder ){
				let children = this.foldersFlat
					.filter( cF => cF.folderId_parent == folder.folderId )
					.filter( cF => !cF.deletedAt)
					.map( f => {
						let children = this.categoryGetNameAndChildren( f );
						
						return {
							...f,
							children,
						}
					});
					
				return children;
			},
			
			async onRefreshFolder(){
				// console.log('onRefreshFolder called ');
				
				if ( this.activeFolder.folderId == 'homeFolder' ){
					await this.fetchFiles();
				}
				else {
					await this.fetchFiles( this.activeFolder.folderId );
				}
			},
			
			async fetchFiles( folderId ){
				try {
					this.loading = 'fetchingFiles';
					// console.log('this.path', this.path);
					
					this.allFiles = await this.$store.dispatch('listFiles', {
						folderId,
						path: this.path,
					});
				} 
				catch (e) {
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
			async fetchFolders(){
				try {
					this.loading = 'fetchingFolders';
					
					const response = await this.$store.dispatch('listFolders', {
						visibility: this.visibility, // 'public' |'private'
						portalId: this.userPortalId ? this.userPortalId : null,
					});
					
					if (!response) {
						throw Error( 'Could not fetch folders' );
					}
					this.foldersFlat = response;
				} 
				catch (e) {
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
			onClickFile( file ) {
				this.fileView = file;
			},
			
			onUseFile( file ) {
				this.$emit('use', file);
				// this.filesSelected.push( file );
			},
			
			
			
			
			async onClickFolderMove( folder ){
				console.log( 'onClickFolder', folder);
				
				this.folderEdit.folderId_parent = folder.folderId || 'homeFolder';
				this.activeFolderIdMove = folder.folderId;
				// console.log( 'activeFolderId', this.activeFolderId);
				
				// if (!folder.folderId) {
				// 	return null;
				// }
			},
			
			onFolderExpandMove( folderId ){
				console.log('onFolderExpand in root', folderId);
				
				if ( !this.expandedMove.includes( folderId ) ) {
					this.expandedMove.push( folderId );
				}
			},
			
			onFolderCollapseMove( folderId ){
				console.log('onFolderCollapse in root', folderId);
				
				if ( this.expandedMove.includes( folderId ) ) {
					// this.expanded.push( folderId );
					this.expandedMove = this.expandedMove.filter( f => f !== folderId); 
				}
			},
			
			
			
			
			
			
			async onMoveFile( fileAndFolderObject ) {
				try {
					if ( !this.showFileActions ) {
						throw Error( 'Your user role does not allow this.' );
					}
					
					this.loading = 'movingFile';
					
					const data = {
						method: 'moveToFolder',
						fileId: fileAndFolderObject.file.fileId,
						folderId: fileAndFolderObject.folder.folderId == 'homeFolder' ? null : fileAndFolderObject.folder.folderId,
					};
					
                    const response = await this.$store.dispatch('updateFile', data);
					// console.log(response);
                    this.$notify({ type: 'success', text: 'File was moved'});
					// this.allFiles = this.files.filter( f => f.fileId != data.fileId );
					
					
					// this.allFiles = this.allFiles.map( f => {
					// 	if ( f.fileId == data.fileId ){
					// 		// console.log('found it', f);
					// 		f.folderId = data.folderId;
					// 		// console.log('found it after', f);
					// 	}
						
					// 	return f;
					// });
					
					
					// const file = this.allFiles.find( f => f.fileId == data.fileId);
					// console.log(file);
					// file.folderId = response.folderId;
					
					// this.onRefreshFolder();
                    this.allFiles = this.files.filter( f => f.fileId != fileAndFolderObject.file.fileId );
					
					this.fileView = null;
                } 
                catch (e) {
                    console.error(e);
                    
                    this.$notify({ 
                        type: 'error', 
                        title: 'Error', 
                        text: e && e.message || 'Could not move file'
                    });
                } 
                finally {
                    this.loading = null;
                }
			},
			
            async onDeleteFile( file ){
                if (! confirm('Are you sure you want to delete it?')) {
                    return;
                }
                
                try {
                    this.loading = 'deletingFile';
                    const response = await this.$store.dispatch('deleteFile', file);
                    this.allFiles = this.files.filter( f => f.fileId != file.fileId );
                    this.$notify({ type: 'success', text: 'File was deleted'});
					this.fileView = null;
                } 
                catch (e) {
                    console.error(e);
                    
                    this.$notify({ 
                        type: 'error', 
                        title: 'Error', 
                        text: 'Could not delete file'
                    });
                } 
                finally {
                    this.loading = null;
                }
            },
            
			async onCreateFolder() {
				try {
					this.loading = 'creatingFolder';
					
					if ( this.folderEdit.name == '' ) {
						throw Error( 'The folder needs a name' )
					}
					
					this.folderEdit.folderType = 'media';
					
					if (
						this.visibility && 
						this.visibility == 'private' ){
						
						this.folderEdit.visibility = 'private';
					}
					else {
						this.folderEdit.visibility = 'public';
					}
					
					if (this.userPortalId){
						this.folderEdit.portalId = this.userPortalId;
					}
					
					if (
						this.activeFolder && 
						this.activeFolder.folderId && 
						this.activeFolder.folderId != 'homeFolder' ){
						
						this.folderEdit.folderId_parent = this.activeFolder.folderId;
					}
					else {
						this.folderEdit.folderId_parent = null;
					}
					
					await this.$store.dispatch('createFolder', this.folderEdit);
					this.$notify({ type: 'success', text: 'Created new folder'});
					
					
					// console.log( this.$refs );
					// console.log( this.$refs.FoldersTreeSimple );
					// this.$refs.FoldersTreeSimple.exposedMethod();
					
					await this.fetchFolders();
					
					this.showNewFolderModal = false;
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						title: 'Error', 
						text: 'Could not create new folder'
					});
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
			async onRenameFolder() {
				try {
					this.loading = 'renamingFolder';
					let activeFolder = this.foldersFlat.find( f => f.folderId == this.activeFolder.folderId);
					
					if ( !this.folderEdit.name || this.folderEdit.name == ''){
						throw Error('The folder needs a name');
					}
					
					this.folderEdit = {
						...activeFolder,
						name: this.folderEdit.name,
					};
					
					await this.$store.dispatch('updateFolder', this.folderEdit);
					this.$notify({ type: 'success', text: 'Folder was renamed' });
					
					this.showRenameFolderModal = false;
					
					
					await this.fetchFolders();
					// this.activeFolder = folder;
					
					// let activeFolder = this.foldersFlat.find( f => f.folderId == this.activeFolder.folderId);
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						title: 'Error', 
						text: 'Could not rename folder'
					});
					console.error(e);
				} 
				finally {
					// this.folderNew = {};
					this.loading = null;
				}
			},
			
			async onMoveFolder() {
				try {
					this.loading = 'movingFolder';
					let activeFolder = this.foldersFlat.find( f => f.folderId == this.activeFolderId);
					
					if ( this.folderEdit.folderId_parent == this.activeFolderId ){
						// TODO check also that its not allowed to move recurrsive from here.
						throw Error('Folder cannot be moved into itself');
					}
					
					if ( this.folderEdit.folderId_parent == 'homeFolder'){
						this.folderEdit.folderId_parent = null;
					}
					
					this.folderEdit = {
						...activeFolder,
						folderId_parent: this.folderEdit.folderId_parent,
					};
					
					await this.$store.dispatch('updateFolder', this.folderEdit);
					this.$notify({ type: 'success', text: 'Folder was moved' });
					
					this.showMoveFolderModal = false;
					await this.fetchFolders();
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						title: 'Error', 
						text: e.message || 'Could not move the folder'
					});
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
			async onDeleteFolder( folderId ){
				try {
					this.loading = 'deletingFolder';
					
					if (! confirm('Are you sure you want to delete it?')) {
						return;
					}
					
					await this.$store.dispatch('deleteFolder', folderId);
					this.$notify({ type: 'success', text: 'Folder was deleted' });
					
					this.activeFolderId = 'homeFolder';
					
					await this.fetchFolders();
					
					// this.activeFolderId = folder.folderId;
				
					// if (!folder.folderId) {
					// 	return null;
					// }
					
					// if ( folder.folderId == 'homeFolder' ){
					// 	await this.fetchFiles();
					// }
					// else {
					// 	await this.fetchFiles( folder.folderId );
					// }
					
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						title: 'Error', 
						text: e.message || 'Could not delete the folder'
					});
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
			onDragStart( event, file ){
				console.log('onDragStart', event);
				event.dataTransfer.dropEffect = 'move';
				event.dataTransfer.effectAllowed = 'move';
				event.dataTransfer.setData('file', JSON.stringify(file) );
			},
			
			onDragOver( event ){
				// console.log('onDragOver', event);
				// event.preventDefault();
				// this.isDraggingOver = true;
				
				// console.log( this.$refs );
				
				// if ( files ) {
				// 	console.log('got files...', files);
					
				// 	[...this.$refs.dropzone.files].map( (file, fileIndex) => {
				// 		console.log('file', file, fileIndex);
				// 	});
				// 	// this.isDraggingOver = true;
				// }
				// else {
				// 	// console.error('no files...');
				// }
			},
			
			onDragLeave( event ){
				// console.log('onDragLeave', event);
				// event.preventDefault();
				// this.isDraggingOver = false;
			},
			
			onDragEnd( event ){
				// console.log('onDragEnd', event);
				// event.preventDefault();
				// this.isDraggingOver = false;
			},
			onDrop( event ){
				// console.log('onDrop', event);
				// const files = event.dataTransfer.files;
				// console.log('files', files);
				// event.preventDefault();
				// this.isDraggingOver = false;
			},
		},
		
		async mounted(){
			await this.fetchFolders();
			
			if ( this.folders && this.folders.length ) {
				// console.log('got this.folders ', this.folders);
				this.activeFolderId = this.folders[0].folderId;
				// console.log('this.folders[0] ', this.folders[0]);
			}
			
			// this.onClickFolder({
			// 	folderId: 'homeFolder'
			// });
			
			// if (this.$route.params.folderId) {
			//     this.folderId = this.$route.params.folderId;
			// }
			
			// await this.fetchFolders();
			
			if ( this.isMobile ) {
				// this.showFoldersTree = false;
				this.maxPixels = 100;
			}
			
			if ( localStorage[ 'mediaLibrary_listType_'+this.accountId ] ) {
				this.listType = localStorage[ 'mediaLibrary_listType_'+this.accountId ];
			}
		}
	}
</script>