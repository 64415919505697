<template>
    <div class="w-full h-full flex flex-col items-center justify-center">
        <div v-if="loading">
            <div class="fixed inset-0 flex items-center justify-center">
                <div class="spinner"></div>
            </div>
        </div>
        <div v-else>
            <div v-if="accountsRaw && accountsRaw.length && accountsRaw.length > 1">
                <h2 class="text-black">Your user is participating in more than one account</h2>
                <p>Please select the account you want to enter:</p>
                
                <div class="grid grid-cols-2 gap-2">
                    <div 
                        v-for="account in accountsRaw"
                        :key="account.partnerId"
                        @click="setAccount(account)"
                        class="cursor-pointer bg-white p-4 rounded border mb-2">
                        
                        {{account.name}}
                    </div>  
                </div>
                
                <div 
                    v-if="user"
                    class="flex-none m-4 text-center">
                    
                    <div class="mb-2">
                        {{ user.attributes.email }}
                    </div>
                    
                    <button 
                        @click="signOut()"
                        class="button submit">
                        
                        Sign Out
                    </button>
                </div>
            </div>
            <div v-if="accountsRaw && accountsRaw.length == 0">
                <h1>We found no CloudCast Partner accounts attached to this user</h1>
                
                <div 
                    v-if="user"
                    class="flex-none m-4 text-center">
                    
                    <div class="mb-2">
                        {{ user.attributes.email }}
                    </div>
                    
                    <button 
                        @click="signOut()"
                        class="button submit">
                        
                        Sign Out
                    </button>
                </div>
            </div>
        </div>
        
        
        <!-- user:<br>
        <pre class="w-full h-100 overflow-auto">{{user}}</pre>
        <hr>
        
        accountsRaw:<br>
        <pre class="w-full h-100 overflow-auto">{{accountsRaw}}</pre>
        <hr> -->
    </div>
</template>

<script>
    import AuthMixins from '@/mixins/AuthMixins';

    export default {
        mixins: [
            AuthMixins,
        ],
        
        data(){
            return {
                accountsRaw: null,
                loading: null,
            }
        },
        
        computed: {
            user(){
                return this.$store.getters.getUser;
            },
            
            accounts(){
                if (this.accountsRaw) {
                    return this.accountsRaw.filter( a => {
                        if (a.name) {
                            return true;
                        }
                    });
                }
                
                return null;
            }
        },
        
        methods: {
            async setAccount( account ){
                await this.$store.dispatch('setAccount', account);
                this.$router.push({ name: 'dashboard', params: {partnerId: account.partnerId} });
            }
        },
        
        async mounted(){
            this.loading = 'listing';
            
            try {
                this.accountsRaw = await this.accountsList();
            } 
            catch (e) {
                this.$notify({ 
                    type: 'error', 
                    title: 'Error', 
                    text: e.message
                });
            
                console.error(e);
                this.$router.push({name: 'signout'});
            }
            
            this.loading = null;
            
            if (this.accountsRaw.length == 1) {
                // console.log('found one. redirect to this one...');
                this.setAccount( this.accountsRaw[0] );
                // console.log('setAccount!', this.accountsRaw[0]);
            }
        }
    }
</script>
