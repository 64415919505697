<template>
    <div class="w-full h-full overflow-auto">
        
        <div 
            v-if="confirmInProgress"
            class="max-w-lg mx-auto overflow-hidden rounded-lg my-12">
            
            <div class="bg-blue-900 text-white p-8">
                <div class="">
                    <img src="https://www.getcloudcast.com/logo.svg" class="w-32" />
                    <h1 class="text-white opacity-50 my-0 leading-none ml-3 text-4xl tracking-tight my-1">Partner Portal</h1>
                </div>
            </div>
            <div class="bg-white p-8">
                <div class="my-4 mb-8 overflow-hidden">
                    <h2 class="text-xl mb-2">Confirm Email</h2>
                    <p>To confirm that you are the owner of this email address, we have sent you an email with a verification code</p>
                </div>
                
                <form @submit.prevent="resetPasswordSubmit()">
                    <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                        {{error}}
                    </div>
                    
                    <InputSingleline 
                        v-model="email"
                        label="Email address"
                        name="email"
                        placeholder="john@company.com"
                        class="w-full -mt-4"
                        type="email"
                        :required="true"
                    />
                    
                    <InputSingleline 
                        v-model="confirmCode"
                        label="Verification Code"
                        name="confirmCode"
                        class="w-full"
                        :required="true"
                    />
                    
                    <InputSingleline 
                        v-model="password"
                        label="New Password"
                        name="newPassword"
                        class="w-full "
                        type="password"
                        :required="true"
                    />
                    
                    <button 
                        type="submit" 
                        class="button submit mb-4 w-full" 
                        :class="{'loading' : loading}"
                        :disabled="loading">
                        
                        Set new password
                    </button>
                </form>
                
                <div class="text-center">
                    <router-link :to="{ name: 'signin'}">
                        Return to Sign In
                    </router-link>
                </div>
            </div>
        </div>
        <div 
            v-else-if="!confirmInProgress"
            class="max-w-lg mx-auto overflow-hidden rounded-lg my-12">
            
            <div class="bg-blue-900 text-white p-8">
                <div class="">
                    <img src="https://www.getcloudcast.com/logo.svg" class="w-32" />
                    <h1 class="text-white opacity-50 my-0 leading-none ml-3 text-4xl tracking-tight my-1">Partner Portal</h1>
                </div>
            </div>
            <div class="bg-white p-8">
                <div class="my-4 mb-8 overflow-hidden">
                    <h2 class="text-xl mb-2">Reset password</h2>
                    <p>We will send you an email with a link so you can reset your password</p>
                </div>
                
                <form @submit.prevent="resetPasswordEmail()">
                    <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                        {{error}}
                    </div>
                    
                    <InputSingleline 
                        v-model="email"
                        label="Email address"
                        name="email"
                        placeholder="john@company.com"
                        class="w-full -mt-4"
                        type="email"
                        :required="true"
                    />
                    
                    <button 
                        type="submit" 
                        class="button submit mb-4 w-full" 
                        :class="{'loading' : loading}"
                        :disabled="loading">
                        
                        Continue
                    </button>
                </form>
                
                <div class="text-center">
                    <router-link :to="{ name: 'signin'}">
                        Return to Sign In
                    </router-link>
                </div>
            </div>  
        </div>
        
        
    </div>
</template>

<script>
    import AuthMixins from '@/mixins/AuthMixins';
    
    export default {
        mixins: [
            AuthMixins,
        ],
        
        data() {
            return {
                email: null,
                error: null,
                confirmInProgress: null,
                confirmCode: null,
                password: null,
                loading: false,
            }
        },
    }
</script>
