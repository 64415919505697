<template>
	<div class="">
		<Explain
			heading="What are products?"
			text="These products will be visible within the CloudCast app in the device section, located on the dashboard."
		/>
		
		<div class="rounded-lg overflow-hidden shadow-sm">
			<table>
				<thead>
					<tr>
						<th class="w-12"></th>
						<th class="w-64">Platform</th>
						<th class="w-64">Name and Description</th>
						<th class="w-48">Image</th>
						<th class="w-32">Inches</th>
						<th class="w-32">Price</th>
						<th class="w-24">Touch</th>
						<th class="w-10"></th>
					</tr>
				</thead>
				
				<Draggable 
					v-model="customerCurrent.customerEdit.partnerProducts"
					handle=".handle"
					tag="tbody">
					
					<tr 
						v-for="(product,i) in customerCurrent.customerEdit.partnerProducts"
						:key="'product_'+i">
						
						<td>
							<div
								class="handle text-3xl mr-4 cursor-move"
								v-html="'='"
							/>
						</td>
						<td>
							
							<select v-model="product.platformGroupCode">
								<option 
									v-for="pG in platformGroups"
									:key="'pG_'+pG.code"
									:value="pG.code">
									
									{{pG.name}}
								</option>
							</select>
						</td>
						<td>
							<input type="text" v-model="product.name" />
							<input type="text" v-model="product.description" />
						</td>
						<td>
							<div 
								v-if="product.image"
								class="border overflow-hidden rounded bg-white">
								
								<img
									:src="fileToUrl( product.image )" 
									class="h-32 w-full object-contain p-4"
								/>
								
								<a class="link m-3 block text-center" @click="product.image = null">Remove image</a>
							</div>
							<InputUpload
								v-else
								name="image"
								:label="'Upload Image'"
								:multiple="false"
								@input="onFilesAdded( $event, product )"
								:path="path"
								:useRealName="false"
								:folderId="null"
							/>
						</td>
						<td>
							<input type="number" v-model.number="product.inches" />
						</td>
						<td>
							<input type="text" v-model="product.price" />
						</td>
						<td>
							<input 
								type="checkbox" 
								v-model="product.touch"
							/>
						</td>
						<td>
							<i 
								class="fas fa-trash text-lg link block text-center mb-4" 
								@click.prevent="customerCurrent.customerEdit.partnerProducts = customerCurrent.customerEdit.partnerProducts.filter( p => p.partnerProductId != product.partnerProductId)"
							/>
						</td>
					</tr>
				</Draggable>
				
				<tbody>
					<tr>
						<td>
							
						</td>
						<td>
							<select v-model="productNew.platformGroupCode">
								<option 
									v-for="pG in platformGroups"
									:key="'pG_'+pG.code"
									:value="pG.code">
									
									{{pG.name}}
								</option>
							</select>
						</td>
						<td>
							<input 
								type="text"
								v-model="productNew.name" 
								placeholder="Name"
							/>
							<input 
								type="text" 
								v-model="productNew.description" 
								placeholder="Description"
							/>
							
						</td>
						<td>
							<div 
								v-if="productNew.image"
								class="border overflow-hidden rounded bg-white mb-4">
								
								<img
									:src="fileToUrl( productNew.image )" 
									class="h-32 w-full object-contain"
								/>
								
								<a class="link m-3 block text-center" @click="productNew.image = null">Remove image</a>
							</div>
							<InputUpload
								v-else
								name="image"
								:label="'Upload Image'"
								:multiple="false"
								@input="onFilesAdded( $event, productNew )"
								:path="path"
								:useRealName="false"
								:folderId="null"
							/>
						</td>
						<td>
							<input 
								type="number"
								v-model.number="productNew.inches" 
								placeholder="Inches"
							/>
						</td>
						<td>
							<input 
								type="text"
								v-model="productNew.price" 
								placeholder="Price"
							/>
						</td>
						<td>
							<input 
								type="checkbox" 
								v-model="productNew.touch"
							/>
						</td>
						<td>
							<i 
								class="fas fa-plus flex items-center justify-center w-6 h-6 bg-blue-500 text-white rounded-full mb-4" 
								@click.prevent="onAddProduct()"
							/>
						</td>
					</tr>
				</tbody>
			</table>
			
			<!-- customerCurrent.customerEdit.partnerProducts
			<pre>{{ customerCurrent.customerEdit.partnerProducts }}</pre> -->
		</div>
		
		<div class="buttons-wrapper mt-8">
			<a 
				v-if="customerCurrent.isChangesMade && !loading"
				class="button "
				:disabled="loading"
				@click.prevent="$emit('onCancelCustomer')">
				
				Cancel
			</a>
			
			<button 
				:disabled="loading"
				type="submit"
				class="button submit ml-4"
				:class="[
					{'loading' : loading == 'updating'},
					{'opacity-25 pointer-events-none' : !customerCurrent.isChangesMade}
				]"
				@click.prevent="onUpdateCustomer()">
				
				Update
			</button>
		</div>
		
	</div>
</template>

<script>
    import Draggable from 'vuedraggable';

	export default {
		props: [
			'customerCurrent',
		],
		
		components: {
            Draggable,
        },
		
		computed: {
			// cloudCastAccounts(){
			//     const cloudCastAccounts = this.$store.getters.getCloudCastAccounts;
			//     return cloudCastAccounts;
			// },
			
			// customers(){
			//     const customers = this.$store.getters.getCustomers;
			//     return customers;
			// },
			
			// customerConnected(){
			//     return this.customers.map( c => c.accountId);
			// },
			
			path() {
				return 'files';
			},
		},
		
		data() {
			return {
				customerId: null,
				loading: null,
				productNew: {
					partnerProductId: null,
					platformGroupCode: 'tizen',
					name: null,
					image: null,
					inches: null,
					price: null,
					touch: false,
					description: null,
				},
				platformGroups:  [
					{
						code: 'tizen',
						name: 'Samsung',
					},
					{
						code: 'webos',
						name: 'LG',
					},
					{
						code: 'raspberryPi',
						name: 'Raspberry Pi',
					},
					// {
					// 	code: 'webBrowser',
					// 	name: 'Web Browser',
					// },
				],
			}
		},
		methods: {
			onAddProduct(){
				try {
					if (!this.productNew.name) {
						throw Error( 'The product needs a name' );
					}
					
					this.productNew.partnerProductId = 'pro_'+Math.round(Math.random()*1000);
					this.customerCurrent.customerEdit.partnerProducts.push( this.productNew );
					
					this.productNew = {
						partnerProductId: null,
						platformGroupCode: 'tizen',
						name: null,
						image: null,
						inches: null,
						price: null,
						touch: false,
						description: null,
					};
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						text: e.message
					});
					console.error(e);
				}
			},
			
			async onUpdateCustomer(){
				try {
					this.loading = 'updating';
					
					const customerFieldsToUpdate = {
						customerId: this.customerCurrent.customerEdit.customerId,
						partnerProducts: this.customerCurrent.customerEdit.partnerProducts,
					};
					
					await this.$store.dispatch('updateCustomer', customerFieldsToUpdate);
					
					this.$notify({ type: 'success', text: 'Updated Customer' });
					this.customerCurrent.isChangesMade = false;
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						text: e.message
					});
					
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
			onFilesAdded( fileData, product ){
				// console.log(fileData, product);
				// https://partner.getcloudcast.com/files/pa_lhd.jkb/files/file_puq.dci.jpg
				product.image = {
					...fileData.file,
					url: process.env.VUE_APP_FILES_ENDPOINT + '/'+this.partnerId+'/' + fileData.file.key
				};
			},
		}
	}
</script>