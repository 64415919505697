<template>
    <div class="">
        <router-link 
            v-for="section in sections"
            :key="section.route"
            :to="{ name: section.route }"
            class="">
            
            {{section.name}}
            <br>
        </router-link>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                sections: [
                    {
                        name: 'Presentations',
                        route: 'resources-presentations',
                    },
                    {
                        name: 'Videos',
                        route: 'resources-videos',
                    },
                    // {
                    //     name: 'Media Library',
                    //     route: 'resources-mediaLibrary',
                    // },
                    // {
                    //     name: 'Signage Composer',
                    //     route: 'resources-signageComposer',
                    // },
                    // {
                    //     name: 'Products',
                    //     route: 'resources-products',
                    // },
                ]
            }
        },
    }
</script>

<style lang="scss" scoped>
    
</style>
