<template>
	<div v-if="ready">
		<div class="py-8 container mx-auto">
			<div class=" mt-8">
				<div class="">
					<header class="text-black mb-4">
						Partner Details
					</header>
					
					<div class="panel ">
						<InputSingleline 
							v-model="accountEdit.name"
							:required="true"
							label="Company Name"
							name="name"
						/>
						
						<InputMultiline 
							v-model="accountEdit.bio"
							label="Short bio for your customers"
							name="bio"
							:required="false"
							placeholder="I.e. We deliver digital signage solutions for restaurants, airports, retail stores and more. Give us a call or contact one of our employees to discuss the best digital signage solution for your business."
						/>
						
						<div class="grid grid-cols-2 gap-x-8">
							
							<InputSingleline 
								v-model="accountEdit.address"
								:required="true"
								label="Address"
								name="address"
							/>
							
							<InputSingleline 
								v-model="accountEdit.city"
								:required="true"
								label="City"
								name="city"
							/>
							
							<InputSingleline 
								v-model="accountEdit.state"
								:required="true"
								label="State"
								name="state"
							/>
							
							<InputSingleline 
								v-model="accountEdit.postalCode"
								:required="true"
								label="Postal Code"
								name="postalCode"
							/>
							
							<InputSingleline 
								v-model="accountEdit.country"
								:required="true"
								label="Country"
								name="country"
							/>
							
							<InputSingleline 
								v-model="accountEdit.websiteUrl"
								:required="false"
								label="Website URL"
								name="websiteUrl"
							/>
						</div>
						
						<Contacts 
							:contacts="accountEdit.contacts"
							@delete="accountEdit.contacts = accountEdit.contacts.filter( c => c != $event); resetTimestamp = new Date().getTime();"
							:reset="resetTimestamp"
						/>
						
						<div class="buttons-wrapper mt-12">
							<a 
								v-if="isChangesMade && !loading"
								class="button "
								:disabled="loading"
								@click.prevent="onCancelCustomer()">
								
								Cancel
							</a>
							
							<button 
								:disabled="loading"
								type="submit"
								class="button submit ml-4"
								:class="[
									{'loading' : loading == 'updating'},
									{'opacity-25 pointer-events-none' : !isChangesMade}
								]"
								@click.prevent="onUpdateAccount()">
								
								Update
							</button>
						</div>
					</div>
				</div>
				
				<div class="mt-12">
					<header class="text-black mb-4">
						Partner Portal Access
					</header>
					
					<div>
						<Users />
					</div>
				</div>
			</div>
		</div>
		
		<!-- 
		<div class="panel">
			<form 
				v-if="accountEdit"
				@submit.prevent="updateAccount()"
				class="flex-grow flex flex-col overflow-hidden">
				
				<div 
					class="
						flex-none flex items-center 
						h-20
						px-4 
						
						md:h-32
						md:py-8
						md:px-16
						md:flex-row
					">
					<h2 class="text-black m-0 flex-grow">
						{{ section ? $t( 'settings.'+section+'.heading' ) : ''}}
					</h2>
					
					<button 
						v-if="section != 'users'"
						:disabled="loading"
						type="submit"
						class="button submit"
						:class="{'loading' : loading == 'update'}">
						 
						<i class="fas fa-check mr-2 " />
						{{ $t('general.save') }}
					 </button>
				</div>
				
				<div 
					class="
						flex-grow overflow-y-auto overflow-x-hidden
						px-4 pb-4
						md:px-16 md:pb-16
					">
					
					<section v-if="section == 'accountDetails' || !section" class="panel">
						<InputSingleline 
							v-model="accountEdit.name"
							:required="true"
							:label="$t( 'settings.accountDetails.accountName' )"
							:name="'name'"
						/>
					</section>
					<section v-else-if="section == 'localeSettings'" class="panel">
						<label for="language">{{$t('settings.localeSettings.language')}}</label>
						
						<select 
							v-model="accountEdit.locale.language"
							@change="onChangeLocale(accountEdit.locale.language)"
							id="language">
							
							<option 
								v-for="language in languages"
								:key="language.name"
								:value="language.code">
								
								{{language.name}}
							</option>
						</select>
					</section>
					
				</div>
				
			</form>
			
			<section v-else-if="section == 'users'">
				<Users />
			</section>
			
			
		</div>
		-->
		 
	</div>
</template>

<script>
	export default {
		components: {
			'Users': () => import('@/components/settings/Users.vue'),
			'Contacts': () => import('@/components/Contacts.vue'),
		},
		
		computed: {
			objectToCheckIfChanged(){
				return JSON.stringify(this.accountEdit);
			},
		},
		
		watch: {
			objectToCheckIfChanged: function(newValue_str, oldValue_str) {
				if ( newValue_str != oldValue_str) {
					this.isChangesMade = true;
				}
				
				if ( !JSON.parse(oldValue_str) || this.isReverted ) {
					this.isChangesMade = false;
					this.isReverted = false;
				}
			},
		},
		
		data() {
			return {
				ready: false,
				loading: null,
				isReverted: false,
				isChangesMade: false,
				accountEdit: null,
				resetTimestamp: null,
			}
		},
		
		methods: {
			onCancelCustomer(){
				this.createAccountCopy();
				this.isReverted = true;
				this.resetTimestamp = new Date().getTime();
				this.$notify({ type: 'success', text: 'Account settings reverted' });
			},
			
			async onUpdateAccount() {
				try {
					this.loading = 'updating';
					
					if (
						!this.accountEdit.name ||
						this.accountEdit.name == '') {
						
						throw Error('Your account need a name');
					}
					
					await this.$store.dispatch('updateAccount', this.accountEdit);
					this.$notify({ type: 'success', text: 'Account settings saved' });
					this.isChangesMade = false;
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						title: 'Error', 
						text: 'There was a problem while saving the settings'
					});
					console.error(e);
				}
				finally {
					this.loading = null;
				}
			},
			
			createAccountCopy(){
				this.accountEdit = JSON.parse(
					JSON.stringify( 
						Object.assign(
							{
								contacts: [],
							},
							this.account,
						)
					)
				);
			},
		},
		
		mounted(){
			this.createAccountCopy();
			this.ready = true;
		}
	}
</script>